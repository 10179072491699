<template>
    <div class="main-page">
        <HeroMain :isButton="true" />
        <main id="main">
            <section class="main-page__top">
                <div class="main-page__top-left">
                    <div class="main-page__top-descr">
                        Nornickel is&nbsp;known as&nbsp;the world&rsquo;s largest producer of&nbsp;high-grade nickel and palladium. We&nbsp;operate in&nbsp;the Russian North where the ecosystems are especially fragile and vulnerable. That is&nbsp;why in&nbsp;recent years, we&nbsp;have paid increasing attention to&nbsp;our environmental projects and efforts to&nbsp;conserve biodiversity.
                    </div>
                    <div class="main-page__top-text" id="map">
                        By&nbsp;navigating from section to&nbsp;section, you will be&nbsp;able to&nbsp;compile a&nbsp;map of&nbsp;Nornickel&rsquo;s ecosystem conservation projects.
                    </div>
                </div>
                <div class="main-page__top-right">
                    <div class="main-page__top-info">
                        A completed map can be downloaded as a poster
                    </div>
                    <img src="img/map-info.png" alt="" />
                </div>
            </section>

            <section class="main-page__map">
                <a v-for="item in links" :href="item.link" :key="item.id" class="main-page__map-item"
                    :class="[`main-page__map-item-${item.id}`, isBig(item.big), isActive(item)]">
                    <div class="main-page__map-item-time">{{ item.time }}</div>
                    <div class="main-page__map-item-title" v-html="item.title"></div>
                    <div class="main-page__map-video" :class="`main-page__map-video-${item.id}`">
                        <video v-if="item.id !== 1 || isMobile" autoplay loop muted playsinline >
                            <source :src="`video/map-${item.id}-mobile.mp4`" type="video/mp4"  media="(max-width: 768px)">
                            <source :src="`video/map-${item.id}.mp4`" type="video/mp4">
                        </video>
                    </div>
                    <picture>
                        <source media="(max-width: 768px)" :srcset="`img/main-map-${item.id}-mobile-active.jpg`">
                        <img loading="lazy" :src="`img/main-map-${item.id}-active.jpg`" alt="">
                    </picture>

                    <picture v-if="!isActive(item)">
                        <source media="(max-width: 768px)" :srcset="`img/main-map-${item.id}-mobile.jpg`">
                        <img loading="lazy" class="notActive" :src="`img/main-map-${item.id}.jpg`" alt="">
                    </picture>
                </a>
            </section>

            <section class="main-page__bottom">
                <a href="poster_Nornickel.pdf" download v-if="store.length >= 11" class="main-page__bottom-link">
                    Download map PDF
                </a>
                <div class="main-page__bottom-title">
                    Biodiversity conservation
                </div>
                <BaseAccordion class="main-page__result-faq" v-for="faq in faqs" :key="faq.id" :faq="faq" />
            </section>

            <section class="main-page__form">
                <div class="main-page__form-wrap">
                    <h2 class="main-page__form-title">Have you got a&nbsp;question for&nbsp;us?</h2>
                    <iframe src="https://forms.yandex.ru/u/672066b7e010db57dd4f229c/?iframe=1" frameborder="0" name="ya-form-672066b7e010db57dd4f229c" width="100%"></iframe>
 
                    <!-- <form method="POST" @submit.prevent="submitForm" id="myForm">
                        <div class="main-page__form-wrap">
                            <label class="main-page__form-label" for="name">
                                Surname, 
First Name, 
Middle Name or&nbsp;Patronym 
                            </label>
                            <input class="main-page__form-input" type="text" name="name" id="name" v-model="formData.name" required>
                        </div>

                        <div class="main-page__form-wrap">
                            <label class="main-page__form-label" for="mail">
                                E-mail 
                            </label>
                            <input class="main-page__form-input" type="email" name="mail" id="mail" v-model="formData.mail" required>
                        </div>

                        <div class="main-page__form-flex">
                            <div class="main-page__form-flex-left">
                                <div class="main-page__form-wrap">
                                    <label class="main-page__form-label" for="city">
                                        City of&nbsp;residence
                                    </label>
                                    <input class="main-page__form-input" type="text" name="city" id="city" v-model="formData.city" required>
                                </div>

                                <div class="main-page__form-wrap">
                                    <label class="main-page__form-label" for="work">
                                        Place of&nbsp;work
                                    </label>
                                    <input class="main-page__form-input" type="text" name="work" id="work" v-model="formData.work" required>
                                </div>
                            </div>
                            <div class="main-page__form-flex-right">
                                <div class="main-page__form-checkboxes">
                                    <p class="main-page__form-label">
                                        Age
                                    </p>
                                    <div class="main-page__form-check">
                                        <label for="check-1" class="main-page__checkbox-item">
                                            <input type="radio" name="age" id="check-1" required v-model="formData.age" value="15 or under "
                                                class="main-page__checkbox-input">15 or under 
                                            <span class="main-page__checkbox-span"></span>
                                        </label>
                                        <label for="check-2" class="main-page__checkbox-item">
                                            <input type="radio" name="age" id="check-2" required v-model="formData.age" value="15 to 18"
                                                class="main-page__checkbox-input">15 to 18
                                            <span class="main-page__checkbox-span"></span>
                                        </label>
                                        <label for="check-3" class="main-page__checkbox-item">
                                            <input type="radio" name="age" id="check-3" required v-model="formData.age" value="19 to 35"
                                                class="main-page__checkbox-input">19 to 35
                                            <span class="main-page__checkbox-span"></span>
                                        </label>
                                        <label for="check-4" class="main-page__checkbox-item">
                                            <input type="radio" name="age" id="check-4" required v-model="formData.age" value="35 to 50"
                                                class="main-page__checkbox-input">35 to 50
                                            <span class="main-page__checkbox-span"></span>
                                        </label>
                                        <label for="check-5" class="main-page__checkbox-item">
                                            <input type="radio" name="age" id="check-5" required v-model="formData.age" value="Over 50"
                                                class="main-page__checkbox-input">Over 50
                                            <span class="main-page__checkbox-span"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="main-page__form-wrap">
                            <label class="main-page__form-label" for="question">
                                Your question
                            </label>
                            <textarea class="main-page__form-input main-page__form-textarea" name="question" v-model="formData.question"
                                id="question" required />
                        </div>

                        <div class="main-page__form-footer">
                            <label for="check" class="main-page__checkbox-item main-page__form-footer-check">
                                <input type="checkbox" name="agree" id="check" class="main-page__checkbox-input" v-model="formData.agree" required>
                                In&nbsp;accordance with Federal Law No. 152-FZ enacted on&nbsp;27.07.2006, entitled &laquo;On&nbsp;Personal Data&raquo;, I&nbsp;freely and willingly give my&nbsp;consent to&nbsp;PJSC MMC Norilsk Nickel to&nbsp;process my&nbsp;personal data submitted on&nbsp;the feedback form on&nbsp;the site https://life.nornickel.ru/
                                <span class="main-page__checkbox-span"></span>
                            </label>

                            <div class="main-page__form-footer-button-wrap">
                                <button class="main-page__form-footer-button" :disabled="!isFormIncomplete" type="submit">Send</button>
                                <span>All fields are required</span>
                            </div>
                        </div>
                    </form> -->
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import axios from 'axios';
import HeroMain from "@/layout/HeroMain.vue";
import BaseAccordion from "@/components/BaseAccordion.vue";
export default {
    components: {
        HeroMain,
        BaseAccordion,
    },
    data() {
        return {
            isSended: false,
            heroDescr: "Узнайте, какие подходы использует &laquo;Норникель&raquo; для сохранения природных экосистем, какая экотехнология восстановления водоёмов оказалась наиболее перспективной, где найти биологические реликты и&nbsp;как &laquo;Норникель&raquo; спасает жизни королей Арктики.",
            faqs: [{
                question: "Why is it important to conserve biodiversity?",
                answer: "<p class=mb-10>Plant-animal relationship carefully designed by nature help maintain climate and preserve life on the Earth. </p><p class=mb-10>Ecosystems continuously evolve and adapt to external threats. Their resilience is rooted in their diversity, as each species makes a sizeable, albeit often inconspicuous contribution to the entire web of life. A single link missing may disrupt the whole chain.</p><p class=mb-10>Human activities impact natural ecosystems, causing significant damage to the environmental balance. Once humans have transformed most of lands, the balance was broken, giving rise to processes linked to climate change. </p><p>By aiming to preserve the pristine beauty of nature and assuming responsibility for the legacy we leave behind, we endeavour to safeguard a sustainable environment and abundant natural resources to make sure humans survive as a biological species.</p>",
                isOpen: false,
            },
            {
                question: "What are the current threats to biodiversity?",
                answer: "<div class=fqa-flex><div class=fqa-num>01</div><div class=fqa-text>Changing landscapes, construction, vehicle traffic, trampling and use of toxic agents both in industry and agriculture all destroy plant and animal habitats. Species composition changes, and the entire populations shrink in size as they get isolated from vital resources.</div></div><div class=fqa-flex><div class=fqa-num>02</div><div class=fqa-text>Local animal species with no immunity to new infections get infected by migrating disease carriers.</div></div><div class=fqa-flex><div class=fqa-num>03</div><div class=fqa-text>Global climate change causes shifts in the geographic distribution of species. Some animals and plants move into non-typical habitats where they behave as invasive species by competing with and even reducing native populations.</div></div><div class=fqa-flex><div class=fqa-num>04</div><div class=fqa-text>04 Industrial activities increase the risk of man-made disasters and fires. Fires and spills of fuel and other industrial fluids cause vast damage to natural ecosystems.</div></div><div class=fqa-flex><div class=fqa-num>05</div><div class=fqa-text>Irresponsible tourism leads to uncontrolled fires and pollution of the environment with household waste.</div></div>",
                isOpen: false,
            },
            {
                question: "Why do&nbsp;we&nbsp;need to&nbsp;define facilities&rsquo; impact areas?",
                answer: "<p class=mb-10>Scientists gather data for subsequent monitoring as they study ecosystems within and outside the area of impact. </p><p class=mb-10>New research is key to identifying changes and quickly responding to any disruptions in the natural balance. By defining impact areas, we can also assess the scope of the facilities' environmental footprint and take corrective action if needed.</p><p>Impact areas can change as researchers receive new data on the state and behaviour of species and shift the delineated boundaries. This is called updating impact boundaries.</p>",
                isOpen: false,
            },
            {
                question: "How does Nornickel study biodiversity across its divisions?",
                answer: "<p class=mb-10>During expeditions biologists research flora and fauna around the sites and on background territories outside the area of impact, where ecosystems remain pristine and can serve as a benchmark. Data on the composition of species in both areas form a basis for ongoing monitoring.</p><p>In a year, the Company conducts new research, compares results against historical data, and makes a separate benchmarking analysis for the area of impact and the background area. This benchmarking is needed as industrial operations are not always the cause of changes in the environment. Such changes may result from natural cycles, which can be tracked in the background area. Disturbances around industrial sites which are not typical of the background area are a signal to reduce certain negative impacts and start restoring ecosystems.</p>",
                isOpen: false,
            },
            {
                question: "What does Nornickel do to conserve biodiversity?",
                answer: "",
                mainPageItem: true,
                isOpen: false,
            },
            ],
            links: [
                {
                    id: 1,
                    title: 'Polar Division: <br/>Norilsk industrial District',
                    time: '15 minutes',
                    link: '/norilsk-division',
                },
                {
                    id: 10,
                    title: 'Art <br/>on an Expedition',
                    time: '5 minutes',
                    link: '/art-on-the-expedition',
                },
                {
                    id: 2,
                    title: 'Polar Division: <br/>Kola MMC location area',
                    time: '10 minutes',
                    link: '/kola-division'
                },
                {
                    id: 3,
                    title: 'Trans-Baikal Division: <br/> Gazimursky Distric',
                    time: '10 minutes',
                    link: '/transbaikal-division'
                },
                {
                    id: 4,
                    title: 'Norilsk <br/>Expedition',
                    time: '10 minutes',
                    link: '/expedition'
                },
                {
                    id: 5,
                    title: 'Scientific <br/>Expedition',
                    time: '15 minutes',
                    link: '/big-expedition',
                    big: true
                },
                {
                    id: 6,
                    title: 'Revitalising <br/>Lake Pyasino',
                    time: '10 minutes',
                    link: '/pyasino'
                },
                {
                    id: 7,
                    title: 'Protecting <br/>polar bear',
                    time: '15 minutes',
                    link: '/white-bears',
                    big: true
                },
                {
                    id: 8,
                    title: 'Integral Indicator <br/>of Ecosystem Health',
                    time: '5 minutes',
                    link: '/indicator'
                },
                {
                    id: 9,
                    title: 'Nature <br/>reserves',
                    time: '5 minutes',
                    link: '/treasures-of-biodiversity'
                },
            ],
            store: localStorage,
            formData: {
                name: '',
                mail: '',
                city: '',
                work: '',
                age: '',
                question: '',
                agree: false,
            }
        }
    },
    computed: {
        isFormIncomplete() {
            return this.formData.name.length > 0 && this.formData.mail.length > 0 && this.formData.city.length > 0 && this.formData.work.length > 0 && this.formData.age.length > 0 && this.formData.question.length > 0 && this.formData.agree;
        },
        isMobile() {
            return window.innerWidth <= 768
        },
    },
    methods: {
        isBig(item) {
            return item ? 'main-page__map-item_big' : ''
        },
        isActive(item) {
            return this.store.getItem((item.link).slice(1)) ? `main-page__map-item-${item.id}_active` : ''
        },
        async submitForm() {
            if(this.isSended) return;
            this.isSended = true;
            try {
                await axios.post('https://life.nornickel.com:30011/send-email', this.formData);
                alert('Your question has been sent! Thank you!');
                document.getElementById('myForm').reset();
                this.isSended = false;
                this.formData = {
                name: '',
                mail: '',
                city: '',
                work: '',
                age: '',
                question: '',
                agree: false,
            }

            } catch (error) {
                alert('Ошибка отправки. Пожалуйста, попродуйте еще раз.');
            }
        }
    },
};
</script>

<style lang="scss">
.main-page {
    overflow: hidden;
    .hero-main {
        // background-position: bottom;
        // background-image: url(../../public/img/hero-main.png);

        // @supports (background-image: url(../../public/img/hero-main.webp)) {
        //     background-image: url(../../public/img/hero-main.webp);
        // }

        // @media (max-width: 768px) {
        //     background-position: top;
        //     background-image: url(../../public/img/hero-main-mobile.png);

        //     @supports (background-image: url(../../public/img/hero-main-mobile.webp)) {
        //         background-image: url(../../public/img/hero-main-mobile.webp);
        //     }
        // }

        // @media (max-height: 53vw) and (min-width: 769px) {
        //     background-position: top;
        //     min-height: 130vh;
        // }

        &-strip {
            display: none;
        }

    }

    &__top {
        padding: 2.36rem 13.2rem 2.639rem 5.2086rem;
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            padding: 4.845rem 9.5rem 7.83rem 9.5rem;
        }

        &-descr {
            padding: 0.56rem 0 0 1.945rem;
            width: 45rem;
            margin-bottom: 5rem;
            font-size: 1.25rem;
            font-weight: 300;
            line-height: 130%;

            @media (max-width: 768px) {
                padding: 0;
                margin-bottom: 8.2rem;
                width: unset;
                font-size: 4.53334rem;
            }
        }

        &-text {
            padding-left: 1.95rem;
            border-left: 1px solid var(--Gray-1, #333);
            color: var(--copper, #d38235);
            font-size: 1.528rem;
            font-weight: 300;
            line-height: 130%;
            width: 40rem;

            @media (max-width: 768px) {
                padding-left: 2.6666rem;
                font-size: 4.8rem;
                width: unset;
            }
        }

        &-info {
            font-size: 1.042rem;
            font-weight: 300;
            line-height: 130%;
            width: 11.54rem;
            margin-bottom: 0.903rem;
        }

        img {
            width: 11.46rem;
            height: 7.014rem;
        }

        &-right {
            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    &__map {
        display: flex;
        flex-wrap: wrap;

        border-bottom: 0.06rem solid #626261;
        
        @media (max-width: 768px) {
            
        border-bottom: 0.3rem solid #626261;
        }
        &-item {
            position: relative;
            display: flex;
            // width: 25vw;
            width: 25%;
            // height: 34.7223rem;
            height: 34.2223rem;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            transition: all .3s ease;
            font-size: 1.527777rem;
            font-weight: 400;
            line-height: 110%;
            padding: 0 0 4rem 2rem;
            align-items: flex-end;
            border-left: 0.07rem solid #626261;
            border-top: 0.07rem solid #626261;
            box-sizing: border-box;
            overflow: hidden;
            @media(max-width: 768px) {
                width: 100%;
                height: 138.667rem;
                padding: 0 0 5.34rem 5.34rem;
                font-size: 4.8rem;
                border-left: none;
                
            border-top: 0.3rem solid #626261;
            }

            &:hover {
                color: #D38235;

                &::after {
                    opacity: 1;
                }
            }

            &::after {
                opacity: 0;
                position: absolute;
                transition: all .3s ease;
                content: '';
                width: 1.667rem;
                height: 1.667rem;
                right: 2.4rem;
                bottom: 2.4rem;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath d='M3.20312 20.4563L21.9461 1.71338' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M22 22.6357L22 1.63574L1 1.63574' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");

                @media(max-width: 768px) {
                    display: none;
                }
            }

            &_big {
                width: 50vw;
                width: 50%;

                @media(max-width: 768px) {
                    width: 100%;
                }
            }

            &-time {
                position: absolute;
                top: 2.9rem;
                right: 2.45rem;
                font-size: 1.319445rem;
                font-weight: 300;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    top: 1.81rem;
                }

                @media(max-width: 768px) {
                    top: 6.6666rem;
                    right: 5.07rem;
                    font-size: 5.06667rem;

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        top: 6.6666rem;
                    }
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -3;
                transition: all .3s ease;

                &.notActive {
                    z-index: -1;
                }
            }
            &-1,
            &-2,
            &-3,
            &-4,
            &-5,
            &-6,
            &-7,
            &-8,
            &-9,
            &-10 {
                &_active .notActive {
                    opacity: 0;
                }

                @media(min-width: 768px) {
                    &:hover .notActive {
                        opacity: 0;
                    }
                }
            }
        }

        &-video {
            position: absolute;
            width: auto;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            overflow: hidden;
            video{
                height: 100%;
                width: auto;
            }
            &-10 {
                top: -0.2rem;
                height: 100.8%;
            }

            &-2 {
                top: 0rem;
                        
                left: -0.1rem;
                height: 99%;
                width: 24.1rem;
            }

            &-3 {
                top: -0.06rem;
            }

            &-4 {
                width: 100%;
                video{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            &-5 {    
                width: 100%;
                video{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            &-6 {
                left: 0.35rem;
                top: 0.25rem; 
                height: 98.8%;
            }
            &-7 {
                left: 0.05rem;
                top: 0.25rem;
                height: 98.3%;
                @media (max-width: 768px) {
                    video{
                        object-position: 100% 50%;
                }
                }
            }

            &-8 {
                left: -0.3rem;
                top: 0.15rem;
                height: 99.2%;
                width: 21rem;
            }

            &-9 {
                left: 0.3rem;
                top: 0.25rem;
                height: 98.7%;
            }
            @media (max-width: 768px) {
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                video{
                    height: 100%;
                    width: 101%;
                    object-fit: cover;
                }
            }
        }
    }

    &__bottom {
        padding: 3.4722rem 8.19445rem 5.20834rem 8.19445rem;
        background: #f7f6f2;

        @media (max-width: 768px) {
            padding: 11.7334rem 5.3334rem 0 5.3334rem;
        }

        &-link {
            display: block;
            margin: 0 auto;
            width: 19.2366rem;
            border: 1px solid #000000;
            padding: 0.8rem 3.4722rem;
            border-radius: 50px;
            font-size: 1.25rem;
            color: #626261;
            font-weight: 400;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 4.8rem;
                width: 74rem;
                padding: 3rem 14rem;
            }
        }

        &-title {
            margin-top: 8.3333rem;
            margin-bottom: 4.16666rem;
            font-size: 2.36111rem;
            font-weight: 600;
            line-height: 130%;

            @media (max-width: 768px) {
                margin-bottom: 8.5334rem;
                margin-top: 24rem;
                font-size: 7.46667rem;
            }
        }

        .faq {
            @media (max-width: 768px) {
                margin: 0;
            }
        }
    }

    &__result-faq {
        border-top: 0.5px solid var(--grey, #626261);

        .answer {
            padding-left: 0.83334rem;
            padding-right: 9.0278rem;

            @media (max-width: 768px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .fqa-flex {
        display: flex;
        gap: 2.2223rem;
        margin-bottom: 1.389rem;

        .clue__hidden {
            @media (min-width: 768px) {
                width: 34.7rem;
                left: 21%;
            }
        }
    }

    .fqa-num {
        font-size: 1.5278rem;
        font-weight: 600;
        line-height: 130%;

        @media (max-width: 768px) {
            font-size: 5.86667rem;
        }
    }

    .fqa-text {
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 130%;

        @media (max-width: 768px) {
            font-size: 4.8rem;
        }
    }

    &__form {
        background: #f7f6f2;
        padding: 1.5625rem;
        iframe{
            width: 100%;
        }
        @media (max-width: 768px) {
            padding: 20.7334rem 5.3334rem 5.3334rem;
        }

        &-wrap {
            max-width: 50rem;
            margin: 0 auto;

            @media (max-width: 768px) {
                max-width: 100%;
            }
        }

        &-title {
            margin-bottom: 3.75rem;
            font-size: 2.125rem;
            font-weight: 600;
            line-height: 130%;

            @media (max-width: 768px) {
                margin-bottom: 8.5334rem;
                font-size: 7.46667rem;
            }
        }

        &-label {
            font-size: 1rem;
            font-weight: 500;
            line-height: normal;

            span {
                font-weight: 300;
            }

            @media (max-width: 768px) {
                font-size: 5.06667rem;
            }
        }

        &-wrap {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            margin-bottom: 2.19rem;

            @media (max-width: 768px) {
                gap: 5rem;
                margin-bottom: 7rem;
            }
        }

        &-input {
            height: 2.8125rem;
            border-radius: 10px;
            padding: 1rem;
            font-size: 1rem;
            line-height: normal;
            border: 1px solid #626261;
            background: rgba(98, 98, 97, 0.00);

            @media (max-width: 768px) {
                height: 11rem;
                padding: 2rem;
                font-size: 4.8rem;
            }
        }

        &-checkboxes {
            display: flex;
            flex-direction: column;
            gap: 0.71rem;

            @media (max-width: 768px) {
                gap: 5rem;
                margin-bottom: 7rem;
            }
        }

        &-flex {
            display: flex;
            gap: 2.19rem;

            @media (max-width: 768px) {
                flex-direction: column;
            }

            &-left {
                width: 32.56rem;

                @media (max-width: 768px) {
                    width: 100%;
                }
            }
        }

        &-check {
            display: flex;
            flex-direction: column;
            gap: 0.81rem;
            width: 9.8rem;

            @media (max-width: 768px) {
                width: 100%;
                gap: 4.5rem;
            }
        }

        &-textarea {
            margin-bottom: 2.81rem;
            height: 13.5rem;

            @media (max-width: 768px) {
                height: 50rem;
            }
        }

        &-footer {
            display: flex;
            gap: 1.76rem;
            font-size: 0.9375rem;
            font-weight: 300;
            line-height: 130%; 

            @media (max-width: 768px) {
                flex-direction: column;
            }

            &-check {
                width: 50%;

                @media (max-width: 768px) {
                    width: 100%;
                }
            }

            &-button-wrap {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.84rem;

                @media (max-width: 768px) {
                    width: 100%;
                    margin-top: 7rem;
                    gap: 3rem;

                    span {
                        font-size: 3.5rem;
                        line-height: normal;
                    }
                }
            }

            &-button {
                width: 100%;
                color: #FFF;
                border: unset;
                height: 2.91038rem;
                font-size: 1rem;
                font-weight: 500;
                border-radius: 23px;
                padding: 0.4375rem 9.375rem;
                background: #0077C8;
                border: 1px solid #0077C8;
                cursor: pointer;
                transition: all .2s;

                &:hover {
                    background: #00589c;
                    border: 1px solid #00589c;
                }

                &[disabled] {
                    background: transparent;
                    cursor: default;
                    color: #626261;
                    border: 1px solid #626261;
                }

                @media (max-width: 768px) {
                    height: 11rem;
                    font-size: 4.8rem;
                    line-height: normal;
                }
            }
        }
    }

    &__checkbox {
        &-item {
            position: relative;
            cursor: pointer;
            padding-left: 2rem;

            @media (max-width: 768px) {
                font-size: 4.8rem;
                line-height: normal;
                padding-left: 7.5rem;
            }
        }

        &-input {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
    }

    .main-page__checkbox-input+.main-page__checkbox-span {
        display: inline-flex;
        align-items: center;
    }

    .main-page__checkbox-input+.main-page__checkbox-span::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 1.25rem;
        height: 1.25rem;

        border: 1px solid #626261;
        border-radius: 3px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;

        @media (max-width: 768px) {
            width: 5rem;
            height: 5rem;
        }
    }

    .main-page__checkbox-input:checked+.main-page__checkbox-span::before {
        content: '';
        background-image: url(../../public/img/svg/check.svg);
    }
}
</style>