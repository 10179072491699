<template>
    <div class="treasures-4-container__wrap">
        <div class="treasures-4-margin">
            <div class="treasures-4-container">
                <div class="treasures-4-inner">

                    <div class="treasures-4-img treasures-4-img_0">
                        <video v-if="!isMobile" @loadeddata="videoLoadedZero" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/treasures-swiper-4-0.mov" type="video/mp4">
                            <source src="video/treasures-swiper-4-0.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingZero && !isMobile">
                            <img loading="lazy" src="img/treasures-swiper-4-0.webp" alt="">
                        </picture>
                    </div>

                    <div class="treasures-4-img treasures-4-img_1">
                        <video v-if="!isMobile" @loadeddata="videoLoadedOne" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/treasures-swiper-4-1.mov" type="video/mp4">
                            <source src="video/treasures-swiper-4-1.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingOne && !isMobile">
                            <img loading="lazy" src="img/treasures-swiper-4-1.webp" alt="">
                        </picture>
                    </div>

                    <div class="treasures-4-img treasures-4-img_2">
                        <video v-if="!isMobile" @loadeddata="videoLoadedTwo" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/treasures-swiper-4-2.mov" type="video/mp4">
                            <source src="video/treasures-swiper-4-2.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingTwo && !isMobile">
                            <img loading="lazy" src="img/treasures-swiper-4-2.webp" alt="">
                        </picture>
                    </div>

                    <div class="treasures-4-img treasures-4-img_3">
                        <video v-if="!isMobile" @loadeddata="videoLoadedThree" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/treasures-swiper-4-3.mov" type="video/mp4">
                            <source src="video/treasures-swiper-4-3.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingThree && !isMobile">
                            <img loading="lazy" src="img/treasures-swiper-4-3.webp" alt="">
                        </picture>
                    </div>

                    <div class="treasures-4-img treasures-4-img_4">
                        <video v-if="!isMobile" @loadeddata="videoLoadedFour" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/treasures-swiper-4-4.mov" type="video/mp4">
                            <source src="video/treasures-swiper-4-4.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingFour && !isMobile">
                            <img loading="lazy" src="img/treasures-swiper-4-4.webp" alt="">
                        </picture>
                    </div>

                    <div class="treasures-4-img treasures-4-img_5">
                        <video v-if="!isMobile" @loadeddata="videoLoadedFive" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/treasures-swiper-4-5.mov" type="video/mp4">
                            <source src="video/treasures-swiper-4-5.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingFive && !isMobile">
                            <img loading="lazy" src="img/treasures-swiper-4-5.webp" alt="">
                        </picture>
                    </div>

                    <div class="treasures-4-item treasures-4-item_1">
                        <div class="treasures-3-item__title">Demoiselle crane</div>
                        <div class="treasures-3-item__descr">
                            This species is listed in the Red Data Book, and ornithologists estimate that no more than 240,000 demoiselle cranes are currently in existence globally.
                        </div>
                    </div>

                    <div class="treasures-4-item treasures-4-item_2">
                        <div class="treasures-3-item__title">Mandarin duck</div>
                        <div class="treasures-3-item__descr">
                            Male mandarin ducks change to their colourful plumage during the mating season, with their feathers coloured yellow, orange, blue, green, and black and white. However, the rest of the time males keep a much lower profile and look almost identical to the grey and brown females. Not so long ago, mandarin ducks were endangered, but right now their populations are gradually recovering. It is listed in the Red Data Book.
                        </div>
                    </div>

                    <div class="treasures-4-item treasures-4-item_3">
                        <div class="treasures-3-item__title">Siberian weasel</div>
                        <div class="treasures-3-item__descr">
                            A medium-sized and a predominantly nocturnal creature. Siberian weasels often live not far from human settlements, where they hunt rats and mice and sometimes can also attack poultry and cats. The species is listed in the Red Data Book.
                        </div>
                    </div>

                    <div class="treasures-4-item treasures-4-item_4">
                        <div class="treasures-3-item__title">Corsac fox</div>
                        <div class="treasures-3-item__descr">
                            Corsac foxes look like typical foxes but are noticeably smaller and more social, with several animals often living together. In times of danger, corsacs feign death – only to escape at their first chance. Corsacs are listed in the Red Data Book as a species of least-concern.
                        </div>
                    </div>

                    <div class="treasures-4-item treasures-4-item_5">
                        <div class="treasures-3-item__title">Siberian musk deer</div>
                        <div class="treasures-3-item__descr">
                            These medium-sized deer have no antlers but have long tusks that project downward from their mouth, which they use to compete with other males for females. There are known cases of male dusk deer fatally wounding their opponents this way. The animal is included in the Red Data Book as a vulnerable species.
                        </div>
                    </div>

                    <div class="treasures-4-item treasures-4-item_6">
                        <div class="treasures-3-item__title">Wolverine</div>
                        <div class="treasures-3-item__descr">
                            This member of the marten species reaches the size of an average dog. Wolverines are known for their great ferocity and strength out of proportion to their size, with relative attack even humans when desperate. The wolverine is red-listed.
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ScrollSwiper from './CustomScrollSwiper';


export default {
    data() {
        return {
            isLoadingZero: false,
            isLoadingOne: false,
            isLoadingTwo: false,
            isLoadingThree: false,
            isLoadingFour: false,
            isLoadingFive: false,
            isMobile: (window.innerWidth <= 768),
        }
    },
    mounted() {
        this.$nextTick(function () {
            const wrap = document.querySelector('.treasures-4-container__wrap');
            const container = document.querySelector('.treasures-4-container');
            const inner = document.querySelector('.treasures-4-inner');
            const marg = document.querySelector('.treasures-4-margin');
            new ScrollSwiper(wrap, container, inner, marg);
            window.addEventListener('resize', this.onResize);
        })
    },
    unmounted(){
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        videoLoadedZero() {
            this.isLoadingZero = true;
        },
        videoLoadedOne() {
            this.isLoadingOne = true;
        },
        videoLoadedTwo() {
            this.isLoadingTwo = true;
        },
        videoLoadedThree() {
            this.isLoadingThree = true;
        },
        videoLoadedFour() {
            this.isLoadingFour = true;
        },
        videoLoadedFive() {
            this.isLoadingFive = true;
        },
        onResize() {
            this.isMobile = (window.innerWidth <= 768);
        },
    }
}
</script>


<style lang="scss">
.treasures-4 {
    position: relative;

    &-margin {
        display: flex;
        width: 100%;
        height: 100%;
    }

    &-inner {
        position: relative;
        flex-shrink: 0;
        position: relative;
        display: flex;
        width: 188rem;
        height: 38.12rem;
        background-image: url(../../public/img/treasures-scroll-4.jpg);
        background-repeat: no-repeat;
        background-size: 100%;

        @media (max-width: 768px) {
            width: 689rem;
            height: 100%;
            background-image: url(../../public/img/treasures-scroll-4-mobile.png);
        }
    }

    &-container {
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        height: 100vh;
        width: 100%;

        overflow: hidden;

        @media (max-width: 768px) {


            width: unset;
            height: 144.467rem;
            overflow-x: scroll;
            overflow-y: hidden;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                width: 0;
            }
        }
    }

    &-img {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        picture,
        img {
            flex-shrink: 0;
            width: 100%;
            height: auto;
        }

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
        }

        &_0 {
            top: 6.5rem;
            left: 8rem;
            width: 23.16rem;
            height: 28.81rem;
            video {
                transform: translate(-50%, -50%) scaleX(-1);
            }
        }
        &_1 {
            top: 20.2rem;
            left: 36rem;
            width: 17.89rem;
            height: 16.20rem;
            video {
                transform: translate(-50%, -50%) scaleX(-1);
            }
        }
        &_2 {
            top: 23rem;
            left: 59.8rem;
            width: 22.46rem;
            height: 15.49rem;
            video {
                transform: translate(-50%, -57%);
            }
        }
        &_3 {
            top: 15.1rem;
            left: 87rem;
            width: 24.86rem;
            height: 22.82rem;
            video {
                transform: translate(-50%, -50%) scaleX(-1);
            }
        }
        &_4 {
            top: 8.5rem;
            left: 119.3rem;
            width: 24.44rem;
            height: 27.82rem;
            video {
                transform: translate(-50%, -48%);
                width: 136%;
            }
        }
        &_5 {
            top: 15.6rem;
            left: 159rem;
            width: 25.28rem;
            height:21.20rem;
            video {
                transform: translate(-50%, -48%);
                width: 136%;
            }
        }
    }

    &-item {
        position: absolute;

        &__title {
            padding: 0.4375rem 0.625rem;
            margin-bottom: 1.06rem;
            width: max-content;
            font-size: 0.9375rem;
            font-weight: 300;
            line-height: 130%;
            color: #fff;
            border-radius: 1.6875rem;
            background: var(--grey, #626261);

            @media (max-width: 768px) {
                font-size: 4rem;
                padding: 1.8716rem 2.6737rem;
                border-radius: 7.22rem;
                margin-bottom: 4.5456rem;
            }
        }

        &__descr {
            font-size: 1.0417rem;
            font-weight: 300;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 4rem;
            }
        }

        &_1 {
            width: 14.7rem;
            top: 4rem;
            left: 6rem;

            @media (max-width: 768px) {
                width: 59.2rem;
                top: 13rem;
                left: 14rem;
            }
        }

        &_2 {
            width: 23.4rem;
            top: 1rem;
            left: 33rem;

            @media (max-width: 768px) {
                width: 82rem;
                top: 5rem;
                left: 118rem;
            }
        }

        &_3 {
            width: 18rem;
            top: 10rem;
            left: 64rem;

            @media (max-width: 768px) {
                width: 69rem;
                top: 31rem;
                left: 233rem;
            }
        }

        &_4 {
            width: 22.778rem;
            top: 7rem;
            left: 97rem;

            @media (max-width: 768px) {
                width: 80.778rem;
                top: 20rem;
                left: 355rem;
            }
        }

        &_5 {
            width: 24.23rem;
            top: 0;
            left: 131rem;

            @media (max-width: 768px) {
                width: 93rem;
                top: 0;
                left: 479rem;
            }
        }

        &_6 {
            width: 19.32rem;
            top: 2rem;
            left: 164rem;

            @media (max-width: 768px) {
                width: 73.32rem;
                top: 8rem;
                left: 600rem;
            }
        }
    }
}

.swiper {
    display: none;

    @media (max-width: 768px) {
        display: block;
    }
}
</style>