<template>
	<div class="audio-wrap">
		<div class="audio-play" @click="toggleAudio">
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M11 5L6 9H2V15H6L11 19V5Z" stroke="#0077C8" stroke-width="1.5" stroke-linecap="round"
					stroke-linejoin="round" />
				<path
					d="M19.07 4.92969C20.9447 6.80496 21.9979 9.34805 21.9979 11.9997C21.9979 14.6513 20.9447 17.1944 19.07 19.0697M15.54 8.45969C16.4774 9.39733 17.004 10.6689 17.004 11.9947C17.004 13.3205 16.4774 14.592 15.54 15.5297"
					stroke="#0077C8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
			</svg>

		</div>
		<div class="audio-popup">
			<div class="audio-popup__icon">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M11 5L6 9H2V15H6L11 19V5Z" stroke="white" stroke-width="1.5" stroke-linecap="round"
						stroke-linejoin="round" />
					<path
						d="M19.07 4.92969C20.9447 6.80496 21.9979 9.34805 21.9979 11.9997C21.9979 14.6513 20.9447 17.1944 19.07 19.0697M15.54 8.45969C16.4774 9.39733 17.004 10.6689 17.004 11.9947C17.004 13.3205 16.4774 14.592 15.54 15.5297"
						stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
				</svg>

			</div>
			<div class="audio-popup__text">For a&nbsp;more immersive experience, we&nbsp;recommend turning on&nbsp;the sound</div>
			<div class="audio-popup__btns">
				<div class="audio-popup__btn" @click="toggleAgree">Yes</div>
				<div class="audio-popup__btn" @click="closePopup">No</div>
			</div>
		</div>
	</div>

</template>


<script>
export default {
	props: {
		audioSrc: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			audio: null,
			store: localStorage,
			isPlaying: false,
			isAgree: false,
			isOpenPopup: false
		};
	},
	mounted() {
		function isSafari() {
			return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		}

		function isFirefox() {
			return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
		}
		if (isSafari() || isFirefox()) {
			document.querySelector('.audio-wrap').classList.add('audio-wrap_hide');
		}
		this.$nextTick(function () {

			if (!isSafari() && !isFirefox()) {
				this.isPlaying = true;
			}
			const visibilityHandler = () => {
				if (document.hidden) {
					this.audio.pause();
				} else {
					if (this.isPlaying) {
						this.audio.play().then(() => {
						}).catch(error => {

							this.catchFailPlaying();
							console.error('Error attempting to play', error);
						});
					}
				}
			}
			const handleWindowBlur = () => {
				if (this.audio) {
					this.audio.pause();
				}
			};

			const handleWindowFocus = () => {

				if (this.audio && this.isPlaying) {
					this.audio.play().then(() => {
						this.isPlaying = true;
					}).catch(error => {

						this.catchFailPlaying();
						console.error('Error attempting to play', error);
					});
				}
			};

			document.addEventListener('visibilitychange', visibilityHandler);
			window.addEventListener('blur', handleWindowBlur);
			window.addEventListener('focus', handleWindowFocus);
		})
	},
	watch: {
		isPlaying: {
			handler(bool) {
				const btn = document.querySelector('.audio-play');
				if (bool) {
					if (this.store.getItem('isAgree') === 'false') {
						this.audio.muted = true;
					}
					this.audio.play().then(() => {
						if (this.store.getItem('isAgree') === 'false') {
							this.isPlaying = false
						} else {
							this.audio.muted = false;
							this.isAgree = true;
							btn.classList.add('audio-play_active');
						}
					}).catch(error => {

						this.catchFailPlaying();
						console.error('Error attempting to play', error);
					});
					this.store.setItem('isPlaying', true);
				} else {

					btn.classList.remove('audio-play_active');
					this.audio.pause();
					this.store.setItem('isPlaying', false);
				}
			}
		},
		isAgree: {
			handler(bool) {
				this.store.setItem('isAgree', bool);

			}
		},
		isOpenPopup: {
			handler(bool) {

				const popup = document.querySelector('.audio-popup');
				if (bool) {

					popup.classList.add('audio-popup_active');
				} else {
					popup.classList.remove('audio-popup_active');
				}
			}
		},
		audioSrc: {
			immediate: true,
			handler(newSrc) {
				if (this.audio) {
					this.audio.pause();
				}
				this.audio = null;

				this.audio = new Audio(newSrc);
				this.audio.loop = true;
				this.audio.volume = 0.5;
				if (this.isPlaying) {
					this.audio.play().catch(error => {
						this.catchFailPlaying();
						console.error('Error attempting to play', error);
					});
				}
			}
		}
	},
	methods: {
		toggleAgree() {
			this.isOpenPopup = false;
			this.isPlaying = true;
			this.isAgree = true;
			this.store.setItem('isAgree', true);
		},
		catchFailPlaying() {
			this.isPlaying = false;
			this.isAgree = false;
			this.isOpenPopup = true;
		},
		closePopup() {
			this.isOpenPopup = false;
			this.isPlaying = false;
			this.isAgree = false;
			this.store.setItem('isAgree', false);
		},
		toggleAudio() {
			if (this.isPlaying) {

				this.isPlaying = false;

				this.isAgree = false;
			} else {
				this.isPlaying = true;

				this.isAgree = true;
			}
		},
	},
	beforeDestroy() {
		if (this.audio) {
			this.audio.pause();
		}
	}
};
</script>

<style lang="scss">
.audio-wrap {
	&_hide {
		display: none;
		pointer-events: none;
	}
}

.audio-play {
	margin-top: 0.8rem;
	margin-right: 1.20rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.69rem;
	height: 1.69rem;
	cursor: pointer;

	svg path:nth-child(2) {
		opacity: 0;
	}

	&_active {
		svg path:nth-child(2) {
			opacity: 1;
		}
	}

	@media (max-width: 768px) {

		margin-top: 2.7rem;
		margin-right: 3rem;
		width: 8rem;
		height: 8rem;
	}
}

.audio-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	background: #0077C8;
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: opcity ease 0.3s;

	&_active {
		opacity: 1;
		pointer-events: auto;
	}

	&__icon {
		display: flex;
		width: 1.69rem;
		height: 1.69rem;

		@media (max-width: 768px) {

			width: 8.9rem;
			height: 8.9rem;
		}

		svg {
			width: 100%;
			height: auto;
		}
	}

	&__text {
		margin-top: 1.27rem;
		max-width: 26.20rem;
		font-size: 1.27rem;
		line-height: 120%;
		text-align: center;

		@media (max-width: 768px) {

			margin-top: 7rem;
			max-width: 81rem;
			font-size: 6rem;
		}
	}

	&__btns {
		margin-top: 2.46rem;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 768px) {
			flex-direction: column;
			margin-top: 12rem;
		}
	}

	&__btn {
		width: 13.66rem;
		height: 3.31rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 1.62rem;
		font-size: 1.13rem;
		font-weight: 500;
		color: #fff;
		cursor: pointer;
		border: 0.07rem solid #fff;
		transition: background ease 0.3s, color ease 0.3s;

		&+.audio-popup__btn {
			margin-left: 1.27rem;

			@media (max-width: 768px) {
				margin-left: 0;
				margin-top: 6rem;
			}
		}

		@media (hover: hover) {
			&:hover {
				background: #fff;
				color: #0077C8;
			}
		}

		@media (max-width: 768px) {

			border-radius: 8rem;
			font-size: 6rem;
			width: 66rem;
			height: 15rem;

			border: 0.3rem solid #fff;
		}
	}
}
</style>