var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"faq"},[_c('div',{staticClass:"header",on:{"click":function($event){return _vm.handleClick(_vm.faq)}}},[_c('div',{staticClass:"question",domProps:{"innerHTML":_vm._s(_vm.faq.question)}}),_c('div',{staticClass:"faq_svg"},[_c('svg',{staticClass:"faq_svg__minus",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"29","height":"2","viewBox":"0 0 29 2","fill":"none"}},[_c('path',{attrs:{"d":"M0 1.0802H29","stroke":"black"}})]),_c('svg',{staticClass:"faq_svg__minus",class:{ 'faq_svg__minus_close': _vm.faq.isOpen },attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"29","height":"2","viewBox":"0 0 29 2","fill":"none"}},[_c('path',{attrs:{"d":"M0 1.0802H29","stroke":"black"}})])])]),(_vm.faq.mainPageItem)?_c('div',{staticClass:"answer",class:{ 'open': _vm.faq.isOpen }},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("03")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v("We support ")]),_c('BaseTooltip',{attrs:{"word":'specially protected natural areas'}},[_c('span',[_vm._v(" Specially protected natural areas (SPNA) are portions of land and water where the nature and biodiversity are protected by the government. They are created to preserve rare and vulnerable animals, plants and ecosystems, and support natural processes and cultural values. SPNAs are nature reserves, national parks, natural protected areas and other sites with special rules and restrictions established to protect nature in the long run. In Russia, there are 13,000 SPNAs spreading over 12% of its territory. ")])]),_c('span',[_vm._v(" and select research programmes.")])],1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)]):_c('div',{staticClass:"answer",class:{ 'open': _vm.faq.isOpen }},[(_vm.faq.answer)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.faq.answer)}}):_c('div',[_vm._t("default")],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("01")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v(" We organise annual research expeditions together with the Russian Academy of Sciences to study ecosystems across our divisions and monitor biodiversity. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("02")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v(" We perform tests and experiments to contribute to natural restoration of habitats. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("04")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v(" We sponsor programmes to study and protect rare and extinct species of plants and animals. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("05")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v(" We rehabilitate disturbed lands to bring them to their initial or a better state. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("06")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v(" We utilise SO₂ capture solutions across operating and designed facilities to reduce air emissions (Sulphur Programme). ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("07")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v(" We build and upgrade local treatment facilities to reduce the polluting impact of wastewater discharges. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("08")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v(" We arrange for juveniles of valuable fish species to be released into Russian rivers. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("09")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v(" We monitor landfill sites and the health of surrounding ecosystems. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("10")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v(" We observe noise requirements during blasting and other activities. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("11")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v(" We use environmental monitoring techniques to keep track of pollution. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("12")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v(" We make our facilities better prepared for emergencies that can affect the environment. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fqa-flex"},[_c('div',{staticClass:"fqa-num"},[_vm._v("13")]),_c('div',{staticClass:"fqa-text"},[_c('span',[_vm._v(" We limit certain activities in areas with the greatest biodiversity value, which feature protected species and unique natural ecosystems. ")])])])
}]

export { render, staticRenderFns }