<template>
    <div class="hero-main">
        <video autoplay loop muted playsinline class="hero-main__video" preload="auto">
            <source src="video/main-mobile.mp4" media="(max-width: 768px)" type="video/mp4">
            <source src="video/main.mp4" type="video/mp4">
        </video>
        <div class="container">
            <a href="/" class="hero-main__link">
                <svg v-if="this.$route.path === '/'" width="118" height="27" viewBox="0 0 118 27" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3710_34351)">
                        <mask id="mask0_3710_34351" maskUnits="userSpaceOnUse" x="0" y="0" width="118" height="28"
                            style="mask-type: luminance;">
                            <path d="M118 0H0V27.0231H118V0Z" fill="white"></path>
                        </mask>
                        <g mask="url(#mask0_3710_34351)">
                            <path
                                d="M31.6923 21.0148C30.4964 22.3195 29.0449 23.2419 27.4636 23.7288V3.29602C29.0449 3.78207 30.4964 4.70444 31.6923 6.00656C33.5301 8.01074 34.5418 10.6776 34.5418 13.5107C34.5418 16.3472 33.5301 19.0124 31.6923 21.0148ZM12.4022 14.4159V3.49662L24.8004 12.6115V23.5282L12.4022 14.4159ZM9.74298 23.7288C8.16084 23.2444 6.70855 22.3195 5.51348 21.0148C3.67408 19.0124 2.65997 16.3472 2.65997 13.5124C2.65997 10.6776 3.67408 8.01074 5.51348 6.00656C6.70855 4.70444 8.16084 3.78207 9.74298 3.29602V23.7288ZM24.8004 0V9.11399L12.4022 0.000857224C5.55281 0.000857224 0 6.05028 0 13.5124C0 20.9762 5.55281 27.0231 12.4022 27.0231V17.9091L24.8004 27.0231C31.6514 27.0231 37.2042 20.9762 37.2042 13.5107C37.2042 6.05028 31.6514 0 24.8004 0Z"
                                fill="white"></path>
                        </g>
                        <g clip-path="url(#clip1_3710_34351)">
                            <path
                                d="M78.1864 13.8779L75.2326 9.14942H72.6236V18.381H75.4372V13.7422L78.3588 18.381H80.9702V9.14942H78.1887V13.8779H78.1864ZM59.429 9H57.9739C56.0522 9 54.4799 10.3677 54.4799 12.494V15.0709C54.4799 17.2064 56.0522 18.5649 57.9739 18.5649H59.429C61.3507 18.5649 62.923 17.1926 62.923 15.0709V12.494C62.923 10.2574 61.3507 9 59.429 9ZM59.9393 15.2755C59.9393 15.8869 59.4382 16.388 58.829 16.388H58.5762C57.9647 16.388 57.4659 15.8869 57.4659 15.2755V12.2826C57.4659 11.6711 57.967 11.1723 58.5762 11.1723H58.829C59.4405 11.1723 59.9393 11.6734 59.9393 12.2826V15.2755ZM82.2666 18.381H85.2366V9.14942H82.2666V18.381ZM115.483 15.8892V9.14942H112.513V18.381H118.998V15.8892H115.481H115.483ZM91.0684 9H89.7374C87.8157 9 86.2434 10.3677 86.2434 12.494V15.0709C86.2434 17.2064 87.8157 18.5649 89.7374 18.5649H91.0684C92.9901 18.5649 94.5624 17.1926 94.5624 15.0709V14.9537H91.5787V15.2778C91.5787 15.8892 91.0776 16.3903 90.4684 16.3903H90.3397C89.7283 16.3903 89.2271 15.8892 89.2271 15.2778V12.2826C89.2271 11.6711 89.7283 11.1723 90.3397 11.1723H90.4684C91.0799 11.1723 91.5787 11.6734 91.5787 12.2826V12.7331H94.5624V12.494C94.5624 10.2574 92.9901 9 91.0684 9ZM71.7753 12.5377V12.0941C71.7753 10.2091 70.203 9.14942 68.2813 9.14942H63.9299V18.3787H66.8584V15.6456H67.7319L68.9663 18.3787H72.0443L70.3226 14.9973C71.203 14.5123 71.7776 13.6756 71.7776 12.5354L71.7753 12.5377ZM68.8859 12.432C68.8859 12.7676 68.7158 13.5193 67.856 13.5193H66.8584V11.2206H67.9779C68.2767 11.2206 68.8859 11.5677 68.8859 12.2872V12.4343V12.432ZM50.6594 13.7882L47.6113 9.14942H45V18.381H47.8136V13.7032L50.8617 18.3833H53.4707V9.14942H50.6571V13.7882H50.6594ZM107.621 14.8778H110.968V12.6825H107.621V11.5585H111.272V9.14942H104.651V18.381H111.543V15.972H107.619V14.8778H107.621ZM103.941 9.14942H100.73L99.1621 12.6389H98.4289V9.14942H95.4589V18.381H98.4289V15.1008H99.1576L100.76 18.381H104.169L101.652 13.7238L103.941 9.14942Z"
                                fill="white"></path>
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_3710_34351">
                            <rect width="118" height="27" fill="white"></rect>
                        </clipPath>
                        <clipPath id="clip1_3710_34351">
                            <rect width="74" height="9.56262" fill="white" transform="translate(45 9)"></rect>
                        </clipPath>
                    </defs>
                </svg>


                <svg v-else-if="this.$route.path !== '/'" width="159" height="33" viewBox="0 0 159 33" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_335_4036)">
                        <g clip-path="url(#clip1_3710_34351)">
                            <path
                                d="M78.1864 13.8779L75.2326 9.14942H72.6236V18.381H75.4372V13.7422L78.3588 18.381H80.9702V9.14942H78.1887V13.8779H78.1864ZM59.429 9H57.9739C56.0522 9 54.4799 10.3677 54.4799 12.494V15.0709C54.4799 17.2064 56.0522 18.5649 57.9739 18.5649H59.429C61.3507 18.5649 62.923 17.1926 62.923 15.0709V12.494C62.923 10.2574 61.3507 9 59.429 9ZM59.9393 15.2755C59.9393 15.8869 59.4382 16.388 58.829 16.388H58.5762C57.9647 16.388 57.4659 15.8869 57.4659 15.2755V12.2826C57.4659 11.6711 57.967 11.1723 58.5762 11.1723H58.829C59.4405 11.1723 59.9393 11.6734 59.9393 12.2826V15.2755ZM82.2666 18.381H85.2366V9.14942H82.2666V18.381ZM115.483 15.8892V9.14942H112.513V18.381H118.998V15.8892H115.481H115.483ZM91.0684 9H89.7374C87.8157 9 86.2434 10.3677 86.2434 12.494V15.0709C86.2434 17.2064 87.8157 18.5649 89.7374 18.5649H91.0684C92.9901 18.5649 94.5624 17.1926 94.5624 15.0709V14.9537H91.5787V15.2778C91.5787 15.8892 91.0776 16.3903 90.4684 16.3903H90.3397C89.7283 16.3903 89.2271 15.8892 89.2271 15.2778V12.2826C89.2271 11.6711 89.7283 11.1723 90.3397 11.1723H90.4684C91.0799 11.1723 91.5787 11.6734 91.5787 12.2826V12.7331H94.5624V12.494C94.5624 10.2574 92.9901 9 91.0684 9ZM71.7753 12.5377V12.0941C71.7753 10.2091 70.203 9.14942 68.2813 9.14942H63.9299V18.3787H66.8584V15.6456H67.7319L68.9663 18.3787H72.0443L70.3226 14.9973C71.203 14.5123 71.7776 13.6756 71.7776 12.5354L71.7753 12.5377ZM68.8859 12.432C68.8859 12.7676 68.7158 13.5193 67.856 13.5193H66.8584V11.2206H67.9779C68.2767 11.2206 68.8859 11.5677 68.8859 12.2872V12.4343V12.432ZM50.6594 13.7882L47.6113 9.14942H45V18.381H47.8136V13.7032L50.8617 18.3833H53.4707V9.14942H50.6571V13.7882H50.6594ZM107.621 14.8778H110.968V12.6825H107.621V11.5585H111.272V9.14942H104.651V18.381H111.543V15.972H107.619V14.8778H107.621ZM103.941 9.14942H100.73L99.1621 12.6389H98.4289V9.14942H95.4589V18.381H98.4289V15.1008H99.1576L100.76 18.381H104.169L101.652 13.7238L103.941 9.14942Z"
                                fill="white"></path>
                        </g>
                        <path d="M16.7136 21.8836L33.4271 33V11.1455L16.7136 0V21.8836Z" fill="#00589C" />
                        <path d="M16.7136 0C7.48721 0 0 7.39153 0 16.5C0 25.6085 7.48721 33 16.7136 33V0Z"
                            fill="#0080C8" />
                        <path
                            d="M33.4272 33C42.6535 33 50.1408 25.6085 50.1408 16.5C50.1408 7.39153 42.6535 0 33.4272 0V33Z"
                            fill="#0080C8" />
                    </g>
                    <defs>
                        <clipPath id="clip0_335_4036">
                            <rect width="159" height="33" fill="white" />
                        </clipPath>
                        
                        <clipPath id="clip1_3710_34351">
                            <rect width="74" height="9.56262" fill="white" transform="translate(45 9)"></rect>
                        </clipPath>
                    </defs>
                </svg>
            </a>
            <div class="hero-main__wrap">
                <h1 class="hero-main__title">
                    Preserving ecosystems
                </h1>
                <p class="hero-main__descr">
                    Learn about how Nornickel preserves natural ecosystems, which green technologies proved
                    to&nbsp;be&nbsp;the best for restoring water bodies, where to&nbsp;find relict species and how
                    Nornickel saves polar bears.
                </p>
            </div>
        </div>

        <div v-if="isButton" class="hero-main__button" @click.prevent="scrollToMain()">
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                <path
                    d="M30.4508 18.0598C30.4508 18.3748 30.3458 18.5848 30.1358 18.7948L21.7358 27.1948C21.3158 27.6148 20.6858 27.6148 20.2658 27.1948L11.8658 18.7948C11.4458 18.3748 11.4458 17.7448 11.8658 17.3248C12.2858 16.9048 12.9158 16.9048 13.3358 17.3248L21.0008 24.9898L28.6658 17.3248C29.0858 16.9048 29.7158 16.9048 30.1358 17.3248C30.3458 17.5348 30.4508 17.8498 30.4508 18.0598Z"
                    fill="white" />
                <path
                    d="M42 21C42 32.55 32.55 42 21 42C9.45 42 -4.13073e-07 32.55 -9.17939e-07 21C-1.12448e-06 16.275 1.575 11.865 4.41 8.085C4.725 7.665 5.46 7.56 5.88 7.875C6.3 8.19 6.405 8.925 6.09 9.345C3.465 12.705 2.1 16.8 2.1 21C2.1 31.395 10.605 39.9 21 39.9C31.395 39.9 39.9 31.395 39.9 21C39.9 10.605 31.395 2.1 21 2.1C17.64 2.1 14.28 3.045 11.34 4.725C10.815 5.04 10.185 4.83 9.87 4.41C9.555 3.885 9.765 3.255 10.185 2.94C13.545 1.05 17.22 -7.5271e-07 21 -9.17939e-07C32.55 -1.42281e-06 42 9.45 42 21Z"
                    fill="white" />
            </svg>
        </div>
        <div class="hero-main-strip"></div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        descr: {
            type: String,
            default: ''
        },
        img: {
            type: String,
            default: ''
        },
        imgMobile: {
            type: String,
            default: ''
        },
        isButton: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        image() {
            if (window.innerWidth > 768) {
                return 'url(' + this.img + ')'
            } else {
                return 'url(' + this.imgMobile + ')'
            }

        },
        isMobile() {
            return window.innerWidth <= 768
        },
    },
    methods: {
        scroll() {
            const el = document.getElementById('main');
            const y = el.getBoundingClientRect().top + window.screenY;

            window.scrollBy({ top: y, behavior: 'smooth' });
        },
        scrollToMain() {
            this.scroll()
        }
    }
}
</script>

<style lang="scss">
.hero-main {
    position: relative;
    min-height: 100vh;
    width: 100%;
    max-width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;


    @media (max-width: 768px) {
        background-position: top;
    }

    &__video {
        min-height: 100vh;
        width: 100%;
        object-fit: cover;
    }

    &-strip {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2.2rem;
        height: 8.125rem;
        background-image: url(../../public/img/strip.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width: 768px) {
            left: -107px;
            right: 0;
            bottom: -4.2rem;
            height: 26.67rem;
        }
    }

    .container {
        position: absolute;
        inset: 0;
        // padding: 31vh 5.03rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        @media (max-width: 768px) {
            padding: 18.8rem 10.1rem;
        }
    }

    &__wrap {
        width: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 19vh;

        @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 27.6vh;
        }
    }

    &__title {
        margin-bottom: 0.5vh;
        font-size: 4.9vh;
        font-weight: 600;
        line-height: 120%;
        text-transform: uppercase;
        color: var(--copper, #D38235);

        @media (max-width: 768px) {
            margin-bottom: 5rem;
            font-size: 9.56rem;
            text-align: center;
            line-height: 103%;
        }
    }

    &__descr {
        // width: 26.375rem;
        text-align: center;
        // font-size: 1.125rem;
        width: 55vh;
        font-size: 2.1vh;
        font-weight: 400;
        line-height: 120%;
        color: var(--gray-1, #333);

        @media (max-width: 768px) {
            font-size: 4.8rem;
            width: unset;
            line-height: 125%;
        }
    }

    &__link {
        position: absolute;
        top: 1.25rem;
        left: 1.25rem;
        width: 9.9375rem;
        height: 2.0625rem;
        display: block;

        @media (max-width: 768px) {
            top: 5rem;
            left: 4.3rem;
            width: 23.5rem;
            height: 5rem;
        }
    }

    &__button {
        position: absolute;
        bottom: 5rem;
        left: calc(50% - 21px);
        width: 42px;
        height: 42px;
        cursor: pointer;
        animation-duration: 1.2s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-name: scroll;
        background: none repeat scroll;


        @media (max-width: 768px) {
            bottom: 17rem;
        }
    }
}
</style>