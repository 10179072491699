<template>
    <div class="division">
        <BaseHero :title="'Polar Division: Norilsk industrial District. The nature havens of southern Taimyr'" :descr="heroDescr"
            :img="'img/hero-3.jpg'" :imgMobile="'img/hero3-mobile.jpg'" />
        <MainSection>
            <SectionWhite>
                <BaseTextBlock class="text mb-60" title="Division profile" :tooltip="true">
                    <p class="mb-10">
                        The mining, metals, and energy assets of Polar
                        <BaseTooltip :word="'Division'">
                            Companies create divisions by grouping together enterprises or facilities in
                                the same region or with shared tasks, such as feedstock transportation or energy supply.
                                Divisions help streamline the management of large organisations.
                        </BaseTooltip>: Norilsk industrial District are located in a permafrost area, in mountainous terrain. The facilities are part
                            of a dense industrial network, so the company needs to focus closely on the condition of the
                            natural environment in these areas.
                    </p>
                    <p>
                        Nornickel recognises its responsibility for the environment and pays meticulous
                            attention to the health of ecosystems across its divisions. We started restoring the
                            environmental balance in the north of the Krasnoyarsk Territory by exploring terrestrial and
                            aquatic flora and fauna and identifying areas affected by industrial activity.

                    </p>
                </BaseTextBlock>

                <div class="number-blocks text-block__wrap mb-60">
                    <BaseNumberBlock :textTop="'up&nbsp;to&nbsp;280&nbsp;days'" :textBottom="'of sustained frost'" />
                    <BaseNumberBlock :textTop="'up&nbsp;to&nbsp;500&nbsp;m'"
                        :textBottom="'permafrost depth'" />
                    <BaseNumberBlock :textTop="'-53&nbsp;˚С'" :textBottom="'minimum temperature'" />
                </div>

                <BigMap :circales="circales" :img="'img/division-big-map'" :imgMobile="'img/division-big-map-mobile'">
                    <BaseNumberBlock :textTop="'44 facilities'" :textBottom="'part of Polar Division: Norilsk Industrial District'" />
                    <div class="circales">
                        <div class="circale-block">
                            <svg class="circale-block__img" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                viewBox="0 0 14 14" fill="none">
                                <circle cx="7" cy="7" r="7" fill="#654EA3" />
                            </svg>

                            <div class="circale-block__text">
                                Industrial facilities
                            </div>
                        </div>
                        <div class="circale-block">
                            <svg class="circale-block__img" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                viewBox="0 0 14 14" fill="none">
                                <circle cx="7" cy="7" r="7" fill="#0077C8" />
                            </svg>

                            <div class="circale-block__text">
                                Energy facilities
                            </div>
                        </div>
                        <div class="circale-block">
                            <svg class="circale-block__img" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                viewBox="0 0 14 14" fill="none">
                                <circle cx="7" cy="7" r="7" fill="#009CA6" />
                            </svg>

                            <div class="circale-block__text">
                                Specially protected natural areas
                            </div>
                        </div>
                    </div>
                </BigMap>

                <section class="scroll">
                    <div class="scroll-bg bg-1">
                    </div>
                    <div class="scroll-bg bg-2">
                    </div>
                    <div class="scroll-bg bg-3">
                    </div>
                    <div class="scroll-wrap">
                        <div class="scroll-card card-1 text-block__descr">
                            <p>
                                Greater Norilsk
                            </p>
                            <p>
                                The Norilsk Industrial District, or&nbsp;Greater Norilsk, is&nbsp;an&nbsp;area made
                                    up&nbsp;of&nbsp;Nornickel&rsquo;s local facilities. The district also includes the
                                    cities of&nbsp;Norilsk, Dudinka, and several other settlements. In&nbsp;the
                                    permafrost environment, the company mines and processes copper, precious metals,
                                    tellurium, sulphur, and selenium.
                            </p>
                            <BaseDropdownInfo title="Facilities" :color="'#D38235'"
                                :text="'<ul><li>Talnakh Ore Cluster: 5 mines</li><li>Norilsk Ore Cluster: 1 mine</li><li>Talnakh Concentrator</li><li>Norilsk Concentrator</li><li>Nadezhda Metallurgical Plant</li><li>Copper Plant</li></ul>'" />
                        </div>
                        <div class="scroll-card card-2 text-block__descr">
                            <p>
                                Energy facilities
                            </p>
                            <p>
                                Heat and electricity are supplied to&nbsp;the Industrial District
                                    by&nbsp;Nornickel&rsquo;s heat and power plants, HPPs and gas production facilities.
                            </p>
                            <BaseDropdownInfo title="Facilities" :color="'#D38235'"
                                :text="'<ul><li>Yuzhno-Soleninskoye gas condensate field</li><li>Messoyakhskoye gas field</li><li>Severo-Soleninskoye gas condensate field</li><li>Pelyatkinskoye gas condensate field</li><li>HPP-1, Norilsk</li><li>HPP-2, Talnakh</li><li>HPP-3, Kayerkan</li><li>Kureyskaya Hydro Power Plant</li><li>Ust-Khantayskaya Hydro Power Plant</li></ul>'" />
                        </div>
                        <div class="scroll-card card-3 text-block__descr">
                            <p>
                                Putoransky Nature Reserve
                            </p>
                            <p>
                                A nature site crucial for the maintenance and restoration of local biodiversity. The
                                    reserve's boundaries are more than 50 km away from the Industrial District.
                                    According to experts, this is enough to keep local ecosystems intact and prevent the
                                    impact of the facilities.
                            </p>
                        </div>
                    </div>
                </section>

                <BaseSidebar class="mb-100">
                    <p>
                        As&nbsp;a&nbsp;result of&nbsp;research carried out in&nbsp;2023, scientists concluded that the Polar Division of&nbsp;the Norilsk Industrial District&rsquo;s industries had no&nbsp;significant impact on&nbsp;the biological diversity and ecosystems of&nbsp;the Putoransky Nature Reserve and its buffer zone.
                    </p>
                </BaseSidebar>

                <BaseTextBlock class="text mb-40" :title="'Area of&nbsp;impact of&nbsp;the industries in&nbsp;the Polar Division of&nbsp;the Norilsk Iindustrial District'"
                    :tooltip="true">
                    <p class="mb-10">
                        According to&nbsp;the 2023 Great Scientific Expedition, the maximum radius of&nbsp;the 
                        <BaseTooltip :word="'cumulative'">
                            We&nbsp;understand &ldquo;cumulative impact&rdquo; as&nbsp;the total impact of&nbsp;all human activity: the city, the Kola and other mining and metallurgical plants, as&nbsp;well as&nbsp;enterprises located in&nbsp;the study area. It&nbsp;is&nbsp;impossible to&nbsp;accurately separate the influence of&nbsp;one thing from that of&nbsp;another, just by&nbsp;the reaction of&nbsp;natural organisms in&nbsp;the area.
                        </BaseTooltip> impact of&nbsp;the Norilsk Industrial District on&nbsp;the terrestrial ecosystems of&nbsp;southern Taimyr is&nbsp;11&nbsp;km.

                    </p>
                    <p>
                        The effect of&nbsp;this industry is&nbsp;most significant at&nbsp;a&nbsp;distance of&nbsp;up&nbsp;to&nbsp;1&nbsp;km for terrestrial ecosystems and up&nbsp;to&nbsp;7&nbsp;km for aquatic ecosystems. The zone of&nbsp;moderate impact stretches to&nbsp;5&nbsp;km for terrestrial ecosystems, and for aquatic ecosystems, between 7&nbsp;and 15&nbsp;km. Further from industrial and residential zones, the signs of&nbsp;impact gradually disappear, eventually reaching zero. In&nbsp;areas where no&nbsp;industrial effects are detected, researchers have created control zones, for studying the pristine, or&nbsp;
                        <BaseTooltip :word="'background'">
                            In&nbsp;background areas, the researchers did not find any traces of&nbsp;industrial impact. The ecosystems here are preserved in&nbsp;their original, pristine form. The state of&nbsp;biodiversity in&nbsp;the background areas is&nbsp;taken as&nbsp;a&nbsp;reference point, and these data are used to&nbsp;observe the nature in&nbsp;the affected areas.
                        </BaseTooltip> state of&nbsp;local ecosystems.
                    </p>
                </BaseTextBlock>
                <div class="division-radius mb-60">
                    <picture>
                        <source media="(max-width: 768px)" :srcset="'img/division-radius-mobile.webp'">
                        <img loading="lazy" :src="'img/division-radius.webp'" alt="">
                    </picture>

                    <div class="division-radius__descr">
                        <span>*</span>
                        <span>
                            For aquatic ecosystems in&nbsp;the area of&nbsp;the Kayerkan-Nadezhda-Center, the zone of&nbsp;insignificant impact is&nbsp;15&ndash;25&nbsp;km.
                        </span>
                    </div>
                </div>

                <div class="division-factors mb-60">
                    <div class="division-factors__left">
                        <div class="division-factors__descr">
                            The main impact factors in the Polar Division of the Norilsk Industrial District include
                        </div>
                        <img loading="lazy" class="division-factors__img-grass" src="img/grass.png" alt="">
                    </div>
                    <ul class="division-factors__items">
                        <li class="division-factors__item">
                            <div class="division-factors__item-title">Adulterants</div>
                            <div class="division-factors__item-text">
                                Pollution of&nbsp;the environment by&nbsp;chemical emissions into the atmosphere, oil spills, and residues from industrial waste.
                            </div>
                        </li>
                        <li class="division-factors__item">
                            <div class="division-factors__item-title">Hazardous Substances</div>
                            <div class="division-factors__item-text">
                                The inflow of&nbsp;both directly and of&nbsp;more scattered sources of&nbsp;various toxic and acidic substances, as&nbsp;well as&nbsp;organic matter.
                            </div>
                        </li>
                        <li class="division-factors__item">
                            <div class="division-factors__item-title">Disturbance of&nbsp;the landscape</div>
                            <div class="division-factors__item-text">
                                Mechanical disturbance of&nbsp;the micro-landscape and soil and vegetation cover, as&nbsp;well as&nbsp;fragmentation of&nbsp;the environment, and the formation of&nbsp;quasi-natural and artificial habitats.
                            </div>
                        </li>
                        <li class="division-factors__item">
                            <div class="division-factors__item-title">Pyrogenic effects</div>
                            <div class="division-factors__item-text">
                                Fires can completely dramatically transform or&nbsp;completely destroy local ecosystems.
                            </div>
                        </li>
                    </ul>
                </div>
                <BaseSidebar class="mb-100">
                    <p>
                        Norilsk Nickel is&nbsp;doing a&nbsp;lot of&nbsp;work to&nbsp;neutralise the negative effects of&nbsp;human activity. The Company&rsquo;s Environmental Strategy is&nbsp;updated annually. For more details, visit Nornickel&rsquo;s website.
                    </p>
                </BaseSidebar>

                <BaseTextBlock class="text mb-100 max-width" title="Soil cover">
                    <p class="mb-10">
                        In&nbsp;the areas affected by&nbsp;industry in&nbsp;the Polar Division of&nbsp;the Norilsk Industrial District, scientists have discovered a&nbsp;natural anomaly: soils contain increased concentrations of&nbsp;heavy metals, while showing a&nbsp;deficiency of&nbsp;potassium and phosphorus. Such soils are averagely resistant to&nbsp;heavy metal pollution.
                    </p>
                    <p class="mb-10">
                        During the course of&nbsp;the study, scientists identified soils corresponding to&nbsp;land showing accumulated man-made impact. They are characterised by&nbsp;increased acidity due to&nbsp;sulphur dioxide, and by&nbsp;the presence of&nbsp;construction waste.
                    </p>
                    <p>
                        The soils of&nbsp;the Polar Division of&nbsp;the Norilsk Industrial District need to&nbsp;be&nbsp;improved and restored to&nbsp;their original state, in&nbsp;order to&nbsp;reduce their negative impact on&nbsp;other components of&nbsp;the ecosystems. In&nbsp;particular, scientists recommend mixing a&nbsp;small amount of&nbsp;lime into areas that have been exposed to&nbsp;sulphur dioxide. Norilsk Nickel&rsquo;s Clean Norilsk program is&nbsp;an&nbsp;initiative for collecting rubbish.
                    </p>
                </BaseTextBlock>

                <BaseTextBlock class="text mb-60 max-width" title="Increasing efforts with the help of&nbsp;IEI">
                    <p class="mb-10">
                        In&nbsp;order to&nbsp;effectively track changes in&nbsp;the area of&nbsp;operation of&nbsp;its facilities, Norilsk Nickel commissioned scientists to&nbsp;develop an&nbsp;<a href="/indicator">Integral Ecosystem Indicator</a>
                        (IEI).
                    </p>
                    <p class="mb-10">
                        Comparison of&nbsp;data on&nbsp;the state of&nbsp;various components of&nbsp;both terrestrial and aquatic ecosystems in&nbsp;impact zones and in&nbsp;background areas allows&nbsp;us to&nbsp;find out whether there are any deviations from the norm.
                    </p>
                    <p>
                        According to&nbsp;the results of&nbsp;the study conducted in&nbsp;2022&ndash;2023, the IEI value in&nbsp;the area of&nbsp;the Polar Division of&nbsp;the Norilsk Industrial District&rsquo;s industrial facilities is&nbsp;0.87 for terrestrial ecosystems and 0.84 for aquatic ecosystems. The closer this indicator is&nbsp;to&nbsp;1, the less the conditional loss of&nbsp;biodiversity in&nbsp;the affected area.
                    </p>
                </BaseTextBlock>

                <div class="division__factors-img" v-if="!isMobile">
                    <picture>
                        <img loading="lazy" src="img/division-factors.webp" alt="">
                    </picture>
                </div>

                <div v-else>
                    <picture style="overflow: hidden; width: 100%; display: block;">
                        <img loading="lazy" src="img/terrestrial-ecosystem-3.webp" alt="">
                    </picture>

                    <picture>
                        <img loading="lazy" src="img/aquatic-ecosystems-3.webp" alt="">
                    </picture>
                </div>

            </SectionWhite>
            <SectionOrange>
                <BaseTextBlock class="text mb-60" :tooltip="true">
                    <div class="section-orange__title">
                        What lives in&nbsp;the south of&nbsp;Taimyr
                    </div>
                    <div class="section-orange__descr">
                        <p class="mb-10">
                            In&nbsp;the zone adjacent to&nbsp;the Industrial District, in&nbsp;the conditional zone of&nbsp;industrial impact, the participants of&nbsp;the Great Scientific Expedition found 
                            <BaseTooltip :word="'protected species'">
                                Rare and endangered species, whose numbers are declining or&nbsp;have already declined to&nbsp;dangerously low levels. They are assigned protected status and added to&nbsp;the endangered list, known as&nbsp;the Red Book. This can either be&nbsp;regional, or&nbsp;for the whole of&nbsp;Russia. Protected status prohibits the hunting of&nbsp;these animals. In&nbsp;the case of&nbsp;endangered plants and mushrooms, it&nbsp;also limits construction and other activities in&nbsp;the areas where they are found. 
                            </BaseTooltip> of&nbsp;animals and plants, as&nbsp;well as&nbsp;several areas with unique ecosystems.
                        </p>
                        <p>
                            From typical examples of&nbsp;Arctic flora and fauna, biologists selected
                            <BaseTooltip :word="'indicator species'">
                                Researchers select indicator species by&nbsp;looking at&nbsp;the plants and animals typical of&nbsp;the region and then choosing the ones that are most sensitive to&nbsp;deviations in&nbsp;the state of&nbsp;the environment. Changes in&nbsp;the composition of&nbsp;the indicator species can be&nbsp;a&nbsp;sign that something has gone wrong in&nbsp;the ecosystem.
                            </BaseTooltip>. Observing their behaviour can tell&nbsp;us a&nbsp;lot about the state of&nbsp;the division&rsquo;s natural environment.
                        </p>
                    </div>
                </BaseTextBlock>
                <div class="flora-and-fauna-nor">
                    <div class="flora-and-fauna-nor__wrap">
                        <BaseTextBlock title="Flora">
                            <p class="mb-10">
                                The vegetation cover of&nbsp;the region consists of&nbsp;mosses and lichens, low-growing grasses, and shrubs.
                            </p>
                            <p>
                                Areas of&nbsp;larch forest and alder thickets have become home to&nbsp;herbaceous perennial plants, which are rarely found or&nbsp;even completely in&nbsp;other types of&nbsp;areas. The abundance of&nbsp;woody plants here helps to&nbsp;maintain a&nbsp;variety of&nbsp;fungi.
                            </p>
                        </BaseTextBlock>
                        <BaseTextBlock title="Fauna">
                            <p class="mb-10">
                                The territory of&nbsp;the division does not contain a&nbsp;wide variety of&nbsp;mammals, which is&nbsp;typical for the southern tundra. There&rsquo;s a&nbsp;much greater variety of&nbsp;birds, many of&nbsp;which live here seasonally. Species that winter in&nbsp;the area include white and tundra partridges, and crows.
                            </p>
                            <p>
                                Local lakes contain stocks of&nbsp;commercially valuable fish, including Siberian whitefish, and various types of&nbsp;char.
                            </p>
                        </BaseTextBlock>
                    </div>
                    <div class="wrap-numbers">
                        <BaseNumberBlock class="" :textTop="'75 species'" textBottom="of&nbsp;birds " />
                        <BaseNumberBlock class="" :textTop="'17 species'" textBottom="of&nbsp;mammals " />
                        <BaseNumberBlock class="" :textTop="'13 species'" textBottom="of&nbsp;fish " />
                    </div>
                </div>

                <BaseTextBlock class="division__spider" title="Bioindicators of&nbsp;the state of&nbsp;ecosystems">
                    <span>
                        <p class="mb-10">
                            The key bioindicator for an&nbsp;effective assessment of&nbsp;the state of&nbsp;disturbed ecosystems is&nbsp;soil microarthropods, such as&nbsp;moss mites. Scientists note that their density increases as&nbsp;we&nbsp;move from the belt of&nbsp;significant impact to&nbsp;the background zones.
                        </p>
                        <p class="mb-10">
                            Among invertebrates, another indicator species is&nbsp;the wandering wolf spider. A&nbsp;negative relationship was established between the total number of&nbsp;spiders and the degree of&nbsp;impact. This indicator can be&nbsp;used to&nbsp;monitor the state of&nbsp;the environment in&nbsp;the Polar Division of&nbsp;the Norilsk Industrial District.
                        </p>
                        <p>
                            For potential bioindicators among aquatic organisms, further research is&nbsp;required to&nbsp;confirm which species may be&nbsp;used.
                        </p>
                    </span>
                    <div class="position-info">
                        <picture>
                            <source type="image/webp" srcset="img/spider.webp">
                            <img loading="lazy" src="img/spider.png" alt="">
                        </picture>
                    </div>
                </BaseTextBlock>

                <div class="collage">
                    <div class="legend">
                        <BaseCollageInfo text="Protected species" :color="'#71464E'" />
                        <BaseCollageInfo text="Indicator species" />
                    </div>

                    <div class="collage__eagle">
                        <video @loadeddata="videoLoadedOne" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/eagle.mov" type="video/mp4">
                            <source src="video/eagle.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingOne">
                            <source type="image/webp" srcset="img/collage-eagle.webp">
                            <img loading="lazy" src="img/collage-eagle.png" alt="">
                        </picture>
                    </div>
                    <div class="collage__merlin">
                        <video v-if="!isMobile" @loadeddata="videoLoadedTwo" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/merlin.mov" type="video/mp4">
                            <source src="video/merlin.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingTwo || isMobile">
                            
                            <source type="image/webp" srcset="img/merlin.webp">
                            <img loading="lazy" src="img/merlin.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__sapsan">
                        <video v-if="!isMobile" @loadeddata="videoLoadedThree" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/sapsan.mov" type="video/mp4">
                            <source src="video/sapsan.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingThree || isMobile">
                            <source type="image/webp" srcset="img/sapsan.webp">
                            <img loading="lazy" src="img/sapsan.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__deep">
                        <video v-if="!isMobile" @loadeddata="videoLoadedFour" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/deep-big.mov" type="video/mp4">
                            <source src="video/deep-big.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingFour || isMobile">
                            <source type="image/webp" srcset="img/deep-big.webp">
                            <img loading="lazy" src="img/deep-big.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__shrew">
                        <video v-if="!isMobile" @loadeddata="videoLoadedFive" autoplay loop muted playsinline>
                            <source src="video/shrew.mov" type="video/mp4">
                            <source src="video/shrew.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingFive || isMobile">
                            <img loading="lazy" srcset="img/shrew.webp">
                        </picture>
                    </div>

                    <div class="collage__warbler">
                        <video v-if="!isMobile" @loadeddata="videoLoadedSix" autoplay loop muted playsinline>
                            <source src="video/warbler.mov" type="video/mp4">
                            <source src="video/warbler.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingSix || isMobile">
                            <img loading="lazy" srcset="img/warbler.webp">
                        </picture>
                    </div>

                    <div class="collage__warbler-2">
                        <video v-if="!isMobile" @loadeddata="videoLoadedSeven" autoplay loop muted playsinline>
                            <source src="video/warbler-2.mov" type="video/mp4">
                            <source src="video/warbler-2.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingSeven || isMobile">
                            <img loading="lazy" srcset="img/warbler-2.webp">
                        </picture>
                    </div>

                    <div class="collage__lentils">
                        <video v-if="!isMobile" @loadeddata="videoLoadedEight" autoplay loop muted playsinline>
                            <source src="video/lentils.mov" type="video/mp4">
                            <source src="video/lentils.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingEight || isMobile">
                            <img loading="lazy" srcset="img/lentils.webp">
                        </picture>
                    </div>

                    <div class="collage__oatmeal">
                        <video v-if="!isMobile" @loadeddata="videoLoadedNine" autoplay loop muted playsinline>
                            <source src="video/oatmeal.mov" type="video/mp4">
                            <source src="video/oatmeal.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingNine || isMobile">
                            <img loading="lazy" srcset="img/oatmeal.webp">
                        </picture>
                    </div>

                    <div class="collage__white-partridge">
                        <video v-if="!isMobile" @loadeddata="videoLoadedTen" autoplay loop muted playsinline>
                            <source src="video/white-partridge.mov" type="video/mp4">
                            <source src="video/white-partridge.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingTen || isMobile">
                            <img loading="lazy" srcset="img/white-partridge.webp">
                        </picture>
                    </div>

                    <div class="collage__bug">
                        <video v-if="!isMobile" @loadeddata="videoLoadedEleven" autoplay loop muted playsinline>
                            <source src="video/bug.mov" type="video/mp4">
                            <source src="video/bug.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingEleven || isMobile">
                            <img loading="lazy" srcset="img/bug-nd.webp">
                        </picture>
                    </div>

                    <div class="collage__plover">
                        <video v-if="!isMobile" @loadeddata="videoLoadedTwelve" autoplay loop muted playsinline>
                            <source src="video/plover.mov" type="video/mp4">
                            <source src="video/plover.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingTwelve || isMobile">
                            <img loading="lazy" srcset="img/plover.webp">
                        </picture>
                    </div>

                    <div class="collage__char">
                        <video v-if="!isMobile" @loadeddata="videoLoadedThirteen" autoplay loop muted playsinline>
                            <source src="video/char.mov" type="video/mp4">
                            <source src="video/char.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingThirteen || isMobile">
                            <img loading="lazy" src="img/char.webp" alt="">
                        </picture>
                    </div>

                    <div class="collage__fish">
                        <video v-if="!isMobile" @loadeddata="videoLoadedFourteen" autoplay loop muted playsinline>
                            <source src="video/collage-fish.mov" type="video/mp4">
                            <source src="video/collage-fish.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingFourteen || isMobile">
                            <img loading="lazy" srcset="img/collage-fish.webp">
                        </picture>
                    </div>

                    <div class="collage__wolverine">
                        <video v-if="!isMobile" @loadeddata="videoLoadedFifteen" autoplay loop muted playsinline>
                            <source src="video/wolverine.mov" type="video/mp4">
                            <source src="video/wolverine.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingFifteen || isMobile">
                            <source type="image/webp" srcset="img/wolverine.webp">
                            <img loading="lazy" src="img/wolverine.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__vole">
                        <video v-if="!isMobile" @loadeddata="videoLoadedSixteen" autoplay loop muted playsinline>
                            <source src="video/vole.mov" type="video/mp4">
                            <source src="video/vole.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingSixteen || isMobile">
                            <img loading="lazy" src="img/vole.webp" alt="">
                        </picture>
                    </div>

                    <div class="collage__goose">
                        <video v-if="!isMobile" @loadeddata="videoLoadedSeventeen" autoplay loop muted playsinline>
                            <source src="video/goose.mov" type="video/mp4">
                            <source src="video/goose.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingSeventeen || isMobile">
                            <source type="image/webp" srcset="img/goose.webp">
                            <img loading="lazy" src="img/goose.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__watercolor">
                        <picture>
                            <source type="image/webp" srcset="img/watercolor.webp">
                            <img loading="lazy" src="img/watercolor.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__watercolor-2">
                        <picture>
                            <source type="image/webp" srcset="img/watercolor.webp">
                            <img loading="lazy" src="img/watercolor.png" alt="">
                        </picture>
                    </div>

                    <!-- <div class="collage__watercolor-3">
                        <picture>
                            <source type="image/webp" srcset="img/watercolor-4.webp">
                            <img loading="lazy" src="img/watercolor-4.png" alt="">
                        </picture>
                    </div> -->

                    <div class="collage__watercolor-4">
                        <picture>
                            <source type="image/webp" srcset="img/watercolor-5.webp">
                            <img loading="lazy" src="img/watercolor-5.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__swan">
                        <video v-if="!isMobile" @loadeddata="videoLoadedEighteen" autoplay loop muted playsinline>
                            <source src="video/swan.mov" type="video/mp4">
                            <source src="video/swan.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingEighteen || isMobile">
                            <source type="image/webp" srcset="img/collage-kola-swan.webp">
                            <img loading="lazy" src="img/collage-kola-swan.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__loon">
                        <video v-if="!isMobile" @loadeddata="videoLoadedNineteen" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/loon.mov" type="video/mp4">
                            <source src="video/loon.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingNineteen || isMobile">
                            <source type="image/webp" srcset="img/loon.webp">
                            <img loading="lazy" src="img/loon.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__hare">
                        <video v-if="!isMobile" @loadeddata="videoLoadedTwenty" autoplay loop muted playsinline>
                            <source src="video/hare.mov" type="video/mp4">
                            <source src="video/hare.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingTwenty || isMobile">
                            <source type="image/webp" srcset="img/hare.webp">
                            <img loading="lazy" src="img/hare.png" alt="">
                        </picture>
                    </div>


                    <BaseCollageInfo class="eagle" title="White-tailed eagle" :color="'#71464E'"
                        :text="'The bird&rsquo;s wingspan reaches 2.4&nbsp;m. It&nbsp;often feeds on&nbsp;carrion and is&nbsp;considered a&nbsp;natural pest manager'" />

                    <BaseCollageInfo class="falcon" title="Peregrine falcon" :color="'#71464E'"
                        :text="'The fastest bird on Earth'" />

                    <BaseCollageInfo class="warbler" title="Arctic warbler"
                        :text="'It also inhabits the Trans-Baikal Territory'" />
                    <BaseCollageInfo class="lentils" title="Common rosefinch"
                        :text="'Makes a&nbsp;melodic short whistle often rendered as&nbsp;&laquo;pleased to&nbsp;meet you&raquo;.'" />

                    <BaseCollageInfo class="willow-warbler" title="Willow warbler"
                        :text="'Apart from the Norilsk Division, it is also found in the Polar Division: Kola MMC location area'" />

                    <BaseDropdownInfo class="merlin" title="Gyrfalcon" :color="'#71464E'" :is-modal="true"
                        :pulsation-color="'finn'"
                        :text="'he largest bird in&nbsp;the Falconidae family. Over the past 20&nbsp;years, its population in&nbsp;Russia has plummeted threefold. In&nbsp;2022&ndash;2023, Nornickel invested RUB 50&nbsp;mln to&nbsp;support and preserve the species'" />

                    <BaseCollageInfo class="oatmeal" title="Pallas&rsquo;s bunting" />
                    <BaseCollageInfo class="larch" title="Siberian larch" :color="'#71464E'" />

                    <BaseCollageInfo class="ptarmigan" title="Willow ptarmigan"
                        :text="'The bird&rsquo;s body maintains a&nbsp;constant temperature of&nbsp;45 &ordm;C'" />

                    <BaseCollageInfo class="shrew" title="Tundra shrew"
                        :text="'It&nbsp;can eat four times its body weight in&nbsp;a&nbsp;day'" />

                    <BaseCollageInfo class="wolverine" title="Wolverine" :color="'#71464E'" />

                    <BaseCollageInfo class="olkhovnik" title="Alnus alnobetula"
                        :text="'The wood of a felled alder tree turns red'" />

                    <BaseCollageInfo class="willow" title="Woolly willow" />
                    <BaseCollageInfo class="vole" title="Tundra vole" />

                    <BaseCollageInfo class="hare" title="Mountain hare"
                        :text="'It reaches speeds of up to 60 km/h whilst escaping predators. It also inhabits Trans-Baikal Division: Gazimursky district'" />

                    <BaseDropdownInfo class="plover" title="Golden plover" :color="'#71464E'" :is-modal="true"
                        :pulsation-color="'finn'"
                        :text="'In ancient times, it was believed that one look at this colourful bird cures jaundice'" />

                    <BaseCollageInfo class="gooseberry" title="Bean goose" :color="'#71464E'"
                        :text="'Flies up to 10 km high'" />

                    <BaseCollageInfo class="loon" title="Black-throated loon" :color="'#71464E'"
                        :text="'It can dive as deep as 20 m'" />

                    <BaseCollageInfo class="swan" title="Whooper swan" :color="'#71464E'"
                        :text="'It can fend off attacks from small predators with its powerful wings. The bird is also found in the Polar Division: Kola MMC location area'" />

                    <BaseCollageInfo class="palia" title="Salvelinus boganidae" />
                    <BaseDropdownInfo class="char" title="Arctic char" :is-modal="true"
                        :pulsation-color="'Green-Mist'"
                        :text="'It is considered the most nutritious fish in the world: a 30 g piece covers daily requirement of healthy fatty acids. It also inhabits Kola Division’s water bodies'" />

                    <BaseDropdownInfo class="ground-beetles" title="Ground beetles" :is-modal="true"
                        :pulsation-color="'Green-Mist'"
                        :text="'Species such as&nbsp;Amara brunnea, Pterostichus fulvescens, Notiophilus fasciatus and Nebria gyllenhali react negatively to&nbsp;industrial exposure. This helps researchers record changes in&nbsp;the environment. A&nbsp;potential indicator species of&nbsp;ground beetle is&nbsp;Curtonotus alpinus, whose density decreases the further away we&nbsp;move from the company&rsquo;s industrial facilities.'" />
                    <BaseDropdownInfo class="myxomycetes" title="Myxogastria" :is-modal="true"
                        :pulsation-color="'Green-Mist'"
                        :text="'Myxogastria is an intermediate position between plants and animals: similar to mushrooms in appearance, they are constantly on the move and eating. Researchers have found 14 species of these creatures in the exposed area'" />
                    <BaseDropdownInfo class="microalgae" title="Microalgae" :is-modal="true"
                        :pulsation-color="'Green-Mist'"
                        :text="'In the division’s rivers and lakes, biologists have found two species of microalgae that can tell us about the area’s ecology. The star-shaped Asterionella formosa inhabits only clean water bodies, whereas its antagonist, Dictyosphaerium pulchellum, develops in polluted water'" />

                    <BaseDropdownInfo class="zooplankton" title="Zooplankton" :is-modal="true"
                        :pulsation-color="'Green-Mist'"
                        :text="'To assess the condition of bodies of water, researchers selected zooplankton species that signal water quality. For example, Limnocalanus macrurus, a planktonic crustacean, likes pure water, and if Brachionus sericus, a planktonic rotifer, begins to dominate, this means the water is polluted'" />

                    <BaseDropdownInfo class="deer" title="Wild reindeer" :color="'#71464E'"
                        :pulsation-color="'finn'" :is-modal="true"
                        :text="'A distinctive feature of this species is that females have antlers. The least studied and smallest population of wild reindeer is on the Gyda Peninsula. It is included in the Red Data Book of the Yamalo-Nenets Autonomous Area. According to research data, reindeer hardly ever migrate long distances and remain in the Arctic tundra all year round'" />
                    <BaseDropdownInfo class="lycosus-moss" title="Lycopod" :is-modal="true"
                        :pulsation-color="'finn'" :color="'#71464E'"
                        :text="'This is&nbsp;a&nbsp;declining species, which is&nbsp;classed as&nbsp;vulnerable. Visually it&nbsp;resembles moss, but it&nbsp;is&nbsp;actually a&nbsp;vascular plant. It&nbsp;thrives in&nbsp;humid meadows, swamps, northern peat bogs, and mossy places'" />
                    <BaseDropdownInfo class="hare_mobile" title="Mountain hare" :is-modal="true"
                        :pulsation-color="'Green-Mist'"
                        :text="'It reaches speeds of up to 60 km/h whilst escaping predators. It also inhabits Trans-Baikal Division: Gazimursky district'" />
                </div>
                <BaseTextBlock class="mb-100" :tooltip="true">
                    <p class="mb-10">
                        In the Medvezhy Ruchey valley (Polar Division: Norilsk industrial District), scientists have discovered a unique
                            ecosystem – surviving steppe-tundra. A rare combination of soil and climatic conditions
                            helped these contemporaries of mammoths and woolly rhinoceroses survive.
                    </p>
                    <p>
                        <BaseTooltip :word="'Relict'">
                            Relict species are representatives of past evolutionary eras that have survived to our
                                time unchanged. Relicts can be organisms, species, or entire natural communities.
                                Typically, relicts were widespread in the past and played a key role in shaping the
                                living conditions in a particular area.
                        </BaseTooltip>
                        ecosystems that witnessed past geological eras can tell scientists a story about how the
                            planet evolved. They are also a valuable resource for species that may be looking for new
                            home in the face of global climate change.
                    </p>
                </BaseTextBlock>

                <div class="division__new-ward mb-60">
                    <div class="division__new-ward-title">
                        A&nbsp;new species of&nbsp;weevil has been discovered
                    </div>
                    <div class="division__new-ward-descr">
                        During the Great Scientific Expedition in&nbsp;the Polar Division of&nbsp;the Norilsk Industrial District, scientists discovered a&nbsp;new species of&nbsp;weevil. Its name was chosen in&nbsp;a&nbsp;vote among the residents of&nbsp;Norilsk and Norilsk Nickel employees. The winning name was &ldquo;Putoranchik&rdquo;, after one of&nbsp;the most amazing places in&nbsp;Russia, the Putorana Plateau. A&nbsp;UNESCO World Heritage Site, this area is&nbsp;abundant with animal and plant life, containing numerous lakes, waterfalls, and majestic canyons.
                    </div>
                    <video @loadeddata="videoLoadedLastlast" autoplay loop muted playsinline>
                        <source src="video/bug-1.mov" type="video/mp4">
                        <source src="video/bug-1.webm" type="video/webm">
                    </video>
                    <picture v-if="!isLoadingLastlast" class="division__new-ward-img">
                        <source type="image/webp" srcset="img/bug.webp">
                        <img loading="lazy" src="img/bug.png" alt="">
                    </picture>

                    <picture class="division__new-ward-bg">
                        <source type="image/webp" srcset="img/bg/bg.webp">
                        <img loading="lazy" src="img/bg/bg.png" alt="">
                    </picture>
                </div>

                <div class="habitat-map">
                    <div class="habitat-map__legend">
                        <div class="habitat-map__legend-top">
                            <div class="habitat-map__legend-title">
                                Protected species habitat map
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                    fill="none">
                                    <circle cx="7" cy="7" r="7" fill="#D38235" />
                                </svg>
                                <div>Severe exposure</div>
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                    fill="none">
                                    <circle cx="7" cy="7" r="7" fill="#FFEC42" />
                                </svg>
                                <div>Moderate exposure</div>
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                    fill="none">
                                    <circle cx="7" cy="7" r="7" fill="#009CA6" />
                                </svg>
                                <div>Insignificant exposure</div>
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                    fill="none">
                                    <circle cx="8" cy="8" r="7" fill="white" stroke="#333333" />
                                </svg>
                                <div>Background exposure</div>
                            </div>
                        </div>
                        <div class="habitat-map__legend-bottom">
                            <div class="habitat-map__legend-title">
                                Species
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                1 – Gyrfalcon
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                2 – Peregrine falcon
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                3 – White-tailed eagle
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                4 – Bean goose
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                5 – Golden plover
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                6 – Whooper swan
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                7 – Black-throated loon
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                8 – Northern spikemoss
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                9 – Relict steppe-tundra
                            </div>
                        </div>
                    </div>
                    <div class="habitat-map__wrap">
                        <div v-if="!isMobile" class="habitat-map__title">
                            Protected species habitat map
                        </div>
                        <div v-if="isMobile" class="habitat-map__title">
                            Protected species habitat map
                        </div>
                        <div class="habitat-map__list">
                            <div class="habitat-map__list-item-wrap">
                                <div class="habitat-map__list-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                        fill="none">
                                        <circle cx="7" cy="7" r="7" fill="#D38235" />
                                    </svg>
                                    <div>Severe exposure</div>
                                </div>
                                <ul class="habitat-map__list-list">
                                    <li>White-tailed eagle</li>
                                    <li>Golden plover</li>
                                    <li>Whooper swan</li>
                                    <li>Black-throated loon</li>
                                    <li>Peregrine falcon</li>
                                </ul>
                            </div>

                            <div class="habitat-map__list-item-wrap">
                                <div class="habitat-map__list-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                        fill="none">
                                        <circle cx="7" cy="7" r="7" fill="#FFEC42" />
                                    </svg>
                                    <div>Moderate exposure</div>
                                </div>
                                <ul class="habitat-map__list-list">
                                    <li>Bean goose</li>
                                    <li>Golden plover</li>
                                    <li>Whooper swan</li>
                                    <li>Black-throated loon</li>
                                    <li>Lycopod</li>
                                </ul>
                            </div>

                            <div class="habitat-map__list-item-wrap">
                                <div class="habitat-map__list-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                        fill="none">
                                        <circle cx="7" cy="7" r="7" fill="#009CA6" />
                                    </svg>
                                    <div>Insignificant exposure</div>
                                </div>
                                <ul class="habitat-map__list-list">
                                    <li>Golden plover</li>
                                    <li>Whooper swan</li>
                                    <li>Black-throated loon</li>
                                    <li>Relict steppe-tundra</li>
                                    <li>Gyrfalcon and Peregrine falcon</li>
                                </ul>
                            </div>

                            <div class="habitat-map__list-item-wrap">
                                <div class="habitat-map__list-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <circle cx="8" cy="8" r="7" fill="white" stroke="#333333" />
                                    </svg>
                                    <div>Background exposure</div>
                                </div>
                                <ul class="habitat-map__list-list">
                                    <li>Bean goose</li>
                                    <li>Golden plover</li>
                                </ul>
                            </div>

                        </div>
                        <div class="habitat-map__buttons">
                            <div class="habitat-map__button" :class="{ 'habitat-map__button_active': idMap === map1 }"
                                @click="idMap = 1">
                                Greater Norilsk / Dudinka
                            </div>
                            <div class="habitat-map__button" :class="{ 'habitat-map__button_active': idMap === map2 }"
                                @click="idMap = 2">
                                Pelyatkinskoe / Messoyakha
                            </div>
                        </div>
                    </div>

                    <picture v-show="idMap === 1">
                        <source media="(max-width: 768px)" srcset="img/habitat-map-1-mobile.png">
                        <source type="image/webp" srcset="img/habitat-map-1.webp">
                        <img loading="lazy" src="img/habitat-map-1.png" alt="">
                    </picture>

                    <picture v-show="idMap === 2">
                        <source media="(max-width: 768px)" srcset="img/habitat-map-2-mobile.png">
                        <source type="image/webp" srcset="img/habitat-map-2.webp">
                        <img loading="lazy" src="img/habitat-map-2.png" alt="">
                    </picture>

                    <div v-show="idMap === 1">
                        <div class="habitat-map__name-lake">
                            Lake Pyasino

                        </div>
                        <div class="habitat-map__city habitat-map__city-1">
                            <div class="habitat-map__city-circale"></div>
                            Talnakh
                        </div>
                        <div class="habitat-map__city habitat-map__city-2">
                            <div class="habitat-map__city-circale"></div>
                            Norilsk
                        </div>
                        <div class="habitat-map__city habitat-map__city-3">
                            <div class="habitat-map__city-circale"></div>
                            Dudinka
                        </div>
                    </div>
                    <div v-show="idMap === 2">
                        <div class="habitat-map__river">
                            Yenisei River
                        </div>
                        <div class="habitat-map__city habitat-map__city-4">
                            <div class="habitat-map__city-circale"></div>
                            <span>
                                Pelyatkinskoye gas condensate field
                            </span>
                        </div>
                        <div class="habitat-map__city habitat-map__city-5">
                            <div class="habitat-map__city-circale"></div>

                            <span>
                                Messoyakhskoye gas condensate field
                            </span>
                        </div>
                    </div>
                </div>
                <BaseTextBlock class="mb-100">
                    <p>
                        Some rare species can live in the areas affected by facilities despite noise or other impacts.
                        After all, their survival depends on the availability of food and an absence of human
                        interference.
                    </p>
                </BaseTextBlock>
                <BaseSidebar>
                    <p>
                        Unique ecosystems, rare and indicator species and their habitats are all elements of the system
                        that helps the company monitor its industrial impact and preserve the abundance and diversity of
                        nature in the division’s area.
                    </p>
                </BaseSidebar>
            </SectionOrange>
        </MainSection>
    </div>
</template>

<script>
import BaseHero from '@/layout/BaseHero.vue'
import SectionWhite from '@/layout/SectionWhite.vue'
import SectionOrange from '@/layout/SectionOrange.vue'
import BigMap from '@/components/BigMap.vue'
import BaseTextBlock from '@/components/BaseTextBlock.vue'
import MainSection from '@/layout/MainSection'
import BaseSidebar from '@/components/BaseSidebar'
import BaseTooltip from '@/components/BaseTooltip.vue'
import BaseNumberBlock from "@/components/BaseNumberBlock.vue";
import BaseCollageInfo from '@/components/BaseCollageInfo.vue'
import BaseDropdownInfo from '@/components/BaseDropdownInfo.vue'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
    components: {
        BaseHero,
        SectionOrange,
        SectionWhite,
        BigMap,
        BaseTextBlock,
        MainSection,
        BaseSidebar,
        BaseTooltip,
        BaseNumberBlock,
        BaseCollageInfo,
        BaseDropdownInfo
    },
    data() {
        return {
            isLoadingOne: false,
            isLoadingTwo: false,
            isLoadingThree: false,
            isLoadingFour: false,
            isLoadingFive: false,
            isLoadingSix: false,
            isLoadingSeven: false,
            isLoadingEight: false,
            isLoadingNine: false,
            isLoadingTen: false,
            isLoadingEleven: false,
            isLoadingTwelve: false,
            isLoadingThirteen: false,
            isLoadingFourteen: false,
            isLoadingFifteen: false,
            isLoadingSixteen: false,
            isLoadingSeventeen: false,
            isLoadingEighteen: false,
            isLoadingNineteen: false,
            isLoadingTwenty: false,
            isLoadingTwentyOne: false,
            isLoadingTwentyTwo: false,
            isLoadingLast: false,
            isLoadingLastlast: false,
            map1: 1,
            map2: 2,
            idMap: 1,
            circales: [{
                color: 'green',
                number: '1'
            },
            {
                color: 'blue',
                number: '2'
            },
            {
                color: 'blue',
                number: '3'
            },
            {
                color: 'blue',
                number: '4'
            },
            {
                color: 'purple',
                number: '5'
            },
            {
                color: 'purple',
                number: '6'
            },
            {
                color: 'purple',
                number: '7'
            }
            ],
            isMobile: (window.innerWidth <= 768),
            heroDescr: 'Animal and plant species living in&nbsp;the vicinity of&nbsp;the industrial giant of&nbsp;the Krasnoyarsk Territory&nbsp;&mdash; the Greater Norilsk'
        }
    },
    mounted() {
        this.$nextTick(function () {
            this.scrollAnimation()
            // this.parallax()
        })
    },
    methods: {
        videoLoadedOne() {
            this.isLoadingOne = true;
        },
        videoLoadedTwo() {
            this.isLoadingTwo = true;
        },
        videoLoadedThree() {
            this.isLoadingThree = true;
        },
        videoLoadedFour() {
            this.isLoadingFour = true;
        },
        videoLoadedFive() {
            this.isLoadingFive = true;
        },
        videoLoadedSix() {
            this.isLoadingSix = true;
        },
        videoLoadedSeven() {
            this.isLoadingSeven = true;
        },
        videoLoadedEight() {
            this.isLoadingEight = true;
        },
        videoLoadedNine() {
            this.isLoadingNine = true;
        },
        videoLoadedTen() {
            this.isLoadingTen = true;
        },
        videoLoadedEleven() {
            this.isLoadingEleven = true;
        },
        videoLoadedTwelve() {
            this.isLoadingTwelve = true;
        },
        videoLoadedThirteen() {
            this.isLoadingThirteen = true;
        },
        videoLoadedLast() {
            this.isLoadingLast = true;
        },
        videoLoadedLastlast() {
            this.isLoadingLastlast = true;
        },
        videoLoadedFourteen() {
            this.isLoadingFourteen = true;
        },
        videoLoadedFifteen() {
            this.isLoadingFifteen = true;
        },
        videoLoadedSixteen() {
            this.isLoadingSixteen = true;
        },
        videoLoadedSeventeen() {
            this.isLoadingSeventeen = true;
        },
        videoLoadedEighteen() {
            this.isLoadingEighteen = true;
        },
        videoLoadedNineteen() {
            this.isLoadingNineteen = true;
        },
        videoLoadedTwenty() {
            this.isLoadingTwenty = true;
        },
        videoLoadedTwentyOne() {
            this.isLoadingTwentyOne = true;
        },
        videoLoadedTwentyTwo() {
            this.isLoadingTwentyTwo = true;
        },
        onResize() {
            this.isMobile = (window.innerWidth <= 768);
        },
        scrollMobile() {
            const collageItems = Array.from(document.querySelectorAll(".scroll-bg.active"))

            collageItems.forEach((elem) => {
                elem.css('background-position', '0px ' + document.scrollTop() + 'px')
            })
        },
        scrollAnimation() {
            const collageItems = Array.from(document.querySelectorAll(".text-block__wrap"));
            collageItems.forEach((elem) => {

                this.oneScrollTrigger = gsap.fromTo(elem, {
                    y: 50,
                }, {
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        start: '0% 100%',
                        end: 'bottom 75%',
                        trigger: elem,
                        scrub: true,
                        // markers: true,
                    },
                });
            });

            const sidebarItems = Array.from(document.querySelectorAll(".sidebar"));
            sidebarItems.forEach((elem) => {
                this.twoScrollTrigger = gsap.fromTo(elem, {
                    y: 50,
                }, {
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        start: '0% 100%',
                        end: 'bottom 75%',
                        trigger: elem,
                        // markers: true,
                        scrub: true,
                    },
                });
            });

            this.ScrollTrigger = gsap.to(".scroll-bg", {
                scrollTrigger: {
                    trigger: ".scroll",
                    start: '0%',
                    scrub: true,
                    toggleClass: { className: "active", targets: ".scroll-bg" }
                },
            })
            if (window.innerWidth <= 768) {
                this.scrollMobile()
            }

            gsap.fromTo(".bg-1", { opacity: '1' }, {
                opacity: '0',
                scrollTrigger: {
                    trigger: ".card-1",
                    start: 'top 0%',
                    end: 'bottom 50%',
                    endTrigger: '.card-2',
                    // markers: true,
                    scrub: true
                },

            });

            gsap.fromTo(".bg-2", { opacity: '1' }, {
                opacity: '0',
                scrollTrigger: {
                    trigger: ".card-2",
                    start: 'top 0%',
                    end: 'bottom 50%',
                    endTrigger: '.card-3',
                    // markers: true,
                    scrub: true,
                },

            });

            gsap.to(".scroll-bg", {
                scrollTrigger: {
                    trigger: ".card-3",
                    start: () => "+=120%",
                    end: () => "+=" + (document.querySelector(".scroll-wrap").offsetHeight),
                    // markers: true,
                    scrub: true,
                    toggleClass: { className: "remove-active", targets: ".scroll-bg" }
                },
            });
        },
    }
}
</script>

<style lang="scss">
.division {
    overflow: hidden;

    @media (max-width: 768px) {
        .hero {
            min-height: 209rem;
            height: 100vh;
        }
    }

    .big-map {
        margin-bottom: 3.81rem;
        height: 40.19419rem;

        @media (max-width: 768px) {
            display: flex;
            margin-bottom: 21.3334rem;
            flex-direction: column-reverse;
            height: unset;
        }

        .map-circale {
            &__1 {
                top: 14.4rem;
                left: 57rem;
            }

            @media (max-width: 768px) {
                top: 56.4rem;
                left: 70rem;
            }

            &__2 {
                top: 10rem;
                left: 43.3rem;

                @media (max-width: 768px) {
                    top: 48.4rem;
                    left: 44.2rem;
                }
            }

            &__3 {
                top: 23.6rem;
                left: 39.7rem;

                @media (max-width: 768px) {
                    top: 73.4rem;
                    left: 38rem;
                }
            }

            &__4 {
                top: 32.8rem;
                left: 42rem;

                @media (max-width: 768px) {
                    top: 90.5rem;
                    left: 42rem;
                }
            }

            &__5 {
                top: 11.8rem;
                left: 41.6rem;

                @media (max-width: 768px) {
                    top: 51.6rem;
                    left: 40.9rem;
                }
            }

            &__6 {
                top: 11.8rem;
                left: 34.85rem;

                @media (max-width: 768px) {
                    top: 51.4rem;
                    left: 28rem;
                }
            }

            &__7 {
                top: 14.7rem;
                left: 23.55rem;

                @media (max-width: 768px) {
                    top: 57.4rem;
                    left: 7.7rem;
                }
            }
        }

        .circales {
            position: absolute;
            top: 8.31rem;
            left: 8.63rem;
            width: 11.94rem;
            display: flex;
            flex-direction: column;
            gap: 1.12rem;

            @media (max-width: 768px) {
                top: 0;
                flex-direction: row;
                flex-wrap: wrap;
                width: 81.4rem;
                gap: 3.3rem;
            }

            .circale-block {
                display: flex;
                align-items: flex-start;
                gap: 1.06rem;

                @media (max-width: 768px) {
                    width: 39rem;

                    &:last-child {
                        width: 51rem;
                    }
                }

                &__img {
                    width: 0.875rem;
                    height: 0.875rem;
                    flex-shrink: 0;

                    @media (max-width: 768px) {
                        width: 1.87rem;
                        height: 1.87rem;
                    }
                }

                &__text {
                    font-size: 0.9375rem;
                    font-weight: 300;
                    line-height: 130%;

                    @media (max-width: 768px) {
                        font-size: 3.46667rem;
                    }
                }
            }
        }

        .number-block {
            bottom: 5.63rem;
            left: unset;
            right: 7.81rem;

            @media (max-width: 768px) {
                margin-left: 9.5rem;
                width: unset;
            }
        }
    }

    .scroll {
        position: relative;
        width: 100%;

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;
            height: 100vh;
            background-position: bottom;
            background-size: calc(100% - 13.5rem) 49rem;
            background-repeat: no-repeat;

            @media (max-width: 768px) {
                background-position: center;
                background-size: 100%;
            }

            &.active {
                background-attachment: fixed;
                height: 100%;

                @media (max-width: 768px) {
                    height: 100%;
                    background-size: 100%;
                    position: fixed;
                    background-attachment: unset;
                }

                &.remove-active {
                    background-attachment: unset;
                    background-position: 6.75rem 352vh;
                    background-size: calc(100% - 13.5rem) 49rem;

                    @media (max-width: 768px) {
                        background-position: bottom;
                        position: absolute;
                        background-size: 100%;
                    }
                }
            }

            &.bg-1 {
                z-index: 3;
                background-image: url(../../public/img/map-1.jpg);
            }

            &.bg-2 {
                z-index: 2;
                background-image: url(../../public/img/map-2.jpg);
            }

            &.bg-3 {
                z-index: 1;
                background-image: url(../../public/img/map-3.jpg);
            }

            @media (max-width: 768px) {
                &.bg-1 {
                    background-image: url(../../public/img/map-1-mobile.jpg);
                }

                &.bg-2 {
                    background-image: url(../../public/img/map-2-mobile.jpg);
                }

                &.bg-3 {
                    background-image: url(../../public/img/map-3-mobile.jpg);
                }
            }
        }

        &-wrap {
            position: relative;
            z-index: 10;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 1.25rem;
        }

        &-card {
            padding: 1.5rem;
            margin-bottom: 100vh;
            width: 34.2rem;
            border-radius: 0.25rem;
            background: #FFF;

            &:first-child {
                margin-top: 50vh;
            }

            @media (max-width: 768px) {
                padding: 4.2666rem;
                margin: 0 auto 100vh auto;
                width: 93%;

                &:first-child {
                    margin-top: 100vh;
                }
            }

            p {
                font-size: 1.25rem;
                font-weight: 300;
                line-height: 130%;

                @media (max-width: 768px) {
                    font-size: 4.53334rem;
                }

                &:first-child {
                    margin-bottom: 1.56rem;
                    font-size: 1.25rem;
                    font-weight: 600;

                    @media (max-width: 768px) {
                        margin-bottom: 6.6667rem;
                        font-size: 4.8rem;
                    }
                }
            }

            .dropdown-info {
                margin-top: 1.88rem;

                @media (max-width: 768px) {
                    margin-top: 7.4666rem;
                }

                &__title {
                    font-size: 1.25rem;

                    @media (max-width: 768px) {
                        font-size: 4.8rem;
                    }
                }

                &__text {
                    background: #F7F6F2;
                    width: 27rem;
                    font-size: 1.04167rem;
                    padding: 3.5rem 1.4375rem 1.5625rem 3.375rem;

                    @media (max-width: 768px) {
                        left: -4.3rem;
                        top: -4.3rem;
                        width: 91.734rem;
                        font-size: 3.73334rem;
                        padding: 14.9334rem 6.1333rem 6.6666rem 13.8666rem;
                    }
                }

                ul {
                    list-style-type: disc;

                    li {
                        padding-left: 0.94rem;
                    }

                    li:not(:last-child) {
                        margin-bottom: 0.8rem;

                        @media (max-width: 768px) {
                            margin-bottom: 3.2rem;
                        }
                    }
                }
            }
        }
    }

    &-radius {
        &__descr {
            margin-top: 0.5rem;
            display: flex;
            justify-content: flex-end;
            gap: 0.31rem;
            padding: 0 1.44rem;
            font-size: 0.8125rem;
            font-weight: 300;
            line-height: 130%;
            color: var(--Gray-3, #828282);

            @media (max-width: 768px) {
                font-size: 3rem;
                padding: 0 4rem;
                margin-top: 2rem;
            }

            span:last-child {
                width: 26rem;

                @media (max-width: 768px) {
                    width: 100%;
                }
            }
        }
    }

    .sidebar {
        width: 51.125rem;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__factors-img {
        margin: 0 auto;
        width: 73.125rem;
        height: 26.9375rem;
    }

    &-factors {
        display: flex;
        gap: 4.75rem;
        margin-left: auto;
        margin-right: auto;
        width: 75.0625rem;
        padding: 3.13rem 3rem 3.88rem 3rem;
        border-radius: 19px;
        border: 1px solid var(--grey, #626261);

        @media (max-width: 768px) {
            width: unset;
            padding: 5rem;
            gap: 6.4rem;
            flex-direction: column;
            margin-left: 9.5rem;
            margin-right: 9.5rem;
        }

        &__left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (max-width: 768px) {
                gap: 6.4rem;
            }
        }

        &__img-grass {
            margin-bottom: 0.63rem;
            width: 17.5625rem;
            height: 9.6875rem;

            @media (max-width: 768px) {
                width: 50%;
                height: auto;
                margin-bottom: 0;
            }
        }

        &__descr {
            width: 17.0625rem;
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 130%;
            flex-shrink: 0;

            @media (max-width: 768px) {
                width: unset;
                font-size: 4.8rem;
            }
        }

        &__items {
            display: flex;
            gap: 5.94rem 2.87rem;
            flex-wrap: wrap;

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 6.4rem;
            }
        }

        &__item {
            width: 19.875rem;
            display: flex;
            flex-direction: column;
            gap: 1.19rem;
            font-size: 1.125rem;
            font-weight: 300;

            @media (max-width: 768px) {
                width: unset;
                font-size: 4.8rem;
                line-height: 130%;
                gap: 5rem;
            }

            &-title {
                padding: 0.4375rem 0.625rem;
                border-radius: 27px;
                background: var(--copper, #D38235);
                color: var(--white, #FFF);
                width: max-content;

                @media (max-width: 768px) {
                    padding: 0.5rem 2rem;
                }
            }

            &-text {
                line-height: 130%;
            }
        }
    }

    .flora-and-fauna-nor {
        margin: 0 auto;
        width: 68.5rem;
        margin-bottom: 8.69rem;

        @media (max-width: 768px) {
            margin-bottom: 16rem;
            width: unset;
        }

        &__wrap {
            display: flex;
            gap: 5.44rem;

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 10.6667rem;
            }
        }

        .wrap-numbers {
            display: flex;
            gap: 2.88rem;
            margin-top: 2.88rem;

            @media (max-width: 768px) {
                margin-top: 0;
                gap: 0;
                padding: 0 9.5rem;
                flex-direction: column;
            }
        }

        .text-block__wrap {
            margin: 0;

            &:first-child {
                width: 32.375rem;

                .number-block {
                    margin-top: 2.88rem;

                    @media (max-width: 768px) {
                        margin-top: 7.4rem;
                    }
                }
            }

            &:last-child {
                width: 29.5rem;

                .wrap-numbers {
                    display: flex;
                    gap: 2.88rem;
                    margin-top: 2.88rem;

                    @media (max-width: 768px) {
                        margin-top: 0;
                        gap: 0;
                        flex-direction: column;
                    }
                }
            }

            @media (max-width: 768px) {

                &:first-child,
                &:last-child {
                    width: 100%;
                }
            }
        }
    }

    &__spider {
        .position-info {
            position: absolute;
            bottom: 0;
            right: -17rem;

            @media (max-width: 768px) {
                position: relative;
                bottom: 0;
                right: 0;
                text-align: end;
                margin-bottom: 10rem;
            }

            img {
                width: 19.9375rem;

                @media (max-width: 768px) {
                    width: 50%;
                }
            }
        }
    }

    .number-blocks {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6.75rem;
        width: 60.5rem;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 7.467rem;
            width: 100%;
            align-items: flex-start;
        }

        .number-block {
            position: static;
            width: max-content;

            @media (max-width: 768px) {
                margin-top: 0;
            }
        }
    }

    .flora-and-fauna {
        display: flex;
        gap: 5.44rem;
        width: 63.5rem;
        margin: 0 auto;

        @media (max-width: 768px) {
            flex-direction: column;
            width: unset;
            gap: 12.27rem;
            margin-bottom: 16rem;
        }

        .text-block__wrap {
            margin: 0;

            &:first-child {
                width: 29.375rem;
            }

            &:last-child {
                width: 28.625rem;

                .number-block {
                    margin-top: 2.88rem;

                    @media (max-width: 768px) {
                        margin-top: 7.4rem;
                    }
                }
            }

            @media (max-width: 768px) {

                &:first-child,
                &:last-child {
                    width: 100%;
                }
            }
        }
    }

    .collage {
        position: relative;
        margin-bottom: 1.25rem;
        height: 347rem;
        width: 100%;
        background-image: url(../../public/img/collage.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom;
        z-index: 11;

        @supports (background-image: url(../../public/img/collage.webp)) {
            background-image: url(../../public/img/collage.webp);
        }

        @media (max-width: 768px) {
            height: 810rem;
            background-position: left 18rem;
            background-image: url(../../public/img/collage-mobile.png);

            @supports (background-image: url(../../public/img/collage-mobile.webp)) {
                background-image: url(../../public/img/collage-mobile.webp);
            }
        }

        .dropdown-info__text {
            @media (max-width: 768px) {
                width: 93.34rem;
                padding: 10.6667rem 5.3333rem 3.7333rem 12.2666rem;
            }
        }

        .legend {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2.5rem;
            top: 9.5rem;

            .block-info {
                position: static;
                font-size: 1.25rem;

                @media (max-width: 768px) {
                    font-size: 4.53334rem;
                }
            }

            @media (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 1rem;
                top: -1rem;
                padding-left: 10rem;

                .block-info__text {
                    font-size: 4.53334rem;
                    line-height: 130%;
                }
            }
        }

        video {
            position: absolute;
            width: 100%;
        }

        &__eagle {
            position: absolute;
            top: 0rem;
            left: 8rem;
            width: 52.2223rem;

            video {
                width: 113%;
                top: 3rem;
                left: -4rem;
                transform: rotate(12deg);
            }

            @media (max-width: 768px) {
                top: 22rem;
                left: 4rem;
                width: 65.2223rem;
            }
        }

        &__merlin {
            position: absolute;
            top: 87.7rem;
            left: 12.3rem;
            width: 38rem;

            video {
                width: 116%;
                transform: scaleX(-1);
                left: -2rem;
                top: -5rem;

                @media (max-width: 768px) {
                    width: 117%;
                    left: -3rem;
                    top: -7rem;
                }
            }

            @media (max-width: 768px) {
                top: 143rem;
                left: 0rem;
                width: 58rem;
            }
        }

        &__sapsan {
            position: absolute;
            top: 53.5rem;
            left: 25.3rem;
            width: 27rem;

            video {
                width: 135%;
                left: -8rem;
                top: 1rem;
                transform: rotate(-35deg);
            }

            @media (max-width: 768px) {
                top: 92.5rem;
                left: 46.3rem;
                width: 37rem;
            }
        }

        &__char {
            position: absolute;
            top: 311rem;
            left: 3.3rem;
            width: 34rem;
            transform: rotate(-10deg);

            video {
                width: 97%;
                left: 1.4rem;
                top: -2rem;
                transform: rotate(11deg);

                @media (max-width: 768px) {
                    left: -6rem;
                }
            }

            @media (max-width: 768px) {
                top: 772rem;
                left: 53.3rem;
                width: 44rem;
                transform: scaleX(-1) rotate(-17deg);
            }
        }

        &__warbler {
            position: absolute;
            top: 76rem;
            left: 54.3rem;
            width: 20rem;
            transform: rotate(-10deg);
            display: flex;
            justify-content: center;

            picture {
                width: 62.2%;
            }

            video {
                width: 113%;
                left: -1rem;
                top: -4rem;
                transform: rotate(11deg);
            }

            @media (max-width: 768px) {
                top: 208rem;
                left: -6rem;
                width: 30rem;
            }
        }

        &__warbler-2 {
            position: absolute;
            top: 55.5rem;
            left: 65rem;
            width: 17rem;
            display: flex;
            justify-content: center;

            picture {
                width: 62.2%;
            }

            video {
                width: 113%;
                left: -1.1rem;
                top: -4rem;
                transform: rotate(-4deg);
            }

            @media (max-width: 768px) {
                top: 193.5rem;
                left: 62rem;
                width: 29rem;
                transform: rotate(-23deg);
            }
        }

        &__lentils {
            position: absolute;
            top: 50.3rem;
            left: 0.8rem;
            width: 11.90rem;
            display: flex;
            align-items: center;
            justify-content: center;

            picture {
                width: 100%;
            }

            video {
                width: 213%;
                left: -6.7rem;
                top: -7rem;
                transform: scaleX(-1);
            }

            @media (max-width: 768px) {
                top: 238.3rem;
                left: -0.2rem;
                width: 29rem;
            }
        }

        &__oatmeal {
            position: absolute;
            top: 109.5rem;
            left: 46.5rem;
            width: 23rem;
            display: flex;
            justify-content: center;

            picture {
                width: 62.2%;
            }

            video {
                top: -5rem;
            }

            @media (max-width: 768px) {
                top: 219.5rem;
                left: 63.5rem;
                width: 37rem;
                transform: scaleX(-1);
            }
        }

        &__shrew {
            position: absolute;
            top: 180rem;
            left: 13rem;
            width: 24rem;
            display: flex;
            justify-content: center;
            transform: scaleX(-1);

            picture {
                width: 62.2%;
            }

            @media (max-width: 768px) {
                top: 582rem;
                left: 39rem;
                width: 37rem;
                transform: rotate(20deg);
            }
        }

        &__fish {
            position: absolute;
            top: 325.5rem;
            left: 40.6rem;
            width: 30rem;
            display: flex;

            picture {
                width: 69.5%;
            }

            video {
                top: -3rem;
                left: -2.1rem;
                width: 83%;
            }

            @media (max-width: 768px) {
                top: 766.2rem;
                width: 50rem;
                transform: scaleX(-1);
                left: -4rem;
            }
        }

        &__deep {
            position: absolute;
            top: 127rem;
            left: 12rem;
            width: 75rem;

            video {
                transform: scaleX(-1);
                width: 87%;
                left: 6rem;
                top: 3rem;

                @media (max-width: 768px) {
                    left: -22rem;
                }
            }

            @media (max-width: 768px) {
                top: 286rem;
                left: 0rem;
                width: 93rem;
                transform: scalex(-1);
            }
        }

        &__white-partridge {
            position: absolute;
            top: 161.5rem;
            top: 165.5rem;
            left: 0rem;
            display: flex;
            width: 27rem;

            picture {
                width: 62.2%;
            }

            video {
                left: -5rem;
                top: -3.8rem;
                transform: scaleX(-1);
            }

            @media (max-width: 768px) {
                top: 358.5rem;
                width: 36rem;
            }
        }

        &__swan {
            position: absolute;
            width: 46.3rem;
            top: 261.5rem;
            left: -2.4rem;

            @media (max-width: 768px) {
                width: 64.3rem;
                top: 635.5rem;
                left: -17.4rem;
            }
        }

        &__loon {
            position: absolute;
            top: 281.8rem;
            left: 46.3rem;
            width: 31.903rem;

            video {
                width: 118%;
                top: -4.8rem;
                left: -2rem;

                @media (max-width: 768px) {
                    top: -7.8rem;
                }
            }

            @media (max-width: 768px) {
                top: 692.1rem;
                left: 46.3rem;
                width: 47rem;
                transform: scaleX(-1);
            }
        }

        &__hare {
            position: absolute;
            top: 214.5rem;
            left: 5.5rem;
            width: 21rem;

            video {
                width: 175%;
                top: -6rem;
                left: -8rem;
            }

            @media (max-width: 768px) {
                top: 550.5rem;
                left: 2.5rem;
                width: 27rem;
            }
        }

        &__wolverine {
            position: absolute;
            top: 184rem;
            left: 54.5rem;
            width: 44rem;

            video {
                width: 95%;
    top: 2.8rem;
    left: 2rem;
            }

            @media (max-width: 768px) {
                top: 398rem;
                left: 46.5rem;
                width: 58rem;
            }
        }

        &__vole {
            position: absolute;
            top: 242.5rem;
            left: 81.2rem;
            width: 27rem;
            display: flex;

            picture {
                width: 50.2%;
            }

            video {
                left: -6.1rem;
                top: -5.8rem;
            }

            @media (max-width: 768px) {
                top: 353.5rem;
                left: 75.2rem;
                width: 38rem;
            }
        }

        &__goose {
            position: absolute;
            top: 247.5rem;
            left: 38rem;
            width: 26rem;

            video {
                width: 135%;
                top: -3rem;
                left: -4rem;
            }

            @media (max-width: 768px) {
                top: 622.5rem;
                left: 67rem;
                width: 31rem;
                transform: scaleX(-1);
            }
        }

        &__watercolor {
            position: absolute;
            top: 20rem;
            left: 5rem;
            width: 35rem;
            mix-blend-mode: multiply;
            transform: rotate(167deg);
            z-index: -1;

            @media (max-width: 768px) {
                top: 26rem;
                left: -14rem;
                width: 67rem;
            }
        }

        &__watercolor-2 {
            position: absolute;
            top: 83rem;
            left: 62rem;
            width: 34rem;
            mix-blend-mode: multiply;
            transform: rotate(167deg);

            @media (max-width: 768px) {
                display: none;
            }
        }

        &__watercolor-3 {
            position: absolute;
            top: 246rem;
            left: 32rem;
            width: 36rem;
            mix-blend-mode: multiply;

            @media (max-width: 768px) {
                top: 623rem;
                left: 65rem;
                width: 36rem;
                transform: rotate(6deg);
            }
        }

        &__watercolor-4 {
            position: absolute;
            top: 144rem;
            left: 29rem;
            width: 70rem;
            mix-blend-mode: multiply;

            @media (max-width: 768px) {
                top: 306rem;
                left: 15rem;
                width: 86rem;
            }
        }

        &__bug {
            position: absolute;
            top: 263.7rem;
            left: 83.1rem;
            width: 13rem;
            display: flex;

            picture {
                width: 62.5%;
            }

            video {
                top: -3.3rem;
                left: -2.6rem;
                transform: rotate(45deg);
            }

            @media (max-width: 768px) {
                top: 488rem;
                left: 19rem;
                width: 26rem;

                video {
                    transform: rotate(-48deg);
                }
            }
        }

        &__plover {
            position: absolute;
            top: 237rem;
            left: 36rem;
            width: 30rem;

            display: flex;

            picture {
                width: 43.5%;
            }

            video {
                top: -7.3rem;
                left: -8.6rem;
            }

            @media (max-width: 768px) {
                top: 507rem;
                left: 48rem;
                width: 50rem;
                transform: scaleX(-1);
            }
        }

        .eagle {
            width: 15rem;
            top: 25.7rem;
            right: 42.3rem;

            @media (max-width: 768px) {
                width: 42rem;
                top: 34rem;
                right: 8rem;
            }
        }

        .falcon {
            width: 12rem;
            top: 58.45rem;
            right: 46.2rem;

            @media (max-width: 768px) {
                width: 33rem;
                top: 94rem;
                right: 53rem;
            }
        }

        .warbler {
            width: 11rem;
            top: 48.4rem;
            right: 28.5rem;

            @media (max-width: 768px) {
                top: 193rem;
                right: 41rem;

                .block-info__text p {
                    display: none;
                }
            }
        }

        .lentils {
            width: 11rem;
            top: 50.4rem;
            left: 17.5rem;

            @media (max-width: 768px) {
                top: 256.4rem;
                left: 29.5rem;
                width: 41rem;
            }
        }

        .willow-warbler {
            width: 12rem;
            top: 76rem;
            right: 36rem;

            @media (max-width: 768px) {
                top: 205.5rem;
                right: 72.5rem;

                .block-info__text p {
                    display: none;
                }
            }
        }

        .merlin {
            position: absolute;
            top: 94rem;
            left: 44rem;

            @media (max-width: 768px) {
                top: 135rem;
                left: 12rem;
            }
        }

        .oatmeal {
            width: 7rem;
            top: 116.5rem;
            right: 30.5rem;

            @media (max-width: 768px) {
                top: 223rem;
                right: 42.5rem;
            }
        }

        .larch {
            width: 7rem;
            top: 137rem;
            right: 15.5rem;

            @media (max-width: 768px) {
                top: 154rem;
                right: 30rem;
            }
        }

        .deer {
            position: absolute;
            top: 143rem;
            left: 42rem;

            @media (max-width: 768px) {
                top: 301rem;
                left: 5rem;

                .dropdown-info__title {
                    max-width: unset;
                }
            }
        }

        .ptarmigan {
            width: 13rem;
            top: 172.2rem;
            left: 18.8rem;

            @media (max-width: 768px) {
                width: 44rem;
                top: 371.2rem;
                left: 25.8rem;
            }
        }

        .shrew {
            width: 15rem;
            top: 189.8rem;
            left: 30.2rem;

            @media (max-width: 768px) {
                width: 31rem;
                top: 553rem;
                left: 37.2rem;
            }
        }

        .wolverine {
            width: 7rem;
            top: 195.9rem;
            right: 40.7rem;

            @media (max-width: 768px) {
                top: 399rem;
                right: 20.7rem;
            }
        }

        .olkhovnik {
            width: 14rem;
            top: 205rem;
            left: 46.7rem;

            @media (max-width: 768px) {
                top: 412rem;
                width: 50rem;
                left: 4rem;
            }
        }

        .willow {
            width: 9rem;
            top: 234rem;
            right: 17rem;

            @media (max-width: 768px) {
                top: 561rem;
                right: 19rem;
            }
        }

        .vole {
            width: 7rem;
            top: 237.3rem;
            right: 8.7rem;

            @media (max-width: 768px) {
                top: 364rem;
                right: 18.7rem;
            }
        }

        .hare {
            width: 15rem;
            top: 233.5rem;
            left: 27rem;

            @media (max-width: 768px) {
                display: none;
            }

            &_mobile {
                display: none;
                position: absolute;
                bottom: 260rem;
                left: 7rem;

                @media (max-width: 768px) {
                    display: block;

                    .dropdown-info__title {
                        max-width: unset;
                    }
                }
            }
        }

        .plover {
            position: absolute;
            top: 241rem;
            left: 49rem;

            @media (max-width: 768px) {
                top: 513rem;
                left: 39rem;

                .dropdown-info__title {
                    max-width: unset;
                }
            }
        }

        .gooseberry {
            width: 14rem;
            top: 259.7rem;
            right: 23.9rem;

            @media (max-width: 768px) {
                width: 48rem;
                top: 627rem;
                right: 23.9rem;
            }
        }

        .loon {
            width: 14rem;
            top: 287.2rem;
            right: 12.9rem;

            @media (max-width: 768px) {
                width: 38rem;
                top: 681rem;
                right: 1.9rem;
            }
        }

        .swan {
            width: 18rem;
            top: 284.69rem;
            left: 38.9rem;

            @media (max-width: 768px) {
                width: 43rem;
                top: 677rem;
                left: 2.9rem;
            }
        }

        .lycosus-moss {
            position: absolute;
            top: 255rem;
            left: 18rem;

            .dropdown-info__title {
                max-width: 12rem;
            }

            .dropdown-info__text {
                padding-top: 3.56rem;
            }

            .dropdown-info__wrap {
                align-items: flex-start;
            }

            @media (max-width: 768px) {
                top: unset;
                bottom: 193rem;
                left: 4rem;

                .dropdown-info__wrap {
                    align-items: center;
                }

                .dropdown-info__title {
                    max-width: unset;
                }
            }
        }

        .char {
            position: absolute;
            top: 316.8rem;
            left: 24rem;

            @media (max-width: 768px) {
                top: 772rem;
                left: 52rem;

                .dropdown-info__title {
                    font-size: 3.2rem;
                    max-width: 33rem;
                }
            }
        }

        .palia {
            bottom: 21.4rem;
            left: 46rem;

            @media (max-width: 768px) {
                bottom: 44.4rem;
                left: 10rem;

                .block-info__title {
                    font-size: 3.2rem;
                }
            }
        }

        .ground-beetles {
            position: absolute;
            bottom: 80rem;
            right: 17rem;

            @media (max-width: 768px) {
                bottom: 311rem;
                right: 40rem;
            }
        }

        .myxomycetes {
            position: absolute;
            top: 225rem;
            right: 18rem;

            @media (max-width: 768px) {
                top: 453rem;
                right: 31rem;
            }
        }

        .microalgae {
            position: absolute;
            bottom: 41rem;
            left: 20rem;

            @media (max-width: 768px) {
                bottom: 86rem;
                left: 11rem;

                .dropdown-info__title {
                    font-size: 3.2rem;
                }
            }
        }

        .zooplankton {
            position: absolute;
            bottom: 37rem;
            right: 39rem;

            @media (max-width: 768px) {
                bottom: 74rem;
                right: 22rem;

                .dropdown-info__title {
                    font-size: 3.2rem;
                }
            }
        }
    }

    .section-orange {
        &__title {
            color: var(--copper, #D38235);
            margin-bottom: 2.19rem;
            font-size: 2.125rem;
            font-weight: 600;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 7.4667rem;
                margin-bottom: 6.4rem;
            }
        }

        .text {
            width: 38.25rem;
            text-align: center;

            @media (max-width: 768px) {
                width: unset;
                text-align: start;
            }
        }

        .number-block {
            position: static;
        }
    }

    .habitat-map {
        position: relative;
        margin-bottom: 4.5rem;
        height: 40rem;

        @media (max-width: 768px) {
            height: unset;
        }

        &__wrap {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            @media (max-width: 768px) {
                position: static;
                transform: translateX(0);
                margin-bottom: 5.867rem;
                padding: 0 5rem;
            }
        }

        &__title {
            margin-bottom: 1.63rem;
            font-size: 1.375rem;
            font-weight: 600;
            line-height: 130%;
            text-align: center;

            @media (max-width: 768px) {
                text-align: start;
                width: 52.5rem;
                margin-bottom: 7.75rem;
                font-size: 4.8rem
            }
        }

        &__buttons {
            display: flex;
            gap: 1rem;

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 2rem;
            }
        }

        &__button {
            cursor: pointer;
            padding: 0.8125rem 1.25rem;
            border-radius: 3.125rem;
            border: 1px solid var(--sea, #21285C);
            font-size: 1.25rem;
            color: #21285C;
            line-height: 130%;
            opacity: 0.4;
            transition: .2s;

            @media (max-width: 768px) {
                padding: 4rem 6rem;
                border-radius: 8.125rem;
                font-size: 4.53334rem;
                text-align: center;
            }

            &_active {
                opacity: 1;
            }

            &:hover {
                color: var(--copper, #D38235);
                border-color: #D38235;
                opacity: 1;
            }
        }

        &__legend {
            position: absolute;
            left: 7.37rem;
            top: 10.19rem;
            width: 12.4rem;

            @media (max-width: 768px) {
                display: none;
            }

            &-title {
                margin-bottom: 1rem;
                font-size: 0.9375rem;
                font-weight: 700;
                line-height: 130%;
            }

            &-text {
                font-size: 0.9375rem;
                font-weight: 300;
                line-height: 130%;
            }

            &-top {
                margin-bottom: 2.81rem;

                &-item {
                    display: flex;
                    gap: 0.56rem;
                    align-items: center;

                    &:not(:last-child) {
                        margin-bottom: 0.62rem;
                    }

                    svg {
                        width: 0.875rem;
                        height: 0.875rem;
                    }
                }
            }

            &-bottom {
                &-item {
                    &:not(:last-child) {
                        margin-bottom: 0.69rem;
                    }
                }
            }
        }

        &__list {
            display: none;
            flex-wrap: wrap;
            row-gap: 6rem;
            margin-bottom: 3rem;

            @media (max-width: 768px) {
                display: flex;
            }

            &-item {
                display: flex;
                margin-bottom: 2rem;
                align-items: center;
                gap: 1rem;
                font-size: 4.2042rem;
                line-height: 130%;

                &-wrap {
                    width: 50%;
                }

                svg {
                    width: 3.7333rem;
                    height: 3.7333rem;
                }
            }

            &-list {
                margin-left: 4rem;
                padding-left: 4rem;
                font-size: 3.6036rem;
                font-weight: 300;
                line-height: 130%;

                li {
                    list-style-type: disc;

                    &:not(:last-child) {
                        margin-bottom: 2.66rem;
                    }
                }
            }
        }

        &__river,
        &__name-lake {
            position: absolute;
            color: var(--blue-deep, #004C97);
            font-size: 0.5625rem;
            font-weight: 300;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 2.4rem;
            }
        }

        &__name-lake {
            top: 14rem;
            right: 28rem;

            @media (max-width: 768px) {
                top: unset;
                right: 25rem;
                bottom: 57rem;
            }
        }

        &__river {
            top: 16rem;
            transform: rotate(49deg);
            right: 24.7rem;

            @media (max-width: 768px) {
                display: none;
            }
        }

        &__city {
            position: absolute;
            width: max-content;
            display: flex;
            align-items: center;
            font-size: 0.9375rem;
            font-weight: 400;
            line-height: 130%;
            gap: 0.31rem;

            @media (max-width: 768px) {
                font-size: 3.2rem;
                gap: 1rem;
            }

            &-circale {
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 100%;
                transition-timing-function: ease-in;
                transition-duration: 0.7s;
                transition: all 2.3s;
                z-index: 2;
                background-color: rgba(#333, 1);
                flex-shrink: 0;

                @media (min-width: 769px) {
                    width: 0.375rem;
                    height: 0.375rem;
                    animation: ripple-black 0.9s infinite;
                }
            }

            &-1 {
                top: 16rem;
                right: 10.5rem;

                @media (max-width: 768px) {
                    top: unset;
                    bottom: 48rem;
                    right: 6.5rem;
                }
            }

            &-2 {
                top: 26.5rem;
                right: 15rem;

                @media (max-width: 768px) {
                    top: unset;
                    bottom: 36rem;
                    right: 9rem;
                    left: unset;
                }
            }

            &-3 {
                top: 22.5rem;
                left: 28.5rem;

                @media (max-width: 768px) {
                    top: unset;
                    bottom: 40rem;
                    left: 10.5rem;
                }
            }

            &-4 {
                width: 8rem;
                flex-direction: column;
                align-items: flex-start;
                gap: 3.34rem;
                top: 17.5rem;
                left: 34rem;

                &::after {
                    position: absolute;
                    content: '';
                    top: 0.1rem;
                    left: 0.15rem;
                    height: 3.34375rem;
                    width: 1px;
                    background-color: #000;
                }

                @media (max-width: 768px) {
                    flex-direction: row;
                    top: unset;
                    bottom: 36rem;
                    right: unset;
                    left: 11.5rem;
                    gap: 1.34rem;

                    &::after {
                        display: none;
                    }
                }
            }

            &-5 {
                width: 8rem;
                flex-direction: column-reverse;
                align-items: flex-start;
                gap: 3.34rem;
                top: 29.2rem;
                left: 57.2rem;

                &::after {
                    position: absolute;
                    bottom: 0.1rem;
                    left: 0.15rem;
                    content: '';
                    height: 3.34375rem;
                    width: 1px;
                    background-color: #000;
                }

                @media (max-width: 768px) {
                    flex-direction: row;
                    top: unset;
                    bottom: 8rem;
                    left: 39rem;
                    gap: 1.34rem;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    &__new-ward {
        position: relative;
        border-radius: 19px;
        border: 1px solid var(--grey, #626261);
        width: 67.09rem;
        padding: 4.378rem 3.34rem 2.777rem 16rem;
        margin: 0 auto;
        display: flex;
        gap: 2rem;

        @media (max-width: 768px) {
            width: unset;
            margin: 0 9.5rem;
            padding: 21rem 3.76rem 7.47rem 3.76rem;
            flex-direction: column;
            align-items: center;
            margin-bottom: 6rem !important;
        }

        &-title {
            font-size: 1.527778rem;
            font-weight: 600;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 4.8rem;
                text-align: center;
                width: 54rem;
            }
        }

        &-descr {
            width: 33.278rem;
            font-size: 1.25rem;
            font-weight: 300;
            line-height: 130%;
            flex-shrink: 0;

            @media (max-width: 768px) {
                width: unset;
                font-size: 4.53334rem;
                text-align: center;
            }
        }

        &-img {
            position: absolute;
            display: block;
            width: 13.5rem;
            height: auto;
            top: 2.3rem;
            left: 1rem;
            z-index: 1;

            @media (max-width: 768px) {
                width: 28rem;
                left: 26.5rem;
                top: -25.5rem;
            }
        }

        &-bg {
            position: absolute;
            width: 24rem;
            left: -5rem;
            top: 6rem;

            @media (max-width: 768px) {
                width: 57rem;
                left: 9rem;
                top: -27rem;
            }
        }

        video {
            position: absolute;
            width: 31.5rem;
            top: -12rem;
            left: -8rem;
            z-index: 1;

            @media (max-width: 768px) {
                width: 60rem;
                left: 10.5rem;
                top: -50.5rem;
            }
        }
    }

    .text-block__wrap a {
        text-decoration: 0.5px underline;
        text-decoration-skip-ink: none;
        text-underline-offset: 0.2rem;
    }
}
</style>