<template>
    <div class="treasures-2-container__wrap">
        <div class="treasures-2-margin">
			<div class="treasures-2-container">
				<div class="treasures-2-inner">

            <div class="treasures-2-item treasures-2-item_1">
                <div class="treasures-2-item__title">Sundews</div>
                <div class="treasures-2-item__descr">
                    <p>
                        The leaves of this plant are topped with sticky secretions, which attract insects and entrap them with the mucilage, preventing their escape. After that, the leaves roll in to envelop and clog the prey, with the digestion process taking several days.
                    </p>
                </div>
            </div>

            <div class="lizard">
                <video @loadeddata="videoLoaded" autoplay loop muted playsinline>
                    <source src="video/lizard.mov" type="video/mp4">
                    <source src="video/lizard.webm" type="video/webm">
                </video>
                <picture v-if="!isLoadingOne">
                    <source type="image/webp" srcset="img/lizard.webp">
                    <img loading="lazy" src="img/lizard.png" alt="">
                </picture>
            </div>

            <div class="swan">
                <video @loadeddata="videoLoadedTwo" autoplay loop muted playsinline>
                    <source src="video/swan.mov" type="video/mp4">
                    <source src="video/swan.webm" type="video/webm">
                </video>
                <picture v-if="!isLoadingTwo">
                    <source type="image/webp" srcset="img/collage-kola-swan.webp">
                    <img loading="lazy" src="img/collage-kola-swan.png" alt="">
                </picture>
            </div>

            <div class="shrike">
                <video autoplay loop muted playsinline>
                    <source src="video/shrike.mov" type="video/mp4">
                    <source src="video/shrike.webm" type="video/webm">
                </video>
            </div>

            <div class="deep">
                <video autoplay loop muted playsinline>
                    <source src="video/deep-big.mov" type="video/mp4">
                    <source src="video/deep-big.webm" type="video/webm">
                </video>
            </div>

            <div class="treasures-2-item treasures-2-item_2">
                <div class="treasures-2-item__title">Viviparous lizard</div>
                <div class="treasures-2-item__descr">
                    Thanks to its exceptional hardiness to the cold, this species of lizard can survive even near the Arctic Circle. They spend winters underground, hibernating in soil layers up to 40 cm deep.
                </div>
            </div>

            <div class="treasures-2-item treasures-2-item_3">
                <div class="treasures-2-item__title">Calypso orchid</div>
                <div class="treasures-2-item__descr">
                    It is one of the ten species of orchid growing in the reserve. The plant takes its name from the nymph Calypso in Greek mythology.
                </div>
            </div>

            <div class="treasures-2-item treasures-2-item_4">
                <div class="treasures-2-item__title">Whooper swan</div>
                <div class="treasures-2-item__descr">
                    These birds arrive in the Lapland Nature Reserve in March and early April from their wintering sites. Not afraid of the cold, they need open water to feed. Whooper swans form monogamous pairs and mate for life.
                </div>
            </div>

            <div class="treasures-2-item treasures-2-item_5">
                <div class="treasures-2-item__title">Wild reindeer</div>
                <div class="treasures-2-item__descr">
                    This large songbird species is a ruthless hunter. Small rodents, lizards, and large insects are the bulk of its prey. Sometimes great grey shrikes can store their prey by impaling it upon sharp branches and thorns.
                </div>
            </div>

            <div class="treasures-2-item treasures-2-item_6">
                <div class="treasures-2-item__title">Great grey shrike</div>
                <div class="treasures-2-item__descr">
                    This is the only species of reindeer in which both males and females grow antlers. The Lapland Nature Reserve is home to around 1,000 of these animals. To survive the harsh winters, reindeer form temporary herds of 100 or more animals.
                </div>
            </div>
        </div>
        </div>
        </div>
    </div>
</template>

<script>

import ScrollSwiper from './CustomScrollSwiper';


export default {
    data() {
        return {
            isLoadingOne: false,
            isLoadingTwo: false,
        }
    },
    mounted() {
        this.$nextTick(function () {
            const wrap = document.querySelector('.treasures-2-container__wrap');
			const container = document.querySelector('.treasures-2-container');
			const inner = document.querySelector('.treasures-2-inner');
			const marg = document.querySelector('.treasures-2-margin');
			new ScrollSwiper(wrap, container, inner, marg);
        })
    },
    methods: {
        videoLoaded() {
            this.isLoadingOne = true;
        },
        videoLoadedTwo() {
            this.isLoadingTwo = true;
        }
    }
}
</script>


<style lang="scss">
.treasures-2 {
    position: relative;

    &-margin {
        display: flex;
        width: 100%;
        height: 100%;
    }

    &-inner {
        background-color: #F7F6F2;
        position: relative;
        flex-shrink: 0;
        display: flex;
        width: 184rem;
        height: 38.12rem;
        background-image: url(../../public/img/treasures-scroll-2.jpg);
        background-repeat: no-repeat;
        background-size: 100% 100%;

        @media (max-width: 768px) {
            width: 652rem;
            height: 100%;
            background-size: 100%;
            background-image: url(../../public/img/treasures-scroll-2-mobile.png);
        }
    }

    &-container {
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        height: 100vh;
        width: 100%;
        overflow: hidden;

        @media (max-width: 768px) {

            width: unset;
            height: 139.467rem;
            overflow-x: scroll;
            overflow-y: hidden;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                width: 0;
            }
        }
    }

    video {
        position: absolute;
        width: 100%;
    }

    .lizard {
        position: absolute;
        top: 25rem;
        left: 20rem;
        width: 17rem;

        video {
            transform: scaleX(-1);
            width: 145%;
            top: -1rem;
            left: -4rem;
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    .swan {
        position: absolute;
        top: 12rem;
        left: 64rem;
        width: 34rem;

        video {
            width: 109%;
            top: -2rem;
            left: -2rem;
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    .deep {
        position: absolute;
        top: 7rem;
        left: 144rem;
        width: 35rem;
        transform: scaleX(-1);

        @media (max-width: 768px) {
            display: none;
        }
    }

    .shrike {
        position: absolute;
        top: 3rem;
        left: 100rem;
        width: 27rem;
        transform: rotate(-14deg);

        @media (max-width: 768px) {
            display: none;
        }
    }

    &-item {
        @media (max-width: 768px) {
            height: 139.467rem;
        }

        &__title {
            padding: 0.4375rem 0.625rem;
            margin-bottom: 1.06rem;
            width: max-content;
            font-size: 0.9375rem;
            font-weight: 300;
            line-height: 130%;
            color: #fff;
            border-radius: 1.6875rem;
            background: var(--grey, #626261);

            @media (max-width: 768px) {
                font-size: 4rem;
                padding: 1.8716rem 2.6737rem;
                border-radius: 7.22rem;
                margin-bottom: 4.5456rem;
            }
        }

        &__descr {
            font-size: 1.0417rem;
            font-weight: 300;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 4rem;
            }
        }

        &_1 {
            width: 27.889rem;
            padding: 4.1rem 3.795rem 0 3.5rem;

            @media (max-width: 768px) {
                width: 105rem;
                padding: 12rem 11.46rem 0 16rem;
            }
        }

        &_2 {
            width: 20.487rem;
            padding: 13.403rem 4.1rem 0 0;

            @media (max-width: 768px) {
                width: 67rem;
                padding: 34rem 11rem 0 0;
            }
        }

        &_3 {
            width: 23.4866rem;
            padding: 4rem 5.3472rem 0 0;

            @media (max-width: 768px) {
                width: 78rem;
                padding: 4rem 14rem 0 0;
            }
        }

        &_4 {
            width: 58.778rem;
            padding: 9.97rem 38rem 0 0;

            @media (max-width: 768px) {
                width: 199rem;
                padding: 20.97rem 131rem 0 0;
            }
        }

        &_5 {
            width: 38.23rem;
            padding: 10.458rem 19.4rem 0 0;

            @media (max-width: 768px) {
                width: 125rem;
                padding: 36rem 60rem 0 0;
            }
        }

        &_6 {
            width: 26.32rem;
            padding: 3.2rem 1rem 0 0;

            @media (max-width: 768px) {
                width: 98rem;
                padding: 13rem 15.4rem 0 0;
            }
        }
    }
}

.swiper {
    display: none;

    @media (max-width: 768px) {
        display: block;
    }
}
</style>