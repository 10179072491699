<template>
	<div class="treasures-5-container__wrap">
		<div class="treasures-5-margin">
			<div class="treasures-5-container">
				<div class="treasures-5-inner">

					<div class="treasures-5-img treasures-5-img_0">
                        <video  v-if="!isMobile" @loadeddata="videoLoadedZero" autoplay loop muted playsinline preload="none">
                            <source src="video/treasures-swiper-5-0.mov" type="video/mp4">
                            <source src="video/treasures-swiper-5-0.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingZero&&!isMobile">
                            <img loading="lazy" src="img/treasures-swiper-5-0.webp" alt="">
                        </picture>
                    </div>

					<div class="treasures-5-img treasures-5-img_1">
                        <video  v-if="!isMobile" @loadeddata="videoLoadedOne" autoplay loop muted playsinline preload="none">
                            <source src="video/treasures-swiper-5-1.mov" type="video/mp4">
                            <source src="video/treasures-swiper-5-1.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingOne&&!isMobile">
                            <img loading="lazy" src="img/treasures-swiper-5-1.webp" alt="">
                        </picture>
                    </div>

					<div class="treasures-5-img treasures-5-img_2">
                        <video  v-if="!isMobile" @loadeddata="videoLoadedTwo" autoplay loop muted playsinline preload="none">
                            <source src="video/treasures-swiper-5-2.mov" type="video/mp4">
                            <source src="video/treasures-swiper-5-2.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingTwo&&!isMobile">
                            <img loading="lazy" src="img/treasures-swiper-5-2.webp" alt="">
                        </picture>
                    </div>

					<div class="treasures-5-img treasures-5-img_3">
                        <video  v-if="!isMobile" @loadeddata="videoLoadedThree" autoplay loop muted playsinline preload="none">
                            <source src="video/treasures-swiper-5-3.mov" type="video/mp4">
                            <source src="video/treasures-swiper-5-3.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingThree&&!isMobile">
                            <img loading="lazy" src="img/treasures-swiper-5-3.webp" alt="">
                        </picture>
                    </div>

					<div class="treasures-5-item treasures-5-item_1">
						<div class="treasures-5-item__title">White-billed loon</div>
						<div class="treasures-5-item__descr">
							The largest of&nbsp;the loon family: the weight of&nbsp;an&nbsp;adult bird can reach 6&nbsp;kg, with a&nbsp;wingspan of&nbsp;up&nbsp;to&nbsp;one and a&nbsp;half metres. We&nbsp;have no&nbsp;accurate information about the numbers of&nbsp;this species, although it&nbsp;is&nbsp;only known that one pair of&nbsp;white-billed loons is&nbsp;found per 100 to&nbsp;200 km<sup>2</sup> of&nbsp;the territory. 
						</div>
					</div>

					<div class="treasures-5-item treasures-5-item_2">
						<div class="treasures-5-item__title">Grey seal</div>
						<div class="treasures-5-item__descr">
							This species of&nbsp;seal is&nbsp;notable for its pronounced sexual dimorphism&nbsp;&mdash; males are at&nbsp;least one and a&nbsp;half times bigger and heavier than females, sometimes up&nbsp;to&nbsp;double their size and weight. These animals live in&nbsp;groups, choosing places that are inaccessible to&nbsp;humans to&nbsp;give birth to&nbsp;their young.
						</div>
					</div>

					<div class="treasures-5-item treasures-5-item_3">
						<div class="treasures-5-item__title">Osprey</div>
						<div class="treasures-5-item__descr">
							The diet of&nbsp;this large bird of&nbsp;prey consists almost entirely of&nbsp;fish. The osprey tracks its prey by&nbsp;hovering at&nbsp;a&nbsp;low altitude, and then sharply dives and plunges into the water, grabbing the fish with its claws.
						</div>
					</div>

					<div class="treasures-5-item treasures-5-item_4">
						<div class="treasures-5-item__title">Narwhal</div>
						<div class="treasures-5-item__descr">
							Narwhals are nicknamed &ldquo;sea unicorns&rdquo;, thanks to&nbsp;their distinctive long horns. The horn is&nbsp;actually an&nbsp;overgrown tooth, and in&nbsp;the past it&nbsp;was believed that male narwhals need it&nbsp;exclusively for mating games, to&nbsp;attract the attention of&nbsp;females. However, scientists have recently found out that they also use it&nbsp;for hunting, using it&nbsp;like a&nbsp;club to&nbsp;kill fish.
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ScrollSwiper from './CustomScrollSwiper';

export default {
    data() {
        return {
            isLoadingZero: false,
            isLoadingOne: false,
            isLoadingTwo: false,
            isLoadingThree: false,
            isMobile: (window.innerWidth <= 768),
        }
    },
	mounted() {
		this.$nextTick(function () {
			const wrap = document.querySelector('.treasures-5-container__wrap');
			const container = document.querySelector('.treasures-5-container');
			const inner = document.querySelector('.treasures-5-inner');
			const marg = document.querySelector('.treasures-5-margin');
			new ScrollSwiper(wrap, container, inner, marg);
            window.addEventListener('resize', this.onResize);
		});
	},
	
    unmounted(){
        window.removeEventListener('resize', this.onResize);
    },
	methods: {
        videoLoadedZero() {
            this.isLoadingZero = true;
        },
        videoLoadedOne() {
            this.isLoadingOne = true;
        },
        videoLoadedTwo() {
            this.isLoadingTwo = true;
        },
        videoLoadedThree() {
            this.isLoadingThree = true;
        },
        onResize() {
            this.isMobile = (window.innerWidth <= 768);
        },
    }
};
</script>

<style lang="scss">
.treasures-5 {
	position: relative;

	@media (max-width: 768px) {
		z-index: 1;
	}

	&-margin {
		display: flex;
		width: 100%;
		height: 100%;
	}

	&-inner {
		position: relative;
		display: flex;
		flex-shrink: 0;
		width: 144.24rem;
		height: 34.24rem;
		background-image: url(../../public/img/treasures-scroll-5.jpg);
		background-repeat: no-repeat;
		background-size: 100%;
		overflow: hidden;
		@media (max-width: 768px) {
			width: 629rem;
			height: 100%;
			background-image: url(../../public/img/treasures-scroll-5-mobile.png);
		}
	}

	&-container {
		position: sticky;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		height: 100vh;
		width: 100%;

		overflow: hidden;

		@media (max-width: 768px) {
			width: unset;
			height: 144.467rem;
			overflow-x: scroll;
			overflow-y: hidden;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				width: 0;
			}
		}
	}
	&-img {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        picture,
        img {
            flex-shrink: 0;
            width: 100%;
            height: auto;
        }

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
        }

        &_0 {
			top: 8.6rem;
			left: 10rem;
            width: 34.08rem;
            height: 23.45rem;
            video {
				transform: translate(-50%, -30%);
				width: 93%;
            }
        }
        &_1 {
			top: 20.1rem;
			left: 41.3rem;
            width: 31.06rem;
            height: 13.10rem;
            video {
                transform: translate(-50%, -50%) scaleX(-1);
            }
        }
        &_2 {
			top: 4.7rem;
			left: 69.8rem;
            width: 32.89rem;
            height: 17.25rem;
            video {
                transform: translate(-50%, -57%);
            }
        }
        &_3 {
			top: 19.2rem;
			left: 98.3rem;
            width: 41.55rem;
            height: 11.69rem;
            video {
                transform: translate(-50%, -50%);
            }
        }
       
    }

	&-item {
		position: absolute;

		&__title {
			padding: 0.1375rem 0.725rem;
			margin-bottom: 0.86rem;
			width: -moz-max-content;
			width: max-content;
			font-size: 1.0375rem;
			font-weight: 300;
			line-height: 130%;
			color: #fff;
			border-radius: 1.6875rem;
			background: var(--grey, #626261);

			@media (max-width: 768px) {
				font-size: 4rem;
				padding: 1.8716rem 2.6737rem;
				border-radius: 7.22rem;
				margin-bottom: 4.5456rem;
			}
		}

		&__descr {
			font-size: 1.0417rem;
			font-weight: 300;
			line-height: 130%;

			sup {
				line-height: 0;
				font-size: 0.6em;
			}

			@media (max-width: 768px) {
				font-size: 4rem;
			}
		}

		&_1 {
			width: 20.7rem;
			top: 3.7rem;
			left: 5.3rem;

			@media (max-width: 768px) {
				width: 69.2rem;
				top: 18rem;
				left: 9rem;
			}
		}

		&_2 {
			width: 20.4rem;
			top: 10rem;
			left: 46.4rem;

			@media (max-width: 768px) {
				width: 82rem;
				top: 45rem;
				left: 214rem;
			}
		}

		&_3 {
			width: 18rem;
			top: 19.3rem;
			left: 77.5rem;

			@media (max-width: 768px) {
				width: 69rem;
				top: 86rem;
				left: 329rem;
			}
		}

		&_4 {
			width: 22.878rem;
			top: 3.7rem;
			left: 103rem;

			@media (max-width: 768px) {
				width: 88.778rem;
				top: 18rem;
				left: 485rem;
			}
		}
	}
}

.swiper {
	display: none;

	@media (max-width: 768px) {
		display: block;
	}
}
</style>