var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-container__wrap"},[_c('div',{staticClass:"treasures-1-margin"},[_c('div',{staticClass:"treasures-1-container"},[_c('div',{staticClass:"treasures-1-inner"},[_vm._m(0),_c('div',{staticClass:"smew"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true},on:{"loadeddata":_vm.videoLoaded}},[_c('source',{attrs:{"src":"video/smew.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/smew.webm","type":"video/webm"}})]),(!_vm.isLoadingOne)?_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":"img/smew.webp"}}),_c('img',{attrs:{"loading":"lazy","src":"img/smew.png","alt":""}})]):_vm._e()]),_c('div',{staticClass:"spider"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"video/spider.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/spider.webm","type":"video/webm"}})])]),_c('div',{staticClass:"kutora"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"video/kutora.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/kutora.webm","type":"video/webm"}})])]),_c('div',{staticClass:"owl"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"video/owl.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/owl.webm","type":"video/webm"}})])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_1"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Smew")]),_c('div',{staticClass:"treasures-1-item__descr"},[_c('p',{staticClass:"mb-10"},[_vm._v(" This species of duck is a symbol of Pasvik and has been part of the nature reserve's logo since 2021. ")]),_c('p',[_vm._v(" While a relatively rare duck for other Russian regions, the smew is one of the most populous and common species in Pasvik. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_2"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Freshwater pearl mussel")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" This is one of the longest-living invertebrates in existence: it can live more than 100 years and even more than 200, according to some data. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_3"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Hooker's flapwort")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" The Murmansk Region is known to have only ten locations inhabited by this liverwort species, each of them with extre ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_4"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Aculepeira lapponica")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" This is the orb-weaving spider found on the Scandinavian Peninsula and in the Murmansk Region. Only two female spiders of this species have been spotted in the Pasvik nature reserve. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_5"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Eurasian water shrew")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" This large shrew is a good swimmer that hunts for fish and waterfowl chicks in addition to insects. The Eurasian water shrew has venomous saliva with strong paralytic properties. This enables the shrew to move its paralysed prey to a store and keep it to eat later. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_6"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Great grey owl")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" It is One of the largest species of wood owls. Unlike many other owls, great grey owls are active not only during the night but also in daytime. ")])])
}]

export { render, staticRenderFns }