<template>
    <div class="kola">
        <BaseHero title="Polar Division: Kola MMC area. Natural inhabitants of&nbsp;the Kola Polar Region"
            :descr="heroDescr"
            :img="'img/hero5.jpg'" :imgMobile="'img/hero5-mobile.jpg'" />
        <MainSection>
            <SectionWhite>
                <BaseTextBlock class="text mb-40" title="Division profile" :tooltip="true">
                    <p class="mb-10">
                        Polar
                        <BaseTooltip :word="'Division'">
                            Companies create divisions by grouping together enterprises or facilities in the same region or with shared tasks, such as feedstock transportation or energy supply. Divisions help streamline the management of large organisations.
                        </BaseTooltip>: Kola MMC area  comprises the facilities of Kola Mining and Metallurgical Company (MMC), which was established on the basis of the oldest nickel mining and processing enterprises, Severonickel and Pechenganickel. MMC's divisions are geographically remote from each other and form two clusters, Monchegorsk and Nickel – Zapolyarny.
                    </p>
                    <p>
                        Today, Nornickel is prioritising the upgrade of the Polar Division: Kola MMC area's facilities in an effort to reduce its industrial footprint and protect the environment.
                    </p>
                </BaseTextBlock>

                <BigMap :circales="circales" img="img/kola-big-map" imgMobile="img/kola-big-map-mobile">
                    <div class="circales">
                        <div class="circale-block">
                            <svg class="circale-block__img" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                viewBox="0 0 14 14" fill="none">
                                <circle cx="7" cy="7" r="7" fill="#654EA3" />
                            </svg>

                            <div class="circale-block__text">Industrial facilities</div>
                        </div>
                        <div class="circale-block">
                            <svg class="circale-block__img" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                viewBox="0 0 14 14" fill="none">
                                <circle cx="7" cy="7" r="7" fill="#009CA6" />
                            </svg>

                            <div class="circale-block__text">Specially protected <br/>natural areas</div>
                        </div>
                    </div>
                </BigMap>
                <section class="scroll">
                    <div class="scroll-bg bg-1">
                    </div>
                    <div class="scroll-bg bg-2">
                    </div>
                    <div class="scroll-bg bg-3">
                    </div>
                    <div class="scroll-bg bg-4">
                    </div>
                    <div class="scroll-wrap">
                        <div class="scroll-card card-1 text-block__descr">
                            <p>Nickel – Zapolyarny production site</p>
                            <p>
                                It develops deposits located between Nickel and Zapolyarny in the west of the Murmansk Region, where the сompany mines ore containing nickel, copper and other valuable components and then processes it at the zapolyarny Concentrator to obtain copper-nickel concentrate.
                            </p>
                            <BaseDropdownInfo title="Facilities" :color="'#D38235'"
                                :text="'<ul><li>Severny Mine</li><li>Kaula-Kotselvaara Mine</li><li>Zapolyarny Concentrator</li></ul>'" />
                        </div>
                        <div class="scroll-card card-2 text-block__descr">
                            <p>Pasvik Nature Reserve</p>
                            <p>
                                It was established to preserve northern pine forests, extensive wetlands of global importance and water birds of the Murmansk Region. The nature reserve is located to the south-west of the Nickel – Zapolyarny production site.
                            </p>
                        </div>
                        <div class="scroll-card card-3 text-block__descr">
                            <p>Monchegorsk production site</p>
                            <p>The site is located to the south-west of the residential area of Monchegorsk, in the central part of the region. It includes the metallurigical facilities of Kola MMC, which produce copper and nickel cathodes, nickel carbonyl, electrolytic cobalt, concentrates of precious metals, copper and nickel, and sulphuric acid.
                            </p>
                            <BaseDropdownInfo title="Facilities" :color="'#D38235'"
                                :text="'<ul><li>Refining shop</li><li>Chemical and metallurgical shop</li><li>Nickel tankhouses No. 1 and No. 2</li></ul>'" />
                        </div>
                        <div class="scroll-card card-4 text-block__descr">
                            <p>Lapland Nature Reserve</p>
                            <p>The reserve is the nearest and most important for the restoration and conservation of species diversity in the area affected by the industrial facilities on the Monchegorsk site. It was established to protect the western population of the alpine-tundra wild reindeer, and some ecosystems unique to the Kola Peninsula. Part of the reserve's
                                <BaseTooltip :word="'buffer zone'">
                                    Safety zones that protect and maintain the viability of protected plants, a group of plants or an entire natural area. No tree felling, hunting, or motorised traffic are allowed in the buffer zones.
                                </BaseTooltip> is in the area of moderate and insignificant impact of Nornickel's operations.
                            </p>
                        </div>
                    </div>
                </section>

                <BaseTextBlock class="text mb-40" title="Production upgrade">
                    <p class="mb-10">
                        At the moment Nornickel is actively upgrading Kola MMC's existing facilities and shutting down obsolete ones, based on the modern vision of eco-friendly production.
                    </p>
                    <p class="mb-10">
                        <b>2020</b>&nbsp;&mdash; the company shut down one of the region’s oldest production facilities, a smelting shop in Nickel. 
                    </p>
                    <p>
                        <b>2021</b>&nbsp;&mdash; Nornickel shut down a copper refining facility in Monchegorsk, which had been producing copper using outdated technology.
                    </p>
                </BaseTextBlock>

                <div class="modernization">
                    <div class="modernization__item">
                        <div class="modernization__item-top">2015</div>
                        <div class="modernization__item-mid">
                            <div class="modernization__mid-text_1">
                                2020
                            </div>
                            <div class="modernization__mid-text_2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="28" viewBox="0 0 18 28"
                                    fill="none">
                                    <path
                                        d="M9 28L17.6603 13L0.339745 13L9 28ZM7.5 -6.55666e-08L7.5 14.5L10.5 14.5L10.5 6.55666e-08L7.5 -6.55666e-08Z"
                                        fill="#333333" />
                                </svg>
                                71<span>%</span>
                            </div>
                            <div class="modernization__mid-bg"></div>
                        </div>
                        <div class="modernization__item-bottom">
                            SO₂ emissionsin the area of Nickel and Zapolyarny
                        </div>
                    </div>
                    <div class="modernization__item">
                        <div class="modernization__item-top">2015</div>
                        <div class="modernization__item-mid">
                            <div class="modernization__mid-text_1">
                                2021
                            </div>
                            <div class="modernization__mid-text_2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="28" viewBox="0 0 18 28"
                                    fill="none">
                                    <path
                                        d="M9 28L17.6603 13L0.339745 13L9 28ZM7.5 -6.55666e-08L7.5 14.5L10.5 14.5L10.5 6.55666e-08L7.5 -6.55666e-08Z"
                                        fill="#333333" />
                                </svg>
                                90<span>%</span>
                            </div>
                            <div class="modernization__mid-bg"></div>
                        </div>
                        <div class="modernization__item-bottom">
                           Total SO₂ emissions by Polar Division: Kola MMC area
                        </div>
                    </div>
                </div>

                <BaseTextBlock class="text text-w mb-40"
                    :title="'The industrial impact of&nbsp;the Polar Division: Kola MMC area'">
                    <p class="mb-10">
                        In&nbsp;2022, the total impact zone of&nbsp;Kola MMC industrial sites and other facilities which could potentially affect the surrounding ecosystems was assessed.
                        </p>

                    <p class="mb-10">
                        <!-- <BaseTooltip :word="'кумулятивного'">
                            Под кумулятивным понимается суммарное воздействие всех антропогенных объектов: города,
                            Кольской и&nbsp;других ГМК, а&nbsp;также расположенных в&nbsp;исследуемой зоне предприятий.
                            Достоверно отделить одно влияние от&nbsp;другого по&nbsp;реакции природных организмов
                            невозможно.
                        </BaseTooltip> -->
                        Based on&nbsp;the state of&nbsp;the ecosystems in&nbsp;the region, the participants in&nbsp;the Great Scientific Expedition wanted to&nbsp;determine the radius of&nbsp;the impact on&nbsp;terrestrial ecosystems close to&nbsp;the Monchegorsk and Nikel-Zapolyarny industrial sites. They estimated it&nbsp;to&nbsp;be&nbsp;around 15&nbsp;to&nbsp;20&nbsp;km from the facilities. For aquatic ecosystems, the boundary is&nbsp;even further&nbsp;&mdash; between 20&nbsp;and 30&nbsp;km.
                    </p>
                    <p class="mb-10">
                        Any change to&nbsp;local ecosystems directly depends on&nbsp;the intensity of&nbsp;the impact. Industrial impact is&nbsp;only significant in&nbsp;the &ldquo;zone of&nbsp;intense impact&rdquo;&nbsp;&mdash; that is&nbsp;within 1&ndash;4&nbsp;km from the sites for terrestrial ecosystems, and within 2&ndash;4&nbsp;km for aquatic ecosystems.
                    </p>
                    <p class="mb-10">
                        The zone of&nbsp;moderate impact stretches from 4&nbsp;to&nbsp;12&nbsp;km from the industrial sites, and the zone of&nbsp;insignificant impact is&nbsp;located between 12&nbsp;and 20&nbsp;km. Anything further away than 20km is&nbsp;considered to&nbsp;be&nbsp;a&nbsp;background zone, where impact is&nbsp;minimal. 
                    </p>
                    <p class="mb-10">
                        In&nbsp;just three years, the Pasvik Nature Reserve has been actively recovering, trying to&nbsp;reverse the negative impact of&nbsp;70&nbsp;years of&nbsp;industry in&nbsp;the area. In&nbsp;2020, Norilsk Nickel&rsquo;s smelter was closed. The smelter was something which had a&nbsp;negative impact on&nbsp;the Pasvik territory.
                    </p>
                    <p class="mb-10">
                        Sites of&nbsp;historical impact were included in&nbsp;the studies carried out in&nbsp;2022 and 2023. It&nbsp;was recommended that continuous monitoring should continue at&nbsp;these sites.
                    </p>
                </BaseTextBlock>
                <div class="transbaikal-radius mb-60">
                    <div class="transbaikal-radius__wrap">
                        <div class="transbaikal-radius__wrap-top mb-10">> 20 km*</div>
                        <div class="transbaikal-radius__wrap-mid mb-10">Background Zone</div>
                        <div class="transbaikal-radius__wrap-bottom">
                            Beyond this radius, biodiversity indicators are considered to be insignificant
                            <!-- <BaseTooltip :word="'фоновые'">
                                На&nbsp;фоновых участках исследователи не&nbsp;обнаружили следов воздействия
                                предприятий,
                                экосистемы здесь сохранены в&nbsp;их&nbsp;исходном, первозданном виде. Состояние
                                биоразнообразия на&nbsp;фоновых территориях принимают за&nbsp;эталонное
                                и&nbsp;используют
                                эти данные для&nbsp;наблюдения за&nbsp;природой в&nbsp;зонах воздействия.
                            </BaseTooltip> -->
                        </div>
                    </div>
                    <picture>
                        <source media="(max-width: 768px)" type="image/webp" srcset="img/kola-radius-mobile.webp">
                        <img loading="lazy" src="img/kola-radius.webp" alt="">
                    </picture>
                    <div class="transbaikal-radius__descr">
                        <span>*</span>
                        <span>
                            For aquatic ecosystems in&nbsp;the area of&nbsp;the Monchegorsk site, the zone of&nbsp;insignificant impact is&nbsp;from 25&nbsp;to&nbsp;30&nbsp;km away and further.
                        </span>
                    </div>
                </div>

                <div class="transbaikal-factors mb-60">
                    <div class="transbaikal-factors__left">
                        <div class="transbaikal-factors__descr">
                            The negative impact of&nbsp;the Polar Division of&nbsp;Kola MMC&rsquo;s facilities includes factors such&nbsp;as
                        </div>
                        <img loading="lazy" class="transbaikal-factors__img-grass" src="img/grass.png" alt="">
                    </div>
                    <ul class="transbaikal-factors__items">
                        <li class="transbaikal-factors__item">
                            <div class="transbaikal-factors__item-title">Expropriation of territories
                            </div>
                            <div class="transbaikal-factors__item-text">
                               Significant transformation of&nbsp;the landscape, including the formation of&nbsp;a&nbsp;man-made wasteland.
                            </div>
                        </li>
                        <li class="transbaikal-factors__item">
                            <div class="transbaikal-factors__item-title">Air emissions</div>
                            <div class="transbaikal-factors__item-text">
                                In&nbsp;the zone of&nbsp;influence, we&nbsp;can see significant environmental impact, in&nbsp;the form of&nbsp;traces of&nbsp;chemical burns on&nbsp;the trunks of&nbsp;trees and shrubs, and on&nbsp;the moss-lichen cover.
                            </div>
                        </li>
                        <li class="transbaikal-factors__item">
                            <div class="transbaikal-factors__item-title">Sewage</div>
                            <div class="transbaikal-factors__item-text">
                                Sewage, and the inflow of&nbsp;various toxic substances and organic matter from the catchment area.
                            </div>
                        </li>
                        <li class="transbaikal-factors__item">
                            <div class="transbaikal-factors__item-title">Pyrogenic effects</div>
                            <div class="transbaikal-factors__item-text">
                                Fires due to&nbsp;human activity can be&nbsp;considered to&nbsp;be&nbsp;an&nbsp;indirect factor, since they are not used in&nbsp;the technological cycle within the study zone.
                            </div>
                        </li>
                    </ul>
                </div>

                <BaseSidebar class="mb-140">
                    Norilsk Nickel is&nbsp;doing a&nbsp;lot of&nbsp;work to&nbsp;neutralise the negative impact on&nbsp;the environment. The Company&rsquo;s Environmental Strategy is&nbsp;updated annually&nbsp;&mdash; visit Nornickel&rsquo;s official website to&nbsp;find out more.
                </BaseSidebar>

                <BaseTextBlock class="text mb-100" title="Soil cover" :tooltip="true">
                    <p class="mb-10">
                        The high content of&nbsp;heavy metals in&nbsp;the soil in&nbsp;the Polar Division: Kola MMC location area is&nbsp;largely due to&nbsp;a&nbsp;natural anomaly. Scientific research did not reveal any negative impact on&nbsp;adjacent soils of&nbsp;surface water runoff from the tailing dam in&nbsp;the city of&nbsp;Zapolyarny, nor did they find any arsenic or&nbsp;heavy metals in&nbsp;them.
                    </p>
                    <p>
                        Scientists recommend improving the quality of&nbsp;land affected by&nbsp;cumulative man-made factors by&nbsp;remediation. Remediation means cleaning the land by&nbsp;removing a&nbsp;large amount of&nbsp;the pollution which is&nbsp;dangerous to&nbsp;local flora and fauna.
                    </p>
                </BaseTextBlock>

                <BaseTextBlock class="text mb-100" title="Increasing efforts with the help of&nbsp;IEI" :tooltip="true">
                    <p class="mb-10">
                        In&nbsp;order to&nbsp;effectively track the changes in&nbsp;the area where its facilities operate, Norilsk Nickel commissioned scientists to&nbsp;develop an&nbsp;<a href="/indicator">Integral Ecosystem Indicator</a>
                        (IEI).
                    </p>
                    <p class="mb-10">
                        By&nbsp;comparing the data on&nbsp;the state of&nbsp;various components of&nbsp;both terrestrial and aquatic ecosystems in&nbsp;the impact zones and in&nbsp;background areas, we&nbsp;can find out whether there are any deviations from the norm.
                    </p>
                    <p>
                        According to the results of the study conducted in 2022 and 2023, the IEI value in the area of the Polar Division of Kola MMC facilities is 0.92 for terrestrial ecosystems and 0.84 for aquatic ecosystems. The closer this indicator is to 1, the less the conditional loss of biodiversity in the affected area.
                    </p>
                </BaseTextBlock>

                <div v-if="!isMobile">
                    <picture>
                        <img loading="lazy" src="img/transbaikal-factors.webp" alt="">
                    </picture>
                </div>

                <div v-else>
                    <picture style="overflow: hidden; width: 100%; display: block;">
                        <img loading="lazy" 
                            src="img/terrestrial-ecosystem.webp" alt="">
                    </picture>

                    <picture>
                        <img loading="lazy" src="img/aquatic-ecosystems.webp" alt="">
                    </picture>
                </div>

                <BaseSidebar>
                    Nornickel intends to&nbsp;regularly measure the IEI in&nbsp;order to&nbsp;monitor the effectiveness of&nbsp;measures implemented to&nbsp;conserve the ecosystem.
                </BaseSidebar>
            </SectionWhite>
            <SectionOrange>
                <BaseTextBlock class="text mb-60 mb-60-m" :tooltip="true">
                    <div class="section-orange__title">
                        What lives in&nbsp;the Kola Arctic?
                    </div>
                    <div class="section-orange__descr">
                        In&nbsp;the area next to&nbsp;the Monchegorsk and Nikel-Zapolyarny industrial sites, the participants of&nbsp;the Great Scientific Expedition discovered several 
                        <BaseTooltip :word="'protected species'">
                            Rare and endangered species, whose numbers are declining or&nbsp;have already declined to&nbsp;dangerously low levels. They are assigned protected status and added to&nbsp;the endangered list, known as&nbsp;the Red Book. This can either be&nbsp;regional, or&nbsp;for the whole of&nbsp;Russia. Protected status prohibits the hunting of&nbsp;these animals. In&nbsp;the case of&nbsp;endangered plants and mushrooms, it&nbsp;also limits construction and other activities in&nbsp;the areas where they are found. 
                        </BaseTooltip>
                        of&nbsp;plants and birds, and identified areas that are critical for the life and well-being of&nbsp;the local fauna. 
                        <BaseTooltip :word="'Indicator species'">
                            Researchers select indicator species by&nbsp;looking at&nbsp;the plants and animals typical of&nbsp;the region and then choosing the ones that are most sensitive to&nbsp;deviations in&nbsp;the state of&nbsp;the environment. Changes in&nbsp;the composition of&nbsp;the indicator species can be&nbsp;a&nbsp;sign that something has gone wrong in&nbsp;the ecosystem.
                        </BaseTooltip>of&nbsp;plants and animals were also selected for further observations on&nbsp;the state of&nbsp;biodiversity.
                    </div>
                </BaseTextBlock>
                <div class="flora-and-fauna">
                    <div class="flora-and-fauna__wrap">
                        <BaseTextBlock title="Flora">
                            <p class="mb-10">
                                In&nbsp;the Zapolyarny area and the village of&nbsp;Nikel, forest-tundra shrubs and small, woody plants dominate. The landscape consists of&nbsp;areas of&nbsp;
                                <BaseTooltip :word="'krummholz'">
                                    Trees and shrubs with crooked trunks and branches grow in&nbsp;hilly krummholz areas. The strong winds and snow lead to&nbsp;them growing into strange shapes, along with the effects of&nbsp;gravity
                                </BaseTooltip>(windswept areas with stunted, deformed vegetation), alternating with swampy areas. Mosses and lichens are found everywhere, as&nbsp;well as&nbsp;typical northern berries, such as&nbsp;blueberries, cloudberries, lingonberries and cranberries.
                            </p>
                            <p>
                                The industry near Monchegorsk is&nbsp;located in&nbsp;the northern taiga zone. The forests there are mostly sparse, separated by&nbsp;lakes and swamps. In&nbsp;the mountainous areas, we&nbsp;can find tundra species of&nbsp;shrubs and grasses.
                            </p>
                        </BaseTextBlock>
                        <BaseTextBlock title="Fauna">
                            <p class="mb-10">

                                The fauna in&nbsp;the vicinity of&nbsp;the Monchegorsk and Nikel-Zapolyarny industrial sites is&nbsp;not very diverse, which is&nbsp;typical for the far north. Only birds show a&nbsp;rich variety of&nbsp;species. In&nbsp;the zone of&nbsp;intense impact next to&nbsp;buildings, the variety of&nbsp;birds is&nbsp;higher due to&nbsp;
                                <BaseTooltip :word="'synanthropic'">
                                    Synanthropic species of&nbsp;insects, birds, animals and plants have learned to&nbsp;live next to&nbsp;people. They use residential buildings as&nbsp;habitats and can feed on&nbsp;human food supplies or&nbsp;waste.
                                </BaseTooltip> species&nbsp;&mdash; that&nbsp;is, species that have adapted to&nbsp;live in&nbsp;areas of&nbsp;human development. There are also different types of&nbsp;waterfowl in&nbsp;the area, and the proximity to&nbsp;the forests attracts white partridge and capercaillie.

                            </p>
                            <p>
                                The lakes and rivers in&nbsp;the area are rich with different species of&nbsp;fish: whitefish, grayling, rainbow trout, char, perch and pike are all found in&nbsp;large numbers.
                            </p>

                        </BaseTextBlock>
                    </div>
                    <div class="wrap-numbers">
                        <BaseNumberBlock class="" :textTop="'82 species'" textBottom="of&nbsp;vascular plants" />
                        <BaseNumberBlock class="" :textTop="'77 species'" textBottom="of&nbsp;birds" />
                        <BaseNumberBlock class="" :textTop="'14&nbsp;species'" textBottom="of&nbsp;mammals" />
                        <BaseNumberBlock class="" :textTop="'13&nbsp;species'" textBottom="of&nbsp;fish" />
                    </div>
                </div>

                <BaseSidebar class="mb-100">
                    Scientists have not been able to&nbsp;identify a&nbsp;correlation between the abundance and diversity of&nbsp;mammals and the degree of&nbsp;negative impact.
                </BaseSidebar>

                <BaseTextBlock class="mb-140 kola__shrew" :title="'Bioindicators of&nbsp;the state of&nbsp;ecosystems'">
                    <span>
                        <p class="mb-10">
                            The key bioindicator to&nbsp;effectively assess the state of&nbsp;disturbed ecosystems is&nbsp;soil microarthropods, such as&nbsp;moss mites. Typically, their population density increases as&nbsp;we&nbsp;move from the significant impact belt to&nbsp;the background area. Noticeable differences in&nbsp;different belts have been identified in&nbsp;the numbers of&nbsp;microarthropods as&nbsp;a&nbsp;whole, as&nbsp;well as&nbsp;for individual groups, such as&nbsp;moss mites and springtails.
                        </p>
                        <p class="mb-10">
                            Among mammals, the shrew population was an&nbsp;indicator of&nbsp;significant impact. Bird species with significant numbers in&nbsp;the area, such as&nbsp;brambling, willow warbler and redpoll are also potentially useful as&nbsp;indicators. 
                        </p>
                        <p>
                            Among aquatic organisms, scientists have identified phytoplankton, zooplankton, zoobenthos and ichthyofauna, including char and whitefish, as&nbsp;potential indicator species. However, further research is&nbsp;still to&nbsp;confirm and accurately assess their indicator capabilities.
                        </p>
                    </span>
                    <div class="position-info">
                        <picture>
                            <source type="image/webp" srcset="img/shrew.webp">
                            <img loading="lazy" src="img/shrew.png" alt="">
                        </picture>

                        <div class="position-info__descr">
                            Among mammals, the shrew was an&nbsp;indicator of&nbsp;significant impact.
                        </div>

                        <BaseDropdownInfo title="Shrews" :pulsation-color="'Green-Mist'"
                            :text="'An&nbsp;indicator of&nbsp;significant impact is&nbsp;the absence of&nbsp;shrews in&nbsp;traps.'" />
                    </div>
                </BaseTextBlock>

                <div class="legend">
                    <BaseCollageInfo text="Protected species" :color="'#71464E'" />
                    <BaseCollageInfo text="Indicator species" />
                </div>
                <div class="collage mb-100">
                    <div class="collage__golden-eagle">
                        <video v-if="!isMobile" @loadeddata="videoLoadedOne" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/golden-eagle.mov" type="video/mp4">
                            <source src="video/golden-eagle.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingOne || isMobile">
                            <source type="image/webp" srcset="img/collage-kola-golden-eagle.webp">
                            <img loading="lazy" src="img/collage-kola-golden-eagle.png" alt="">
                        </picture>
                    </div>
                    <div class="collage__bluethroat">
                        <video v-if="!isMobile" @loadeddata="videoLoadedTwo" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/bluethroat.mov" type="video/mp4">
                            <source src="video/bluethroat.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingTwo || isMobile">
                            <source type="image/webp" srcset="img/collage-kola-bluethroat.webp">
                            <img loading="lazy" src="img/collage-kola-bluethroat.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__tap-dance">
                        <video v-if="!isMobile" @loadeddata="videoLoadedThree" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/tap-dance.mov" type="video/mp4">
                            <source src="video/tap-dance.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingThree || isMobile">
                            <source type="image/webp" srcset="img/tap-dance.webp">
                            <img loading="lazy" src="img/tap-dance.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__white-browed">
                        <video v-if="!isMobile" @loadeddata="videoLoadedFour" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/white-browed.mov" type="video/mp4">
                            <source src="video/white-browed.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingFour || isMobile">
                            <source type="image/webp" srcset="img/white-browed.webp">
                            <img loading="lazy" src="img/white-browed.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__squirrel">
                        <video v-if="!isMobile" @loadeddata="videoLoadedFive" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/squirrel.mov" type="video/mp4">
                            <source src="video/squirrel.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingFive || isMobile">
                            <img loading="lazy" src="img/squirrel.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__redstart">
                        <video v-if="!isMobile" @loadeddata="videoLoadedSix" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/redstart.mov" type="video/mp4">
                            <source src="video/redstart.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingSix || isMobile">
                            <source type="image/webp" srcset="img/redstart.webp">
                            <img loading="lazy" src="img/redstart.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__yurok">
                        <video v-if="!isMobile" @loadeddata="videoLoadedSeven" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/yurok.mov" type="video/mp4">
                            <source src="video/yurok.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingSeven || isMobile">
                            <source type="image/webp" srcset="img/yurok.webp">
                            <img loading="lazy" src="img/yurok.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__elk">
                        <video @loadeddata="videoLoadedEight" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/elk.mov" type="video/mp4">
                            <source src="video/elk.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingEight">
                            <source type="image/webp" srcset="img/elk.webp">
                            <img loading="lazy" src="img/elk.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__warbler">
                        <video v-if="!isMobile" @loadeddata="videoLoadedNine" autoplay loop muted playsinline>
                            <source src="video/warbler.mov" type="video/mp4">
                            <source src="video/warbler.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingNine || isMobile">
                            <img loading="lazy" src="img/warbler.webp" alt="">
                        </picture>
                    </div>

                    <div class="collage__spider">
                        <video v-if="!isMobile" @loadeddata="videoLoadedTen" autoplay loop muted playsinline>
                            <source src="video/spiders-2.mov" type="video/mp4">
                            <source src="video/spiders-2.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingTen || isMobile">
                            <img loading="lazy" src="img/spiders-2.webp" alt="">
                        </picture>
                    </div>

                    <div class="collage__bug">
                        <video v-if="!isMobile" @loadeddata="videoLoadedEleven" autoplay loop muted playsinline>
                            <source src="video/bug.mov" type="video/mp4">
                            <source src="video/bug.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingEleven || isMobile">
                            <img loading="lazy" src="img/bug-nd.webp" alt="">
                        </picture>
                    </div>

                    <div class="collage__ant">
                        <video v-if="!isMobile" @loadeddata="videoLoadedTwelve" autoplay loop muted playsinline>
                            <source src="video/ant.mov" type="video/mp4">
                            <source src="video/ant.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingTwelve || isMobile">
                            <img loading="lazy" src="img/ant.webp" alt="">
                        </picture>
                    </div>

                    <div class="collage__fox">
                        <video v-if="!isMobile" @loadeddata="videoLoadedThirteen" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/fox.mov" type="video/mp4">
                            <source src="video/fox.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingThirteen || isMobile">
                            <source type="image/webp" srcset="img/fox.webp">
                            <img loading="lazy" src="img/fox.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__shrew">
                        <video v-if="!isMobile" @loadeddata="videoLoadedFourteen" autoplay loop muted playsinline>
                            <source src="video/shrew.mov" type="video/mp4">
                            <source src="video/shrew.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingFourteen || isMobile">
                            <img loading="lazy" src="img/shrew.webp" alt="">
                        </picture>
                    </div>

                    <div class="collage__marten">
                        <video v-if="!isMobile" @loadeddata="videoLoadedFifteen" autoplay loop muted playsinline>
                            <source src="video/marten.mov" type="video/mp4">
                            <source src="video/marten.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingFifteen || isMobile">
                            <img loading="lazy" src="img/marten.webp" alt="">
                        </picture>
                    </div>

                    <div class="collage__mouse">
                        <video v-if="!isMobile" @loadeddata="videoLoadedSixteen" autoplay loop muted playsinline>
                            <source src="video/mouse.mov" type="video/mp4">
                            <source src="video/mouse.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingSixteen || isMobile">
                            <img loading="lazy" src="img/mouse-2.webp" alt="">
                        </picture>
                    </div>

                    <div class="collage__swan">
                        <video v-if="!isMobile" @loadeddata="videoLoadedEighteen" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/swan.mov" type="video/mp4">
                            <source src="video/swan.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingEighteen || isMobile">
                            <source type="image/webp" srcset="img/collage-kola-swan.webp">
                            <img loading="lazy" src="img/collage-kola-swan.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__broody">
                        <video v-if="!isMobile" @loadeddata="videoLoadedNineteen" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/broody.mov" type="video/mp4">
                            <source src="video/broody.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingNineteen || isMobile">
                            <source type="image/webp" srcset="img/broody.webp">
                            <img loading="lazy" src="img/broody.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__grayling">
                        <video v-if="!isMobile" @loadeddata="videoLoadedTwenty" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/grayling.mov" type="video/mp4">
                            <source src="video/grayling.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingTwenty || isMobile">
                            <source type="image/webp" srcset="img/collage-kola-grayling.webp">
                            <img loading="lazy" src="img/collage-kola-grayling.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__char">
                        <video v-if="!isMobile" @loadeddata="videoLoadedTwentyOne" autoplay loop muted playsinline
                            preload="none">
                            <source src="video/char.mov" type="video/mp4">
                            <source src="video/char.webm" type="video/webm">
                        </video>

                        <picture v-if="!isLoadingTwentyOne || isMobile">
                            <source type="image/webp" srcset="img/char.webp">
                            <img loading="lazy" src="img/char.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__watercolor">
                        <picture>
                            <source type="image/webp" srcset="img/watercolor.webp">
                            <img loading="lazy" src="img/watercolor.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__watercolor-2">
                        <picture>
                            <source type="image/webp" srcset="img/watercolor.webp">
                            <img loading="lazy" src="img/watercolor.png" alt="">
                        </picture>
                    </div>

                    <div class="collage__watercolor-3">
                        <picture>
                            <source type="image/webp" srcset="img/watercolor-2.webp">
                            <img loading="lazy" src="img/watercolor-2.png" alt="">
                        </picture>
                    </div>

                    <BaseDropdownInfo class="golden-eagle" title="Golden eagle" :is-modal="true" :color="'#71464E'"
                        :pulsation-color="'finn'"
                        :text="'The largest eagle with a wingspan reaching 230&nbsp;cm and a body length of up to 93&nbsp;cm. Golden eagles are sensitive to human activity, more often inhabiting mountains than flat landscapes. The species has disappeared from many areas over the past few centuries due to mass extermination, pesticides, the growing number of cities, and development of land by humans.'" />

                    <BaseCollageInfo class="belobrovik" title="Redwing"
                        :text="'Changes its song in different situations: alerts in case of danger and calls when food is found'" />
                    <BaseDropdownInfo class="belobrovik_mobile" title="Redwing" :is-modal="true"
                        :pulsation-color="'Green-Mist'"
                        :text="'Changes its song in different situations: alerts in case of danger and calls when food is found'" />

                    <BaseCollageInfo class="tap-dancing" title="Common redpoll"
                        :text="'The common redpoll’s song sounds like a tapdance'" />

                    <BaseCollageInfo class="bluethroat" title="Bluethroat"
                        :text="'Excellent imitator of other birds’ sounds and songs'" />

                    <BaseCollageInfo class="redstart-coot" title="Redstart"
                        :text="'The red tail of the males, when slightly twitching, resembles a burning flame'" />

                    <BaseCollageInfo class="yurok" title="Brambling" />
                    <BaseCollageInfo class="warbler" title="Willow warbler" />

                    <BaseDropdownInfo class="ground-beetles" title="Ground beetles" :is-modal="true"
                        :pulsation-color="'Green-Mist'"
                        :text="'In&nbsp;the Polar Division of&nbsp;Kola MMC , the researchers found 17&nbsp;species of&nbsp;ground beetles, of&nbsp;which two were designated as&nbsp;indicators&nbsp;&mdash; Carabus glabratus and Amara brunnea. Carabus glabratus reacts negatively to&nbsp;industrial impact&nbsp;&mdash; the more intense the activity, the lower the density of&nbsp;this species in&nbsp;the area. Carabus glabratus was found only in&nbsp;background zones. Conversely, numbers of&nbsp;Amara brunnea increase in&nbsp;areas with intense industrial impact, and gradually decrease closer to&nbsp;background areas'" />

                    <BaseDropdownInfo class="spiders" title="Spiders" :is-modal="true" :pulsation-color="'Green-Mist'"
                        :text="'Researchers noted the lowest species diversity of spiders in the severely exposed areas of the division. Indicators among the species detected were three wandering huntsman spiders: the wolf spider, the ground spider, and the sac spider. Wolf spiders form the core of the population. They do not weave webs; they move quickly and actively inhabit new areas.'" />

                    <BaseDropdownInfo class="ants" title="Ants" :is-modal="true" :pulsation-color="'Green-Mist'"
                        :text="'Of&nbsp;the 12&nbsp;species of&nbsp;ants discovered by&nbsp;the Expedition in&nbsp;the Polar Division of&nbsp;Kola MMC, four members of&nbsp;the Formica genus were used as&nbsp;indicators: the Northern Black Ant, the Redhead Ant, the Hairy Forest Ant, and the Slaver Ant. The distribution and abundance of&nbsp;these ants helped scientists confirm their assumptions about the impact of&nbsp;the industrial facilities on&nbsp;the local nature.'" />

                    <BaseDropdownInfo class="shrew" title="Shrews" :is-modal="true" :pulsation-color="'Green-Mist'"
                        :text="'An&nbsp;indicator of&nbsp;significant impact is&nbsp;the absence of&nbsp;shrews in&nbsp;traps.'" />
                    <BaseCollageInfo class="vole" title="Grey red-backed vole" />

                    <BaseCollageInfo class="kokushnik" title="Fragrant orchid" :width="'10rem'"
                        :color="'#71464E'" />

                    <BaseDropdownInfo class="fingerroot" title="Heath spotted-orchid" :is-modal="true"
                        :width="'10rem'" :color="'#71464E'" :pulsation-color="'finn'"
                        :text="'In&nbsp;Russia, spots on&nbsp;the leaves of&nbsp;this northern orchid were popularly believed to&nbsp;be&nbsp;traces of&nbsp;tears dropped by&nbsp;the cuckoo because of&nbsp;its loneliness, so&nbsp;another name for this species is&nbsp;&laquo;cuckoo tears&raquo;. Heath spotted-orchid seeds develop in&nbsp;symbiosis with certain fungi species, and the plant blooms 6&ndash;8 years after germination.'" />

                    <BaseCollageInfo class="ledum" title="Marsh Labrador tea" :width="'10rem'" />

                    <div class="background background-1"></div>
                    <BaseCollageInfo class="swan" title="Whooper swan" :color="'#71464E'"
                        :text="'The bird received its name because of the deep honking call it often emits in flight. It can reach a speed of up to 80 km/h. The whooper swan is the national bird of Finland'" />

                    <BaseDropdownInfo class="broody" title="Lesser black-backed gull" :is-modal="true" :color="'#71464E'"
                        :pulsation-color="'finn'"
                        :text="'The Big Scientific Expedition found this gull species in severely exposed areas of the Monchegorsk and Murmansk facilities. As well as large bodies of water, the birds are attracted by food waste in rubbish dumps. The abundance of the species is negatively affected by human settlements in its habitats and air pollution from industrial emissions.'" />

                    <div class="background background-2"></div>

                    <BaseDropdownInfo class="zooplankton" title="Zooplankton" :is-modal="true"
                        :pulsation-color="'Green-Mist'"
                        :text="'The rotifer Asplanchna priodonta reproduces itself in the bodies of water of the severely exposed area. The researchers also found the water flea Holopedium gibberum in samples from the insignificantly exposed area and in background areas.'" />

                    <div class="background background-3"></div>
                    <BaseDropdownInfo class="phytoplankton" title="Phytoplankton" :is-modal="true"
                        :pulsation-color="'Green-Mist'"
                        :text="'The green algae Scenedesmus quadricauda is&nbsp;found only in&nbsp;samples from the zone of&nbsp;intense impact of&nbsp;the Polar Division of&nbsp;Kola MMC, and the cyanobacterium Oscillatoria amoena lives exclusively in&nbsp;background areas. In&nbsp;total, scientists have recorded 169 species of&nbsp;phytoplankton'" />

                    <div class="background background-4"></div>
                    <BaseCollageInfo class="tubifex" title="Sludge worm"
                        :text="'A well-known indicator of organic pollution in bodies of water'" />

                    <div class="background background-5"></div>
                    <BaseDropdownInfo class="loach" title="Arctic char" :is-modal="true"
                        :pulsation-color="'Green-Mist'"
                        :text="'It is considered the most nutritious fish in the world: a 30 g piece covers the daily requirement of healthy fatty acids. It also inhabits Norilsk Division’s bodies of water.'" />

                    <div class="background background-6"></div>
                    <BaseCollageInfo class="grayling" title="European grayling" :color="'#71464E'"
                        :text="'The fish can reach a&nbsp;weight of&nbsp;6.7&nbsp;kg'" />
                </div>
                <BaseTextBlock class="mb-100">
                    <p class="mb-10">
                        According to&nbsp;specialists from the Russian Academy of&nbsp;Sciences, both the Lapland and Pasvik nature reserves are habitats that are critically important for the wildlife populations in&nbsp;the division. Protected 
                        <BaseTooltip :word="'endemic species'">
                            A&nbsp;type of&nbsp;animal or&nbsp;plant that is&nbsp;found in&nbsp;a&nbsp;certain territory and nowhere else. Due to&nbsp;the fact that their habitat is&nbsp;limited, endemic species are often listed in&nbsp;the Red Book as&nbsp;rare or&nbsp;endangered.
                        </BaseTooltip> have been discovered in&nbsp;protected areas, and unique ecosystems have been found.
                    </p>
                    <p class="mb-10">
                        Areas of&nbsp;the reserves that are in&nbsp;the zone of&nbsp;industrial impact need constant monitoring. The company already pays special attention to&nbsp;the places that were in&nbsp;the zone of&nbsp;impact of&nbsp;the now closed smelting shop in&nbsp;the village of&nbsp;Nikel.
                    </p>
                    <p>
                        Nornickel regularly provides financial assistance to&nbsp;nature reserves, and sponsors programmes to&nbsp;study and restore various species.
                    </p>
                </BaseTextBlock>

                <div class="kola__new-ward mb-40">
                    <div class="kola__new-ward-title">
                        The rescue of&nbsp;Stanislav the whale
                    </div>
                    <div class="kola__new-ward-descr">
                        In&nbsp;June 2024, in&nbsp;the Teriberskaya Bay of&nbsp;the Barents Sea, an&nbsp;endangered humpback whale became entangled in&nbsp;fishing nets. This rare animal could have died if&nbsp;it&nbsp;wasn&rsquo;t for the well-coordinated work of&nbsp;the Friends of&nbsp;the Ocean rescue squad, with the support of&nbsp;Norilsk Nickel. Despite the complexity of&nbsp;the nets, the difficult weather conditions and Stanislav&rsquo;s stubborn resistance, the rescuers managed to&nbsp;free him in&nbsp;a&nbsp;few days.
                    </div>

                    <video @loadeddata="videoLoadedLast" autoplay loop muted playsinline
                        preload="none">
                        <source src="video/whale.mov" type="video/mp4">
                        <source src="video/whale.webm" type="video/webm">
                    </video>

                    <picture class="kola__new-ward-img" v-if="!isLoadingLast">
                        <img loading="lazy" src="img/whale.png" alt="">
                    </picture>

                    <picture class="kola__new-ward-bg">
                        <source type="image/webp" srcset="img/bg/blue.webp">
                        <img loading="lazy" src="img/bg/blue.png" alt="">
                    </picture>
                </div>

                <div class="kola__button mb-60">
                    <a href="https://www.youtube.com/watch?v=EmbC8ODraFo" target="_blank">
                        Watch the video
                    </a>
                </div>

                <div class="habitat-map">
                    <div class="habitat-map__legend">
                        <div class="habitat-map__legend-top">
                            <div class="habitat-map__legend-title">
                                Affected area
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                    fill="none">
                                    <circle cx="7" cy="7" r="7" fill="#D38235" />
                                </svg>
                                <div>Severe exposure</div>
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                    fill="none">
                                    <circle cx="7" cy="7" r="7" fill="#FFEC42" />
                                </svg>
                                <div>Moderate exposure</div>
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                    fill="none">
                                    <circle cx="7" cy="7" r="7" fill="#009CA6" />
                                </svg>
                                <div>Insignificant exposure</div>
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                    fill="none">
                                    <circle cx="8" cy="8" r="7" fill="white" stroke="#333333" />
                                </svg>
                                <div>Background exposure</div>
                            </div>
                        </div>
                        <div class="habitat-map__legend-bottom">
                            <div class="habitat-map__legend-title">
                                Species
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                1 – Lesser black-backed gull
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                2 – Golden eagle
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                3 – Whooper swan
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                4 – European grayling
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                5 – Heath spotted-orchid
                            </div>
                            <div class="habitat-map__legend-top-item habitat-map__legend-text">
                                6 – Fragrant orchid
                            </div>
                        </div>
                    </div>
                    <div class="habitat-map__wrap">
                        <div v-if="!isMobile" class="habitat-map__title">Protected species habitat map</div>
                        <div v-if="isMobile" class="habitat-map__title">Protected species habitat map</div>
                        <div class="habitat-map__list">
                            <div class="habitat-map__list-item-wrap">
                                <div class="habitat-map__list-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                        fill="none">
                                        <circle cx="7" cy="7" r="7" fill="#D38235" />
                                    </svg>
                                    <div>Severe exposure</div>
                                </div>
                                <ul class="habitat-map__list-list">
                                    <li>Lesser black-backed gull</li>
                                    <li>Golden eagle</li>
                                </ul>
                            </div>

                            <div class="habitat-map__list-item-wrap">
                                <div class="habitat-map__list-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                        fill="none">
                                        <circle cx="7" cy="7" r="7" fill="#FFEC42" />
                                    </svg>
                                    <div>Moderate exposure</div>
                                </div>
                                <ul class="habitat-map__list-list">
                                    <li>European grayling</li>
                                    <li>Fragrant orchid</li>
                                </ul>
                            </div>

                            <div class="habitat-map__list-item-wrap">
                                <div class="habitat-map__list-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                        fill="none">
                                        <circle cx="7" cy="7" r="7" fill="#009CA6" />
                                    </svg>
                                    <div>Insignificant exposure</div>
                                </div>
                                <ul class="habitat-map__list-list">
                                    <li>Golden eagle</li>
                                    <li>Whooper swan</li>
                                    <li>European grayling</li>
                                    <li>Heath spotted-orchid</li>
                                </ul>
                            </div>

                            <div class="habitat-map__list-item-wrap">
                                <div class="habitat-map__list-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <circle cx="8" cy="8" r="7" fill="white" stroke="#333333" />
                                    </svg>
                                    <div>Background exposure</div>
                                </div>
                                <ul class="habitat-map__list-list">
                                    <li>Whooper swan</li>
                                    <li>European grayling</li>
                                    <li>Fragrant orchid</li>
                                </ul>
                            </div>

                        </div>
                        <div class="habitat-map__buttons">
                            <div class="habitat-map__button" :class="{ 'habitat-map__button_active': idMap === map1 }"
                                @click="idMap = 1">
                                Monchegorsk
                            </div>
                            <div class="habitat-map__button" :class="{ 'habitat-map__button_active': idMap === map2 }"
                                @click="idMap = 2">
                                Nickel – Zapolyarny
                            </div>
                        </div>
                    </div>

                    <picture v-show="idMap === 1">
                        <source media="(max-width: 768px)" type="image/webp"
                            srcset="img/habitat-map-kola-1-mobile.webp">
                        <source media="(max-width: 768px)" srcset="img/habitat-map-kola-1-mobile.png">
                        <source type="image/webp" srcset="img/habitat-map-kola-1.webp">
                        <img loading="lazy" src="img/habitat-map-kola-1.png" alt="">
                    </picture>

                    <picture v-show="idMap === 2">
                        <source media="(max-width: 768px)" type="image/webp"
                            srcset="img/habitat-map-kola-2-mobile.webp">
                        <source media="(max-width: 768px)" srcset="img/habitat-map-kola-2-mobile.png">
                        <source type="image/webp" srcset="img/habitat-map-kola-2.webp">
                        <img loading="lazy" src="img/habitat-map-kola-2.png" alt="">
                    </picture>

                    <div v-show="idMap === 1">
                        <div class="habitat-map__city habitat-map__city-1">
                            <div class="habitat-map__city-circale"></div>
                            <span>Monchegorsk</span>
                        </div>
                        <div class="habitat-map__river">
                            Imandra
                        </div>
                    </div>
                    <div v-show="idMap === 2">
                        <div class="habitat-map__city habitat-map__city-2">
                            <div class="habitat-map__city-circale"></div>
                            <span>Nickel</span>
                        </div>
                        <div class="habitat-map__city habitat-map__city-3">
                            <div class="habitat-map__city-circale"></div>
                            <span>Zapolyarny</span>
                        </div>
                        <div class="habitat-map__city habitat-map__city-4">
                            <div class="habitat-map__city-circale"></div>
                            <span>Murmansk</span>
                        </div>
                        <div class="habitat-map__name-sea">
                            Barents Sea
                        </div>
                    </div>
                </div>

                <BaseTextBlock class="mb-40">
                    Some rare species can live in the areas affected by the facilities despite the noise or other impacts. After all, their survival depends on the availability of food and absence of human disturbance.
                </BaseTextBlock>
                <BaseSidebar>
                    <p>
                        Minimising the legacy anthropogenic footprint of many decades, restoring and preserving the Kola Peninsula's ecosystems are important parts of Nornickel's environmental programme at Polar Division: Kola MMC location area.
                    </p>
                </BaseSidebar>
            </SectionOrange>
        </MainSection>
    </div>
</template>

<script>
import BaseHero from '@/layout/BaseHero.vue'
import BigMap from '@/components/BigMap.vue'
import SectionOrange from '@/layout/SectionOrange.vue'
import SectionWhite from '@/layout/SectionWhite.vue'
import MainSection from '@/layout/MainSection'
import BaseTooltip from '@/components/BaseTooltip.vue'
import BaseTextBlock from '@/components/BaseTextBlock.vue'
import BaseSidebar from '@/components/BaseSidebar'
import BaseCollageInfo from '@/components/BaseCollageInfo.vue'
import BaseDropdownInfo from '@/components/BaseDropdownInfo.vue'
import BaseNumberBlock from '@/components/BaseNumberBlock'

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
    components: {
        BaseHero,
        SectionOrange,
        SectionWhite,
        BigMap,
        BaseTextBlock,
        MainSection,
        BaseSidebar,
        BaseTooltip,
        BaseDropdownInfo,
        BaseCollageInfo,
        BaseNumberBlock
    },
    data() {
        return {
            isLoadingOne: false,
            isLoadingTwo: false,
            isLoadingThree: false,
            isLoadingFour: false,
            isLoadingFive: false,
            isLoadingSix: false,
            isLoadingSeven: false,
            isLoadingEight: false,
            isLoadingNine: false,
            isLoadingTen: false,
            isLoadingEleven: false,
            isLoadingTwelve: false,
            isLoadingThirteen: false,
            isLoadingFourteen: false,
            isLoadingFifteen: false,
            isLoadingSixteen: false,
            isLoadingSeventeen: false,
            isLoadingEighteen: false,
            isLoadingNineteen: false,
            isLoadingTwenty: false,
            isLoadingTwentyOne: false,
            isLoadingTwentyTwo: false,
            isLoadingLast: false,
            map1: 1,
            map2: 2,
            idMap: 1,
            isMobile: (window.innerWidth <= 768),
            circales: [
                {
                    color: 'green',
                    number: '1'
                },
                {
                    color: 'green',
                    number: '2'
                },
                {
                    color: 'purple',
                    number: '3'
                },
                {
                    color: 'purple',
                    number: '4'
                },
            ],
            heroDescr: 'Flora and fauna near Nornickel’s industrial assets in the Murmansk Region'
        }
    },
    mounted() {
        this.$nextTick(function () {
            this.scrollAnimation()
            // this.parallax()
        })
    },
    methods: {
        videoLoadedOne() {
            this.isLoadingOne = true;
        },
        videoLoadedTwo() {
            this.isLoadingTwo = true;
        },
        videoLoadedThree() {
            this.isLoadingThree = true;
        },
        videoLoadedFour() {
            this.isLoadingFour = true;
        },
        videoLoadedFive() {
            this.isLoadingFive = true;
        },
        videoLoadedSix() {
            this.isLoadingSix = true;
        },
        videoLoadedSeven() {
            this.isLoadingSeven = true;
        },
        videoLoadedEight() {
            this.isLoadingEight = true;
        },
        videoLoadedNine() {
            this.isLoadingNine = true;
        },
        videoLoadedTen() {
            this.isLoadingTen = true;
        },
        videoLoadedEleven() {
            this.isLoadingEleven = true;
        },
        videoLoadedTwelve() {
            this.isLoadingTwelve = true;
        },
        videoLoadedThirteen() {
            this.isLoadingThirteen = true;
        },
        videoLoadedFourteen() {
            this.isLoadingFourteen = true;
        },
        videoLoadedFifteen() {
            this.isLoadingFifteen = true;
        },
        videoLoadedSixteen() {
            this.isLoadingSixteen = true;
        },
        videoLoadedSeventeen() {
            this.isLoadingSeventeen = true;
        },
        videoLoadedEighteen() {
            this.isLoadingEighteen = true;
        },
        videoLoadedNineteen() {
            this.isLoadingNineteen = true;
        },
        videoLoadedTwenty() {
            this.isLoadingTwenty = true;
        },
        videoLoadedTwentyOne() {
            this.isLoadingTwentyOne = true;
        },
        videoLoadedTwentyTwo() {
            this.isLoadingTwentyTwo = true;
        },
        videoLoadedLast() {
            this.isLoadingLast = true;
        },
        onResize() {
            this.isMobile = (window.innerWidth <= 768);
        },
        scrollMobile() {
            const collageItems = Array.from(document.querySelectorAll(".scroll-bg.active"))

            collageItems.forEach((elem) => {
                elem.css('background-position', '0px ' + document.scrollTop() + 'px')
            })
        },
        scrollAnimation() {
            const collageItems = Array.from(document.querySelectorAll(".text-block__wrap"));
            collageItems.forEach((elem) => {

                this.oneScrollTrigger = gsap.fromTo(elem,
                    {
                        y: 50,
                    },
                    {
                        y: 0,
                        duration: 1,
                        scrollTrigger: {
                            start: '0% 100%',
                            end: 'bottom 75%',
                            trigger: elem,
                            scrub: true,
                            // markers: true,
                        },
                    });
            });

            const sidebarItems = Array.from(document.querySelectorAll(".sidebar"));
            sidebarItems.forEach((elem) => {
                this.twoScrollTrigger = gsap.fromTo(elem,
                    {
                        y: 50,
                    },
                    {
                        y: 0,
                        duration: 1,
                        scrollTrigger: {
                            start: '0% 100%',
                            end: 'bottom 75%',
                            trigger: elem,
                            // markers: true,
                            scrub: true,
                        },
                    });
            });

            this.ScrollTrigger = gsap.to(".scroll-bg", {
                scrollTrigger: {
                    trigger: ".scroll",
                    start: '0%',
                    scrub: true,
                    toggleClass: { className: "active", targets: ".scroll-bg" }
                },
            })
            if (window.innerWidth <= 768) {
                this.scrollMobile()
            }

            gsap.fromTo(".bg-1",
                { opacity: '1' },
                {
                    opacity: '0',
                    scrollTrigger: {
                        trigger: ".card-1",
                        start: 'top 0%',
                        end: 'bottom 50%',
                        endTrigger: '.card-2',
                        // markers: true,
                        scrub: true
                    },

                });

            gsap.fromTo(".bg-2",
                { opacity: '1' },
                {
                    opacity: '0',
                    scrollTrigger: {
                        trigger: ".card-2",
                        start: 'top 0%',
                        end: 'bottom 50%',
                        endTrigger: '.card-3',
                        // markers: true,
                        scrub: true,
                    },

                });

            gsap.fromTo(".bg-3",
                { opacity: '1' },
                {
                    opacity: '0',
                    scrollTrigger: {
                        trigger: ".card-3",
                        start: 'top 0%',
                        end: 'bottom 50%',
                        endTrigger: '.card-4',
                        // markers: true,
                        scrub: true,
                    },

                });

            gsap.to(".scroll-bg", {
                scrollTrigger: {
                    trigger: ".card-4",
                    start: () => "+=120%",
                    end: () => "+=" + (document.querySelector(".scroll-wrap").offsetHeight),
                    // markers: true,
                    scrub: true,
                    toggleClass: { className: "remove-active", targets: ".scroll-bg" }
                },
            });
        },
        // parallax() {
        //     if (window.innerWidth > 768) {
        //         gsap.fromTo(".collage__golden-eagle",
        //             { y: 115 },
        //             {
        //                 y: -15,
        //                 scrollTrigger: {
        //                     trigger: ".collage",
        //                     start: 'top 100%',
        //                     end: '10%',
        //                     // markers: true,
        //                     scrub: true
        //                 },

        //             });

        //         gsap.fromTo(".collage__bluethroat",
        //             { y: 55 },
        //             {
        //                 y: -15,
        //                 scrollTrigger: {
        //                     trigger: ".collage",
        //                     start: '24% 100%',
        //                     end: '29%',
        //                     // markers: true,
        //                     scrub: true
        //                 },

        //             });

        //         gsap.fromTo(".collage__swan",
        //             { y: 30 },
        //             {
        //                 y: -10,
        //                 scrollTrigger: {
        //                     trigger: ".collage",
        //                     start: '75% 100%',
        //                     end: 'bottom',
        //                     // markers: true,
        //                     scrub: true
        //                 },

        //             });

        //         gsap.fromTo(".collage__grayling",
        //             { y: 40 },
        //             {
        //                 y: -10,
        //                 scrollTrigger: {
        //                     trigger: ".collage",
        //                     start: '90% 100%',
        //                     end: '100%',
        //                     // markers: true,
        //                     scrub: true
        //                 },

        //             });
        //     }
        // }
    }
}
</script>

<style lang="scss">
.kola {
    overflow: hidden;

    .hero {
        min-height: 56.6rem;
        height: 100vh;

        @media (max-width: 768px) {

            min-height: 217rem;
        }
    }

    .text-w {
        width: 34.3rem;

        @media (max-width: 768px) {
            width: unset;
        }
    }

    .big-map {
        margin-bottom: 2.7778rem;

        @media (max-width: 768px) {
            display: flex;
            margin-bottom: 21.3334rem;
            flex-direction: column-reverse;
            height: unset;
        }

        .map-circale {
            &__1 {
                top: 25.5rem;
                left: 46.5rem;
            }

            @media (max-width: 768px) {
                top: 78.5rem;
                left: 39rem;
            }

            &__2 {
                top: 14.2rem;
                left: 35.7rem;

                @media (max-width: 768px) {
                    top: 48.5rem;
                    left: 10.5rem;
                }
            }

            &__3 {
                top: 9rem;
                left: 39.7rem;

                @media (max-width: 768px) {
                    top: 31.5rem;
                    left: 23.5rem;
                }
            }

            &__4 {
                top: 28rem;
                left: 50.3rem;

                @media (max-width: 768px) {
                    top: 94.7rem;
                    left: 62rem;
                }
            }
        }

        .circales {
            position: absolute;
            top: 8.31rem;
            left: 8.63rem;
            width: 11.94rem;
            display: flex;
            flex-direction: column;
            gap: 1.12rem;

            @media (max-width: 768px) {
                top: 0;
                flex-direction: row;
                width: 81.4rem;
                gap: 3.3rem;
            }

            .circale-block {
                display: flex;
                align-items: flex-start;
                gap: 1.06rem;

                @media (max-width: 768px) {
                    width: 39rem;

                    &:last-child {
                        width: 51rem;
                    }
                }

                &__img {
                    width: 0.875rem;
                    height: 0.875rem;
                    flex-shrink: 0;

                    @media (max-width: 768px) {
                        width: 1.87rem;
                        height: 1.87rem;
                    }
                }

                &__text {
                    font-size: 0.9375rem;
                    font-weight: 300;
                    line-height: 130%;

                    @media (max-width: 768px) {
                        font-size: 3.46667rem;
                    }
                }
            }
        }
    }

    .scroll {
        position: relative;
        width: 100%;

        .card-4 {
            position: relative;
        }

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;
            height: 100vh;
            background-position: bottom;
            background-size: 100%;
            background-repeat: no-repeat;

            @media (max-width: 768px) {
                background-position: center;
                background-size: 100%;
            }

            &.active {
                background-attachment: fixed;
                height: 100%;

                @media (max-width: 768px) {
                    height: 100%;
                    background-size: 100%;
                    position: fixed;
                    background-attachment: unset;
                }

                &.remove-active {
                    background-attachment: unset;
                    background-position: 0 500vh;
                    background-size: 100%;

                    @media (max-width: 768px) {
                        background-position: bottom;
                        position: absolute;
                        background-size: 100%;
                    }
                }
            }

            &.bg-1 {
                z-index: 4;
                background-image: url(../../public/img/pasvik-1.jpg);
            }

            &.bg-2 {
                z-index: 3;
                background-image: url(../../public/img/pasvik-2.jpg);
            }

            &.bg-3 {
                z-index: 2;
                background-image: url(../../public/img/pasvik-3.jpg);
            }

            &.bg-4 {
                z-index: 1;
                background-image: url(../../public/img/pasvik-4.jpg);
            }

            @media (max-width: 768px) {
                &.bg-1 {
                    background-image: url(../../public/img/pasvik-1-mobile.jpg);
                }

                &.bg-2 {
                    background-image: url(../../public/img/pasvik-2-mobile.jpg);
                }

                &.bg-3 {
                    background-image: url(../../public/img/pasvik-3-mobile.jpg);
                }

                &.bg-4 {
                    background-image: url(../../public/img/pasvik-4-mobile.jpg);
                }
            }
        }

        &-wrap {
            position: relative;
            z-index: 10;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 1.25rem;
        }

        &-card {
            padding: 1.5rem;
            margin-bottom: 100vh;
            width: 38rem;
            border-radius: 0.25rem;
            background: #FFF;

            &:first-child {
                margin-top: 50vh;
            }

            @media (max-width: 768px) {
                padding: 4.2666rem;
                margin: 0 auto 100vh auto;
                width: 93%;

                &:first-child {
                    margin-top: 100vh;
                }
            }

            p {
                font-size: 1.25rem;
                font-weight: 300;
                line-height: 130%;

                @media (max-width: 768px) {
                    font-size: 4.53334rem;
                }

                &:first-child {
                    margin-bottom: 1.56rem;
                    font-size: 1.25rem;
                    font-weight: 600;

                    @media (max-width: 768px) {
                        margin-bottom: 6.6667rem;
                        font-size: 4.8rem;
                    }
                }
            }

            .dropdown-info {
                margin-top: 1.88rem;

                @media (max-width: 768px) {
                    margin-top: 7.4666rem;
                }

                &__title {
                    font-size: 1.25rem;

                    @media (max-width: 768px) {
                        font-size: 4.8rem;
                    }
                }

                &__text {
                    background: #F7F6F2;
                    width: 27rem;
                    font-size: 1.04167rem;
                    padding: 3.5rem 1.4375rem 1.5625rem 3.375rem;

                    @media (max-width: 768px) {
                        left: -4.3rem;
                        top: -4.3rem;
                        width: 91.734rem;
                        font-size: 3.73334rem;
                        padding: 14.9334rem 6.1333rem 6.6666rem 13.8666rem;
                    }
                }

                ul {
                    list-style-type: disc;

                    li {
                        padding-left: 0.94rem;
                    }

                    li:not(:last-child) {
                        margin-bottom: 0.8rem;

                        @media (max-width: 768px) {
                            margin-bottom: 3.2rem;
                        }
                    }
                }
            }
        }
    }

    .transbaikal-radius {
        position: relative;

        &__descr {
            margin-top: 0.5rem;
            display: flex;
            justify-content: flex-end;
            gap: 0.31rem;
            padding: 0 1.44rem;
            font-size: 0.8125rem;
            font-weight: 300;
            line-height: 130%;
            color: var(--Gray-3, #828282);

            @media (max-width: 768px) {
                font-size: 3rem;
                padding: 0 4rem;
                margin-top: 2rem;
            }

            span:last-child {
                width: 22.875rem;

                @media (max-width: 768px) {
                    width: 100%;
                }
            }
        }

        &__wrap {
            position: absolute;
            top: 3.54166rem;
            right: 10.2083rem;
            padding: 0.69445rem;
            width: 16rem;
            font-size: 1.04167rem;
            line-height: 130%;
            display: flex;
            flex-direction: column;

            @media (max-width: 768px) {
                font-size: 4rem;
                padding: 0;
                width: 56rem;
                align-items: center;
                text-align: center;
                right: 22rem;
            }

            &-top {
                font-weight: 600;
            }

            &-mid {
                font-weight: 400;
            }
        }

        .clue__hidden {
            left: -13rem;
            width: 38rem;

            @media (max-width: 768px) {
                left: 0;
                width: unset;
            }
        }
    }

    .transbaikal-factors {
        display: flex;
        gap: 1.75rem;
        margin-left: auto;
        margin-right: auto;
        width: 75.0625rem;
        padding: 3.13rem 3rem 3.88rem 3rem;
        border-radius: 19px;
        border: 1px solid var(--grey, #626261);

        @media (max-width: 768px) {
            width: unset;
            padding: 5rem;
            gap: 6.4rem;
            flex-direction: column;
            margin-left: 9.5rem;
            margin-right: 9.5rem;
        }

        &__left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (max-width: 768px) {
                gap: 6.4rem;
            }
        }

        &__img-grass {
            margin-bottom: 0.63rem;
            width: 17.5625rem;
            height: 9.6875rem;
            bottom: 4rem;
            left: 2rem;
            @media (max-width: 768px) {
                width: 50%;
                height: auto;
                margin-bottom: 0;
            }
        }

        &__descr {
            width: 17.0625rem;
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 130%;
            flex-shrink: 0;

            @media (max-width: 768px) {
                width: unset;
                font-size: 4.8rem;
            }
        }

        &__items {
            display: flex;
            gap: 5.94rem 2.87rem;
            flex-wrap: wrap;

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 6.4rem;
            }
        }

        &__item {
            width: 19.875rem;
            display: flex;
            flex-direction: column;
            gap: 1.19rem;
            font-size: 1.125rem;
            font-weight: 300;

            @media (max-width: 768px) {
                width: unset;
                font-size: 4.8rem;
                line-height: 130%;
                gap: 5rem;
            }

            &-title {
                padding: 0.4375rem 0.625rem;
                border-radius: 27px;
                background: var(--copper, #D38235);
                color: var(--white, #FFF);
                width: max-content;

                @media (max-width: 768px) {
                    padding: 0.5rem 2rem;
                }
            }

            &-text {
                line-height: 130%;
            }
        }
    }

    .section-orange {
        margin-bottom: 0;

        &__title {
            color: var(--copper, #D38235);
            margin-bottom: 2.19rem;
            font-size: 2.125rem;
            font-weight: 600;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 7.4667rem;
                margin-bottom: 6.4rem;
            }
        }

        .text {
            width: 38.25rem;
            text-align: center;

            @media (max-width: 768px) {
                width: unset;
                text-align: start;
            }
        }

        .mb-60-m.mb-60 {
            @media (max-width: 768px) {
                margin-bottom: 10.6667rem;
            }
        }

        .number-block {
            position: static;
        }

        .sidebar__text {
            padding: 0;
        }
    }

    .flora-and-fauna {
        margin: 0 auto;
        width: 68.5rem;
        margin-bottom: 8.69rem;

        @media (max-width: 768px) {
            margin-bottom: 16rem;
            width: unset;
        }

        &__wrap {
            display: flex;
            gap: 4.44rem;

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 10.6667rem;
            }
        }

        .wrap-numbers {
            display: flex;
            gap: 2.88rem;
            margin-top: 2.88rem;

            @media (max-width: 768px) {
                margin-top: 0;
                gap: 0;
                padding: 0 9.5rem;
                flex-direction: column;
            }
        }

        .text-block__wrap {
            margin: 0;
            width: 32.375rem;

            @media (max-width: 768px) {

                &:first-child,
                &:last-child {
                    width: 100%;
                }
            }
        }
    }

    .legend {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2.28rem;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            padding-left: 9.6rem;
            gap: 2rem;
            margin-bottom: 8.8rem
        }

        .block-info {
            position: static;
        }
    }

    .collage {
        position: relative;
        height: 339rem;
        width: 100%;
        background-image: url(../../public/img/kola-collage.webp);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 11;

        @supports (background-image: url(../../public/img/kola-collage.webp)) {
            background-image: url(../../public/img/kola-collage.webp);
        }

        @media (max-width: 768px) {
            height: 797rem;
            background-image: url(../../public/img/kola-collage-mobile.webp);

            @supports (background-image: url(../../public/img/kola-collage-mobile.webp)) {
                background-image: url(../../public/img/kola-collage-mobile.webp);
            }
        }

        &__video {
            width: 100%;
            position: absolute;
            right: -0.1rem;
        }


        .dropdown-info__text {
            @media (max-width: 768px) {
                width: 93.34rem;
                padding: 10.6667rem 5.3333rem 3.7333rem 12.2666rem;
            }
        }

        .background {
            position: absolute;
            background-color: #fff;
            opacity: 0.8;
            mix-blend-mode: hard-light;
            filter: blur(30px);

            @media (max-width: 768px) {
                filter: blur(11px);
            }

            &-1 {
                bottom: 64rem;
                left: 8.5rem;
                width: 19.25rem;
                height: 8.417rem;
                transform: rotate(13.682deg);

                @media (max-width: 768px) {
                    display: none;
                }
            }

            &-2 {
                bottom: 37rem;
                left: 31rem;
                width: 9rem;
                height: 5rem;

                @media (max-width: 768px) {
                    bottom: 101rem;
                    left: 8rem;
                    width: 45rem;
                    height: 14rem;
                }
            }

            &-3 {
                bottom: 33rem;
                right: 45rem;
                width: 10rem;
                height: 5rem;

                @media (max-width: 768px) {
                    bottom: 89rem;
                    right: 8rem;
                    width: 35rem;
                    height: 5rem;
                }
            }

            &-4 {
                bottom: 21rem;
                left: 18rem;
                width: 13rem;
                height: 7rem;

                @media (max-width: 768px) {
                    bottom: 74rem;
                    left: 33rem;
                    width: 30rem;
                    height: 6rem;
                }
            }

            &-5 {
                bottom: 12rem;
                left: 45rem;
                width: 13rem;
                height: 1rem;

                @media (max-width: 768px) {
                    bottom: 54rem;
                    left: 18rem;
                    width: 25rem;
                    height: 9rem;
                }
            }

            &-6 {
                bottom: 34rem;
                right: 15rem;
                width: 12rem;
                height: 4rem;

                @media (max-width: 768px) {
                    bottom: 52rem;
                    right: 10rem;
                    width: 31rem;
                    height: 14rem;
                }
            }
        }

        video {
            position: absolute;
            width: 100%;
        }

        &__redstart {
            position: absolute;
            width: 11rem;
            top: 103rem;
            left: 34.7rem;

            @media (max-width: 768px) {
                width: 16rem;
                top: 173.3rem;
                left: 27.7rem;
            }

            video {
                top: -4rem;
                left: -3.6rem;
                width: 165%;

                @media (max-width: 768px) {
                    top: -6rem;
                    left: -5.6rem;
                }
            }
        }

        &__warbler {
            position: absolute;
            width: 15rem;
            top: 117.3rem;
            left: 5.6rem;
            display: flex;
            transform: scaleX(-1) rotate(-11deg);

            picture {
                width: 62.5%;
            }

            video {
                top: -2rem;
                left: -2.9rem;
            }

            @media (max-width: 768px) {
                width: 22rem;
                top: 94rem;
                left: 17rem;
                transform: scaleX(1);
            }
        }

        &__spider {
            position: absolute;
            width: 11rem;
            top: 120.7rem;
            left: 27.4rem;
            display: flex;

            picture {
                width: 62.5%;
            }

            video {
                top: -3.4rem;
                left: -3.2rem;
                transform: rotate(135deg);
            }

            @media (max-width: 768px) {
                position: absolute;
                width: 15rem;
                top: 547.7rem;
                left: 71.5rem;
                transform: scaleX(-1);
            }
        }

        &__bug {
            position: absolute;
            width: 9rem;
            top: 133rem;
            left: 16.4rem;
            display: flex;

            picture {
                transform: rotate(-93deg);
                width: 56.5%;
            }

            video {
                transform: rotate(-53deg);
                top: -1.9rem;
                left: -2.4rem;

                @media (max-width: 768px) {
                    transform: rotate(-30deg);
                }
            }

            @media (max-width: 768px) {
                width: 15rem;
                top: 216rem;
                left: -1.5rem;
            }
        }

        &__ant {
            position: absolute;
            width: 12rem;
            top: 175rem;
            left: 63.4rem;
            display: flex;

            picture {
                width: 69.5%;
            }

            video {
                transform: rotate(-70deg);
                top: -4rem;
                left: -1.9rem;

                @media (max-width: 768px) {
                    transform: rotate(-30deg);
                }
            }

            @media (max-width: 768px) {
                width: 18rem;
                top: 578rem;
                left: 83rem;
            }
        }

        &__shrew {
            position: absolute;
            width: 27rem;
            top: 181rem;
            left: 80.2rem;
            display: flex;

            picture {
                width: 53.5%;
            }

            video {
                top: -2rem;
                left: -6.4rem;
                transform: rotate(-6deg);
            }

            @media (max-width: 768px) {
                width: 36rem;
                top: 460rem;
                left: 1.2rem;
                transform: scaleX(-1);
            }
        }

        &__mouse {
            position: absolute;
            width: 20rem;
            top: 252rem;
            left: 3rem;
            display: flex;

            picture {
                width: 53.5%;
            }

            video {
                top: -3.5rem;
                left: -4.9rem;
                transform: scaleX(-1) rotate(17deg);
            }

            @media (max-width: 768px) {
                width: 28rem;
                top: 511rem;
                left: 7rem;
            }
        }

        &__marten {
            position: absolute;
            width: 43rem;
            top: 220rem;
            left: 2rem;
            display: flex;

            picture {
                width: 75.5%;
            }

            video {
                top: 1rem;
                left: -6rem;
            }

            @media (max-width: 768px) {
                width: 60rem;
                top: 411rem;
                left: -5rem;
            }
        }

        &__fox {
            position: absolute;
            width: 42rem;
            top: 187rem;
            left: 22.2rem;

            video {
                top: -5.5rem;
                left: -2.4rem;
                width: 108%;

                @media (max-width: 768px) {
                    top: -12rem;
                    left: -8.4rem;
                }
            }

            @media (max-width: 768px) {
                width: 64rem;
                top: 341rem;
                left: 26.2rem;
            }
        }

        &__yurok {
            position: absolute;
            width: 22rem;
            top: 111.5rem;
            left: 67rem;

            video {
                top: 2.3rem;
                left: -0.2rem;
                width: 106%;
                transform: scaleX(-1) rotate(-23deg);

                @media (max-width: 768px) {
                    top: 3.3rem;
                }
            }

            @media (max-width: 768px) {
                width: 29rem;
                top: 227rem;
                left: 60rem;
            }
        }

        &__elk {
            position: absolute;
            width: 77rem;
            top: 114.5rem;
            left: 0rem;

            @media (max-width: 768px) {
                transform: scaleX(-1);
                top: 249.5rem;
                left: -7rem;
                width: 97rem;
            }

            video {
                top: 10.3rem;
                left: 3rem;
                width: 90%;

                @media (max-width: 768px) {
                    top: 10.3rem;
                    left: -1rem;
                }
            }
        }

        &__squirrel {
            position: absolute;
            width: 15rem;
            top: 89rem;
            left: 17rem;

            @media (max-width: 768px) {
                transform: scaleX(-1);
                top: 446rem;
                left: 66rem;
                width: 22rem;
            }

            video {
                top: -2rem;
                left: -4rem;
                width: 145%;
                transform: rotate(-12deg);

                @media (max-width: 768px) {
                    top: -4rem;
                    left: -6rem;
                    width: 150%;
                }
            }
        }

        &__white-browed {
            position: absolute;
            width: 31rem;
            top: 40rem;
            left: 59rem;

            @media (max-width: 768px) {
                width: 42rem;
                top: 81rem;
                left: 54rem;
            }

            video {
                top: -3rem;
                width: 106%;
            }
        }

        &__golden-eagle {
            position: absolute;
            width: 54rem;
            top: 1rem;
            left: 45rem;

            @media (max-width: 768px) {
                width: 82rem;
                top: 16rem;
                left: -8rem;
                transform: scaleX(-1);
            }

            video {
                top: 2.6rem;
                left: 6rem;
                width: 80%;

                @media (max-width: 768px) {
                    top: -8rem;
                }
            }
        }

        &__bluethroat {
            position: absolute;
            width: 23.9rem;
            top: 80.5rem;
            left: 62.5rem;

            @media (max-width: 768px) {
                width: 34rem;
                top: 25rem;
                left: 58rem;
            }

            video {
                transform: scaleX(-1);
                left: -5rem;
                width: 117%;
                top: -6rem;

                @media (max-width: 768px) {
                    left: -8rem;
                    top: -8rem;
                }
            }
        }

        &__tap-dance {
            position: absolute;
            width: 15rem;
            top: 60.5rem;
            left: 39rem;

            @media (max-width: 768px) {
                width: 22rem;
                top: 126.5rem;
                left: 35rem;
            }

            video {
                transform: scaleX(-1) rotate(-16deg);
                width: 119%;
                left: -0.5rem;
                top: -1.9rem;

                @media (max-width: 768px) {
                    width: 121%;
                    top: -2.9rem;
                }
            }
        }

        &__swan {
            position: absolute;
            width: 41.3rem;
            top: 261.5rem;
            left: 3.6rem;

            @media (max-width: 768px) {
                width: 64.3rem;
                top: 621.5rem;
                left: -8.4rem;
            }
        }

        &__broody {
            position: absolute;
            width: 28rem;
            top: 254.3rem;
            left: 62.7rem;

            video {
                width: 93%;
                top: 1.6rem;
                left: 0.2rem;
            }

            @media (max-width: 768px) {
                width: 39rem;
                top: 615.3rem;
            }
        }

        &__grayling {
            position: absolute;
            width: 34.7223rem;
            top: 299.7rem;
            left: 63.7rem;

            video {
                width: 66%;
                top: 2.7rem;
                left: 6.7rem;
                transform: rotate(-9deg);

                @media (max-width: 768px) {
                    top: 6rem;
                    left: 5rem;
                }
            }

            @media (max-width: 768px) {
                top: 736.7rem;
                width: 54.7223rem;
                left: 41.7rem;
            }
        }

        &__char {
            position: absolute;
            width: 32rem;
            top: 307.3rem;
            left: 35.7rem;

            video {
                width: 98%;
                top: -2.6rem;
                left: 0.6rem;
                transform: rotate(10deg);
            }

            @media (max-width: 768px) {
                top: 738.7rem;
                width: 46.7223rem;
                left: 2.7rem;
            }
        }

        &__watercolor {
            position: absolute;
            top: 5rem;
            left: 63rem;
            width: 35rem;
            // opacity: 0.64;
            mix-blend-mode: multiply;

            @media (max-width: 768px) {
                top: 29rem;
                left: 1rem;
                width: 59rem;
            }
        }

        &__watercolor-2 {
            position: absolute;
            top: 82rem;
            left: 65rem;
            width: 38rem;
            // opacity: 0.64;
            mix-blend-mode: multiply;

            @media (max-width: 768px) {
                display: none;
            }
        }

        &__watercolor-3 {
            position: absolute;
            top: 117rem;
            left: 20rem;
            width: 64rem;
            // opacity: 0.64;
            mix-blend-mode: multiply;

            @media (max-width: 768px) {
                top: 246rem;
                left: -20rem;
                width: 85rem;
            }
        }

        .golden-eagle {
            position: absolute;
            top: 23rem;
            right: 35rem;

            @media (max-width: 768px) {
                top: 69rem;
                left: 7.5rem;
                right: 0;
            }
        }

        .belobrovik {
            width: 15rem;
            top: 47rem;
            right: 5.07rem;

            @media (max-width: 768px) {
                display: none;
            }

            &_mobile {
                display: none;
                position: absolute;
                top: 111rem;
                right: 17rem;

                @media (max-width: 768px) {
                    display: block;
                }
            }
        }

        .tap-dancing {
            width: 13rem;
            top: 64.7rem;
            left: 52.8rem;

            @media (max-width: 768px) {
                width: 35rem;
                top: 135rem;
                left: 55rem;
            }
        }

        .bluethroat {
            width: 13rem;
            top: 82rem;
            left: 57rem;

            @media (max-width: 768px) {
                width: 42rem;
                top: 0rem;
                left: unset;
                right: 2rem;
            }
        }

        .redstart-coot {
            width: 13rem;
            top: 103.7rem;
            left: 46rem;

            @media (max-width: 768px) {
                width: 45rem;
                top: 185.2rem;
                left: 25rem;
            }
        }

        .yurok {
            width: 10rem;
            top: 117rem;
            right: 23.7rem;

            @media (max-width: 768px) {
                top: 231rem;
                right: 23.7rem;
            }
        }

        .warbler {
            width: 10rem;
            top: 115.5rem;
            left: 5.5rem;

            @media (max-width: 768px) {
                top: 94rem;
                left: 32rem;
            }
        }

        .ground-beetles {
            position: absolute;
            top: 135rem;
            left: 7.5rem;

            @media (max-width: 768px) {
                top: 220rem;
                left: 12rem;
            }
        }

        .spiders {
            position: absolute;
            top: 121.5rem;
            left: 33rem;

            @media (max-width: 768px) {
                top: unset;
                bottom: 237rem;
                left: unset;
                right: 26rem;
            }
        }

        .ants {
            position: absolute;
            top: 180rem;
            right: 28rem;

            @media (max-width: 768px) {
                top: unset;
                bottom: 206rem;
                right: 16rem;
            }
        }

        .shrew {
            position: absolute;
            bottom: 145rem;
            right: 11.5rem;

            @media (max-width: 768px) {
                bottom: 333rem;
                right: unset;
                left: 12rem;
            }
        }

        .kokushnik {
            bottom: 138rem;
            left: 17rem;

            @media (max-width: 768px) {
                bottom: 402rem;
                left: 6rem;
            }
        }

        .fingerroot {
            position: absolute;
            bottom: 134rem;
            right: 8rem;

            .dropdown-info__text {
                padding: 3.56rem 0.62rem 0.88rem 3.13rem;
            }

            @media (max-width: 768px) {
                bottom: 387rem;
                right: 26rem;
            }
        }

        .ledum {
            bottom: 94rem;
            left: 25rem;

            @media (max-width: 768px) {
                bottom: 235rem;
                left: 8rem;
            }
        }

        .vole {
            width: 10rem;
            bottom: 87rem;
            left: 5rem;

            @media (max-width: 768px) {
                width: 28rem;
                bottom: 285rem;
                left: 4rem;
            }
        }

        .swan {
            width: 17rem;
            bottom: 62rem;
            left: 10.5rem;

            @media (max-width: 768px) {
                width: 56rem;
                bottom: 167rem;
                left: 6rem;
            }
        }

        .broody {
            position: absolute;
            bottom: 62.7rem;
            right: 31.5rem;

            @media (max-width: 768px) {
                bottom: 123rem;
                right: 13rem;
            }
        }

        .zooplankton {
            position: absolute;
            bottom: 39rem;
            left: 30rem;

            @media (max-width: 768px) {
                bottom: 74rem;
                left: 32rem;
            }
        }

        .phytoplankton {
            position: absolute;
            bottom: 34rem;
            right: 45rem;

            @media (max-width: 768px) {
                bottom: 90rem;
                right: 21rem;
            }
        }

        .tubifex {
            bottom: 21.5rem;
            left: 17rem;
            width: 14.5rem;

            @media (max-width: 768px) {
                bottom: 96rem;
                left: 5rem;
                width: 52rem;
            }
        }

        .loach {
            bottom: 12.5rem;
            left: 46rem;
            position: absolute;

            @media (max-width: 768px) {
                bottom: 53rem;
                left: 13rem;
            }

        }

        .grayling {
            bottom: 33rem;
            right: 15rem;
            width: 12rem;

            @media (max-width: 768px) {
                bottom: 50rem;
                right: 6rem;
                width: 40rem;
            }
        }
    }

    .habitat-map {
        position: relative;
        margin-bottom: 4.5rem;
        height: 40rem;

        @media (max-width: 768px) {
            height: unset;
        }

        &__wrap {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            @media (max-width: 768px) {
                position: static;
                transform: translateX(0);
                margin-bottom: 5.867rem;
                padding: 0 5rem;
            }
        }

        &__title {
            margin-bottom: 1.63rem;
            font-size: 1.375rem;
            font-weight: 600;
            line-height: 130%;
            text-align: center;

            @media (max-width: 768px) {
                text-align: start;
                width: 52.5rem;
                margin-bottom: 7.75rem;
                font-size: 4.8rem
            }
        }

        &__buttons {
            display: flex;
            gap: 1rem;

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 2rem;
            }
        }

        &__button {
            cursor: pointer;
            padding: 0.8125rem 1.25rem;
            border-radius: 3.125rem;
            border: 1px solid var(--sea, #21285C);
            font-size: 1.25rem;
            color: #21285C;
            line-height: 130%;
            opacity: 0.4;
            transition: .2s;

            @media (max-width: 768px) {
                padding: 4rem 6rem;
                border-radius: 8.125rem;
                font-size: 4.53334rem;
                text-align: center;
            }

            &_active {
                opacity: 1;
            }

            &:hover {
                color: var(--copper, #D38235);
                border-color: #D38235;
                opacity: 1;
            }
        }

        &__legend {
            position: absolute;
            left: 7.37rem;
            top: 10.19rem;
            width: 12.4rem;

            @media (max-width: 768px) {
                display: none;
            }

            &-title {
                margin-bottom: 1rem;
                font-size: 0.9375rem;
                font-weight: 700;
                line-height: 130%;
            }

            &-text {
                font-size: 0.9375rem;
                font-weight: 300;
                line-height: 130%;
            }

            &-top {
                margin-bottom: 2.81rem;

                &-item {
                    display: flex;
                    gap: 0.56rem;
                    align-items: center;

                    &:not(:last-child) {
                        margin-bottom: 0.62rem;
                    }

                    svg {
                        width: 0.875rem;
                        height: 0.875rem;
                    }
                }
            }

            &-bottom {
                &-item {
                    &:not(:last-child) {
                        margin-bottom: 0.69rem;
                    }
                }
            }
        }

        &__list {
            display: none;
            flex-wrap: wrap;
            row-gap: 6rem;
            margin-bottom: 3rem;

            @media (max-width: 768px) {
                display: flex;
            }

            &-item {
                display: flex;
                margin-bottom: 2rem;
                align-items: center;
                gap: 1rem;
                font-size: 4.2042rem;
                line-height: 130%;

                &-wrap {
                    width: 50%;
                }

                svg {
                    width: 3.7333rem;
                    height: 3.7333rem;
                }
            }

            &-list {
                margin-left: 4rem;
                padding-left: 4rem;
                font-size: 3.6036rem;
                font-weight: 300;
                line-height: 130%;

                li {
                    list-style-type: disc;

                    &:not(:last-child) {
                        margin-bottom: 2.66rem;
                    }
                }
            }
        }

        &__river,
        &__name-sea {
            position: absolute;
            color: var(--blue-deep, #004C97);
            font-size: 0.57rem;
            font-weight: 300;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 2.4rem;
            }
        }

        &__name-sea {
            text-align: center;
            top: 14rem;
            right: 35rem;

            @media (max-width: 768px) {
                top: unset;
                right: 9rem;
                bottom: 53rem;
            }
        }

        &__river {
            bottom: 24rem;
            right: 49.5rem;

            @media (max-width: 768px) {
                bottom: 29.5rem;
                right: 44.5rem;
            }
        }

        &__city {
            position: absolute;
            width: max-content;
            display: flex;
            align-items: center;
            font-size: 0.9375rem;
            font-weight: 400;
            line-height: 130%;
            gap: 0.31rem;

            @media (max-width: 768px) {
                font-size: 3.2rem;
                gap: 1rem;
            }

            &-circale {
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 100%;
                transition-timing-function: ease-in;
                transition-duration: 0.7s;
                transition: all 2.3s;
                z-index: 2;
                background-color: rgba(#333, 1);
                flex-shrink: 0;


                @media (min-width: 769px) {
                    width: 0.375rem;
                    height: 0.375rem;
                    animation: ripple-black 0.9s infinite;
                }
            }

            &-1 {
                top: 12.5rem;
                left: 43rem;

                @media (max-width: 768px) {
                    top: unset;
                    bottom: 58rem;
                    left: 40rem;
                }
            }

            &-2 {
                display: flex;
                top: 16rem;
                left: 32rem;
                flex-direction: row-reverse;

                @media (max-width: 768px) {
                    top: unset;
                    bottom: 53rem;
                    left: 9rem;
                }
            }

            &-3 {
                top: 16.4rem;
                left: 37.5rem;

                @media (max-width: 768px) {
                    top: unset;
                    bottom: 53rem;
                    left: 26rem;

                }
            }

            &-4 {
                top: 25rem;
                right: 36.5rem;

                @media (max-width: 768px) {
                    top: unset;
                    right: 8rem;
                    bottom: 28rem;
                }
            }
        }
    }

    &__shrew {
        .position-info {
            position: absolute;
            bottom: 0;
            right: -29rem;
            width: 21rem;

            @media (max-width: 768px) {
                width: unset;
                position: relative;
                right: 0;
                top: 11rem;
            }

            img {
                position: absolute;
                top: -9.5rem;
                left: -3rem;
                width: 11rem;

                @media (max-width: 768px) {
                    top: 12rem;
                    left: 26rem;
                    width: 27rem;
                }
            }

            .dropdown-info {
                position: absolute;
                top: -4.3rem;
                left: 4.8rem;

                @media (max-width: 768px) {
                    position: relative;
                    top: unset;
                    left: unset;
                }
            }

            .dropdown-info__text {
                @media (max-width: 768px) {
                    top: -4rem;
                    left: -4rem;
                    width: 83.6rem;
                    padding: 15rem 5rem 5rem 5rem;
                }
            }

            &__descr {
                @media (max-width: 768px) {
                    font-size: 4.53334rem;
                    padding-left: 5.3334rem;
                    margin-bottom: 5.3334rem;
                    width: 85%;
                    border-left: 1px solid #333;
                }
            }
        }
    }

    .text-block__wrap a {
        text-decoration: 0.5px underline;
        text-decoration-skip-ink: none;
        text-underline-offset: 0.2rem;
    }

    .modernization {
        width: 83.4rem;
        margin: 0 auto;
        margin-bottom: 6.25rem;

        @media (max-width: 768px) {
            width: unset;
            padding: 0 9.5rem;
            margin-bottom: 6.25rem;
        }

        &__item {
            &:not(:last-child) {
                margin-bottom: 2.09rem;

                @media (max-width: 768px) {
                    margin-bottom: 7.4667rem;
                }
            }

            &-top {
                margin-bottom: 1.31945rem;
                font-size: 1.52778rem;
                font-weight: 600;
                line-height: 130%;

                @media (max-width: 768px) {
                    margin-bottom: 4.2667rem;
                    font-size: 3.2rem;
                }
            }

            &-mid {
                margin-bottom: 1.3rem;
                position: relative;
                height: 1px;
                width: 100%;
                background-color: #333;

                @media (max-width: 768px) {
                    margin-bottom: 3.7334rem;
                }
            }

            &-bottom {
                width: 19rem;
                font-size: 1.25rem;
                font-weight: 300;
                line-height: 130%;

                @media (max-width: 768px) {
                    width: 42rem;
                    font-size: 3.2rem;
                }
            }

            &:last-child {
                .modernization__mid-bg {
                    width: 8.34rem;
                }

                .modernization__mid-text_1 {
                    right: 5rem;
                }

                .modernization__mid-text_2 {
                    right: 1rem;

                    @media (max-width: 768px) {
                        right: 0;
                    }
                }

                .modernization__item-bottom {
                    width: 22rem;

                    @media (max-width: 768px) {
                        width: 37rem;
                    }
                }
            }
        }

        &__mid-text_1 {
            position: absolute;
            top: -3rem;
            right: 21rem;
            font-size: 1.52778rem;
            font-weight: 600;
            line-height: 130%;

            @media (max-width: 768px) {
                top: -8rem;
                right: 20rem;
                font-size: 3.2rem;
            }
        }

        &__mid-text_2 {
            position: absolute;
            top: 1rem;
            right: 18.5rem;
            font-size: 2.77778rem;
            font-weight: 600;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 10.66667rem;
                top: 3rem;
                right: 0;

                span {
                    font-size: 4.8rem;
                }
            }

            svg {
                width: 1.25rem;
                height: 1.9445rem;

                @media (max-width: 768px) {
                    width: 4.8rem;
                    height: 7.467rem;
                }
            }
        }

        &__mid-bg {
            position: absolute;
            top: -0.5rem;
            right: 0;
            height: 1.1112rem;
            width: 25rem;
            background-color: #CEEDFF;

            @media (max-width: 768px) {
                height: 4.267rem;
                top: -2rem;
            }
        }
    }

    &__button {
        position: relative;
        z-index: 2;
        padding: 0 17.79rem;

        @media (max-width: 768px) {
            padding: 0 9.5rem;
        }

        a {
            padding: 0.81rem 1.69rem;
            border-radius: 50px;
            border: 1px solid var(--sea, #21285C);
            font-size: 1.125rem;
            font-weight: 300;
            line-height: 130%;
            text-align: center;
            transition: all .2s;

            &:hover {
                border: 1px solid var(--copper, #D38235);
                background: var(--copper, #D38235);
                color: #fff;
                text-decoration-line: underline;
                text-decoration-skip-ink: none;
                text-underline-offset: 0.2rem;
            }

            @media (max-width: 768px) {
                display: block;
                width: 100%;
                font-size: 4.8rem;
                padding: 2rem 2rem;
            }
        }
    }

    &__new-ward {
        position: relative;
        border-radius: 19px;
        border: 1px solid var(--grey, #626261);
        width: 67.09rem;
        padding: 4.378rem 3.34rem 2.777rem 19rem;
        margin: 0 auto;
        display: flex;
        gap: 1.2rem;

        @media (max-width: 768px) {
            width: unset;
            margin: 0 9.5rem;
            padding: 14rem 3.76rem 7.47rem 3.76rem;
            flex-direction: column;
            align-items: center;
            margin-bottom: 6rem !important;
        }

        &-title {
            font-size: 1.527778rem;
            font-weight: 600;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 4.8rem;
                text-align: center;
                width: 54rem;
            }
        }

        &-descr {
            width: 32.278rem;
            font-size: 1.25rem;
            font-weight: 300;
            line-height: 130%;
            flex-shrink: 0;

            @media (max-width: 768px) {
                width: unset;
                font-size: 4.53334rem;
                text-align: center;
            }
        }

        video {
            position: absolute;
            top: -3.5rem;
            left: -19.366rem;
            width: 57.25rem;
            z-index: 2;

            @media (max-width: 768px) {
                width: 93.5rem;
                left: -7.5rem;
                top: -28.5rem;
            }
        }

        &-img {
            position: absolute;
            display: block;
            width: 35.25rem;
            height: auto;
            top: 5.5rem;
            left: -7.366rem;
            z-index: 1;

            @media (max-width: 768px) {
                width: 71.5rem;
                left: 5.5rem;
                top: -14.5rem;
            }
        }

        &-bg {
            position: absolute;
            display: block;
            width: 26rem;
            height: auto;
            top: 0.5rem;
            left: -6.366rem;
            z-index: 0;

            @media (max-width: 768px) {
                width: 60rem;
                top: -25.5rem;
                left: 6.634rem;
            }
        }
    }
}
</style>