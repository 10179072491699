<template>
    <div class="art">
        <BaseHero title="Explorers and Artists: Art on&nbsp;an&nbsp;Expedition" :descr="heroDescr"
            :img="'img/hero10.jpg'" :imgMobile="'img/hero10-mobile.jpg'" />
        <MainSection>
            <section class="section-white">
                <BaseTextBlock class="mb-40">
                    <div class="art__position-info-open">
                        Discovering <br/>the Arctic together
                    </div>
                    <span>
                        <p class="mb-10">
                            All aspects of&nbsp;the relationship between humans and the Arctic nature are showcased in&nbsp;the large-scale exhibition project &laquo;Explorers and Artists: Art on&nbsp;an&nbsp;Expedition&raquo;, which was created with the support of&nbsp;Norilsk Nickel.
                        </p>
                        <p>
                            The exhibition is&nbsp;timed to&nbsp;coincide with the completion of&nbsp;the second year of&nbsp;the Big Scientific Expedition, a&nbsp;joint project between Nornickel and the Siberian Branch of&nbsp;the Russian Academy of&nbsp;Sciences, with the aim of&nbsp;studying biodiversity. This study was unique in&nbsp;its scale. This is&nbsp;the first study of&nbsp;its size since the end of&nbsp;the Soviet period.
                        </p>
                    </span>
                </BaseTextBlock>

                <div class="art__youtube-video mb-100">
                    <iframe width="100%" height="100%" frameborder="0" allowfullscreen
                        :src="`https://www.youtube.com/embed/snEfjPiaNRg?modestbranding=0&controls=0&showinfo=0&rel=0&showinfo=0`">
                    </iframe>
                </div>

                <BaseTextBlock class="mb-100">
                    <span>
                        <p class="mb-10">
                            The exhibition project was created thanks to&nbsp;the joint work of&nbsp;Norilsk Nickel, the Siberian Branch of&nbsp;the Russian Academy of&nbsp;Sciences, Zaryadye Park, the State Darwin Museum, and the Stroganov Russian State University of&nbsp;Art and Industry.
                        </p>
                        <p class="mb-10">
                            The main goal of&nbsp;the project is&nbsp;to&nbsp;show the general public the cross-cultural connection between nature, mankind, science, and art.
                        </p>
                        <p>
                            Exhibits were kindly provided both by&nbsp;leading museums in&nbsp;Russia, and by&nbsp;private collectors. Visitors were able to&nbsp;enjoy artefacts from the time of&nbsp;Tsar Alexei Mikhailovich through to&nbsp;modern dramatic performances from members of&nbsp;the Krasnoyarsk, Yakutsk and Moscow Biennale of&nbsp;Contemporary Art.
                        </p>
                    </span>
                    <div class="position-info">
                        <BaseNumberBlock textTop="413" textBottom="Exhibits brought together as&nbsp;part of&nbsp;our exhibition project " />
                    </div>
                </BaseTextBlock>

                <div class="art__quote mb-100">
                    <div class="art__quote-quotation-mark"></div>
                    <div class="art__quote-text">
                        The symbiotic trio of&nbsp;science, art and culture is&nbsp;very important for our company. We&nbsp;want the results of&nbsp;our expeditions to&nbsp;be&nbsp;clear and understandable, and also to&nbsp;be&nbsp;accessible to&nbsp;as&nbsp;many people as&nbsp;possible. It&rsquo;s a&nbsp;wonderful thing that these objects can be&nbsp;brought together to&nbsp;show all the history of&nbsp;mankind&rsquo;s relationship with the Arctic.
                    </div>
                    <div class="art__quote-name">
                        Stanislav Seleznev
                    </div>
                    <div class="art__quote-status">
                        Vice President for Environmental and Industrial Safety, Nornickel
Participants
                    </div>
                    <picture>
                        <source type="image/webp" srcset="img/art/StanislavSeleznev.webp">
                        <img class="art__quote-img" src="img/art/StanislavSeleznev.png" alt="Stanislav Seleznev">
                    </picture>
                    <img class="art__quote-strip" src="img/strip.png" alt="">
                </div>

                <BaseTextBlock v-if="isMobile" class="mb-60" title="Participants">
                    <span>
                        The project is&nbsp;both interdisciplinary and cross-cultural. Art galleries, museums and nature reserves took part in&nbsp;both the art project and in&nbsp;the connected scientific and educational program.
                    </span>
                </BaseTextBlock>

                <ParticipantsSwiper class="mb-60" />

                <BaseTextBlock class="mb-40" :title="!isMobile ? 'Exhibition <br />in&nbsp;Zaryadye Park' : ''">
                    <div class="art__position-info-open">
                        Three <br />exhibitions
                    </div>
                    <div class="art__position-info-icon">
                        <img src="img/art/icon-1.png" alt="">
                    </div>

                    <div v-if="isMobile" class="art__position-info-title">
                        Exhibition <br />in&nbsp;Zaryadye Park
                    </div>

                    <span>
                        <p class="mb-10">
                            The Nature Embassy pavilion presents works from the Science-Art exhibition. This is&nbsp;an&nbsp;attempt by&nbsp;contemporary artists to&nbsp;explore ecosystems, and creatively rethink the problems of&nbsp;industrial areas, such as&nbsp;the state of&nbsp;water, and the fragile ecosystems of&nbsp;the Arctic lands in&nbsp;general.
                        </p>
                        <p class="mb-10">
                            The works refer to&nbsp;the primitive and pristine, reminding&nbsp;us of&nbsp;the harmony between mankind and nature. It&nbsp;makes&nbsp;us think about the mechanisms nature uses to&nbsp;adapt to&nbsp;the industrial environment.
                        </p>
                        <p class="mb-10">
                            The first expeditions to&nbsp;the Arctic were planned in&nbsp;the royal court of&nbsp;England many years ago. People were shown historical exhibits, such as&nbsp;engraved and handwritten maps, drawings, and travel diaries of&nbsp;scientists and artists, as&nbsp;well as&nbsp;ethnographic artefacts .
                        </p>
                        <p>
                            A&nbsp;special place in&nbsp;the exhibition was dedicated to&nbsp;the paintings of&nbsp;the first artist from beyond the Arctic Circle&nbsp;&mdash; Alexander Borisov. Sixteen original canvases were brought to&nbsp;Moscow from the Arkhangelsk Museum of&nbsp;Artistic Development of&nbsp;the Arctic.
                        </p>
                    </span>
                </BaseTextBlock>

                <div class="art__swiper-wrap">
                    <swiper class="art__swiper" :speed="1000" :navigation="true" :modules="modules" :loop="true"
                        :slides-per-view="1" :space-between="50">
                        <SwiperSlide :lazy="true">
                            <img src="../../public/img/art/swiper-1.jpg" loading="lazy" />
                        </SwiperSlide>
                        <SwiperSlide :lazy="true">
                            <img src="../../public/img/art/swiper-2.jpg" loading="lazy" />
                        </SwiperSlide>
                        <SwiperSlide :lazy="true">
                            <img src="../../public/img/art/swiper-3.jpg" loading="lazy" />
                        </SwiperSlide>
                        <SwiperSlide :lazy="true">
                            <img src="../../public/img/art/swiper-4.jpg" loading="lazy" />
                        </SwiperSlide>
                    </swiper>
                </div>

                <div class="art__exhibits mb-100">
                    <BaseNumberBlock textTop="239" textBottom="Exhibits" />

                    <div class="art__exhibits-list">
                        <div class="art__exhibits-item">
                            <picture>
                                <source type="image/webp" srcset="img/art/exhibit-1.webp">
                                <img loading="lazy" class="art__exhibits-img" src="img/art/exhibit-1.png" alt="">
                            </picture>
                            <p class="art__exhibits-text">
                                Folk  <br />art
                            </p>
                        </div>

                        <div class="art__exhibits-item">
                            <picture>
                                <source type="image/webp" srcset="img/art/exhibit-2.webp">
                                <img loading="lazy" class="art__exhibits-img" src="img/art/exhibit-2.png" alt="">
                            </picture>
                            <p class="art__exhibits-text">
                                Natural <br />Materials
                            </p>
                        </div>

                        <div class="art__exhibits-item">
                            <picture>
                                <source type="image/webp" srcset="img/art/exhibit-3.webp">
                                <img loading="lazy" class="art__exhibits-img" src="img/art/exhibit-3.png" alt="">
                            </picture>
                            <p class="art__exhibits-text">
                                Experimental  <br />techniques
                            </p>
                        </div>
                    </div>
                </div>

                <div class="art__quote mb-100">
                    <div class="art__quote-quotation-mark"></div>
                    <div class="art__quote-text">
                        Novaya Zemlya made a&nbsp;strong impression on&nbsp;me: its gloomy mountains and the eternal wanderers of&nbsp;the Arctic Ocean&nbsp;&mdash; polar ice.
                    </div>
                    <div class="art__quote-name">
                        Alexander Borisov
                    </div>
                    <div class="art__quote-status art__quote-status-AlexanderBorisov">
                        First artist from the Arctic Circle <br />(1866&ndash;1934)
                    </div>
                    <picture>
                        <source type="image/webp" srcset="img/art/AlexanderBorisov.webp">
                        <img loading="lazy" class="art__quote-img art__quote-img-AlexanderBorisov"
                            src="img/art/AlexanderBorisov.png" alt=" Alexander Borisov">
                    </picture>
                    <img class="art__quote-strip" src="img/strip.png" alt="">
                </div>

                <BaseTextBlock class="mb-40" :title="!isMobile ? 'Exhibition <br />at&nbsp;the Darwin Museum' : ''">
                    <div class="art__position-info-icon">
                        <img src="img/art/icon-2.png" alt="">
                    </div>
                    <div v-if="isMobile" class="art__position-info-title">
                        Exhibition <br />at&nbsp;the Darwin Museum
                    </div>
                    <span>
                        <p class="mb-10">
                            An&nbsp;exhibition of&nbsp;field diaries and drawings from the expeditions of&nbsp;famous scientists, zoologists, artists and ecologists who recorded unique biodiversity against the backdrop of&nbsp;climate change, took shape in&nbsp;the museum.
                        </p>
                        <p class="mb-10">
                            The focus is&nbsp;on&nbsp;the master of&nbsp;scientific and artistic illustration, sculptor Vasily Alekseevich Vatagin, widely known for his illustrations in&nbsp;books by&nbsp;Joseph Rudyard, Jack London and Vitaly Valentinovich Bianchi.
                        </p>
                        <p>
                            No&nbsp;less iconic a&nbsp;figure is&nbsp;the creator and traveller Nikolai Nikolaevich Kondakov, who, together with Otto Yulievich Schmidt, participated in&nbsp;the expedition to&nbsp;Severnaya Zemlya on&nbsp;the icebreaker Rusanov. His polar drawings reflect the Far North in&nbsp;the early 1940s. His subjects include polar bears, pink gulls, walrus with their young, and narwhal hunting.
                        </p>
                    </span>
                </BaseTextBlock>

                <div class="art__swiper-wrap">
                    <swiper class="art__swiper" :speed="1000" :navigation="true" :modules="modules" :loop="true"
                        :slides-per-view="1" :space-between="50">
                        <SwiperSlide :lazy="true">
                            <img src="../../public/img/art/swiper-5.jpg" loading="lazy" />
                        </SwiperSlide>
                        <SwiperSlide :lazy="true">
                            <img src="../../public/img/art/swiper-6.jpg" loading="lazy" />
                        </SwiperSlide>
                        <SwiperSlide :lazy="true">
                            <img src="../../public/img/art/swiper-7.jpg" loading="lazy" />
                        </SwiperSlide>
                        <SwiperSlide :lazy="true">
                            <img src="../../public/img/art/swiper-8.jpg" loading="lazy" />
                        </SwiperSlide>
                    </swiper>
                </div>

                <div class="art__exhibits mb-100">
                    <BaseNumberBlock textTop="56" textBottom="Exhibits" />

                    <div class="art__exhibits-list">
                        <div class="art__exhibits-item">
                            <picture>
                                <source type="image/webp" srcset="img/art/exhibit-4.webp">
                                <img loading="lazy" class="art__exhibits-img" src="img/art/exhibit-4.png" alt="">
                            </picture>
                            <p class="art__exhibits-text">
                                Sketches <br />and diaries
                            </p>
                        </div>

                        <div class="art__exhibits-item">
                            <picture>
                                <source type="image/webp" srcset="img/art/exhibit-5.webp">
                                <img loading="lazy" class="art__exhibits-img" src="img/art/exhibit-5.png" alt="">
                            </picture>
                            <p class="art__exhibits-text">
                                Sculpture
                            </p>
                        </div>

                        <div class="art__exhibits-item">
                            <picture>
                                <source type="image/webp" srcset="img/art/exhibit-6.webp">
                                <img loading="lazy" class="art__exhibits-img" src="img/art/exhibit-6.png" alt="">
                            </picture>
                            <p class="art__exhibits-text">
                                Fauna <br />of&nbsp;the Arctic
                            </p>
                        </div>
                    </div>
                </div>

                <div class="art__quote art__quote-VladimirBurmatov mb-100">
                    <div class="art__quote-quotation-mark"></div>
                    <div class="art__quote-text">
                        The &laquo;Art on&nbsp;an&nbsp;Expedition&raquo; exhibition is&nbsp;a&nbsp;real breakthrough project, which gives&nbsp;us a&nbsp;completely new way of&nbsp;looking at&nbsp;the Arctic today. This project combined scientific thought, serious scientific discoveries, and modern technology.
                    </div>
                    <div class="art__quote-name">
                        Vladimir Burmatov
                    </div>
                    <div class="art__quote-status art__quote-status-VladimirBurmatov">
                        Deputy of&nbsp;the State Duma of&nbsp;the Federal Assembly of&nbsp;the Russian Federation, First Deputy Chairman of&nbsp;the State Duma Committee on&nbsp;Ecology
                    </div>
                    <picture>
                        <source type="image/webp" srcset="img/art/VladimirBurmatov.webp">
                        <img loading="lazy" class="art__quote-img art__quote-img-VladimirBurmatov"
                            src="img/art/VladimirBurmatov.png" alt="Vladimir Burmatov">
                    </picture>
                    <img class="art__quote-strip" src="img/strip.png" alt="">
                </div>

                <BaseTextBlock class="mb-40" :title="!isMobile ? ' Exhibition at&nbsp;the Stroganov Russian State University of&nbsp;Art and Industry' : ''">
                    <div class="art__position-info-icon">
                        <img src="img/art/icon-3.png" alt="">
                    </div>
                    <div v-if="isMobile" class="art__position-info-title">
                        Exhibition at&nbsp;the Stroganov Russian State University of&nbsp;Art and Industry
                    </div>
                    <span>
                        <p class="mb-10">
                            An&nbsp;exhibition of&nbsp;field diaries and drawings from the expeditions of&nbsp;famous scientists, zoologists, artists and ecologists who recorded unique biodiversity against the backdrop of&nbsp;climate change, took shape in&nbsp;the museum.
                        </p>
                        <p class="mb-10">
                            The focus is&nbsp;on&nbsp;the master of&nbsp;scientific and artistic illustration, sculptor Vasily Alekseevich Vatagin, widely known for his illustrations in&nbsp;books by&nbsp;Joseph Rudyard, Jack London and Vitaly Valentinovich Bianchi.
                        </p>
                        <p>
                            No&nbsp;less iconic a&nbsp;figure is&nbsp;the creator and traveller Nikolai Nikolaevich Kondakov, who, together with Otto Yulievich Schmidt, participated in&nbsp;the expedition to&nbsp;Severnaya Zemlya on&nbsp;the icebreaker Rusanov. His polar drawings reflect the Far North in&nbsp;the early 1940s. His subjects include polar bears, pink gulls, walrus with their young, and narwhal hunting.
                        </p>
                    </span>
                </BaseTextBlock>

                <div class="art__swiper-wrap">
                    <swiper class="art__swiper" :speed="1000" :navigation="true" :modules="modules" :loop="true"
                        :slides-per-view="1" :space-between="50">
                        <SwiperSlide :lazy="true">
                            <img src="../../public/img/art/swiper-9.jpg" loading="lazy" />
                        </SwiperSlide>
                        <SwiperSlide :lazy="true">
                            <img src="../../public/img/art/swiper-10.jpg" loading="lazy" />
                        </SwiperSlide>
                        <SwiperSlide :lazy="true">
                            <img src="../../public/img/art/swiper-11.jpg" loading="lazy" />
                        </SwiperSlide>
                        <SwiperSlide :lazy="true">
                            <img src="../../public/img/art/swiper-12.jpg" loading="lazy" />
                        </SwiperSlide>
                    </swiper>
                </div>

                <div class="art__exhibits mb-100">
                    <BaseNumberBlock textTop="88" textBottom="Exhibits" />

                    <div class="art__exhibits-list">
                        <div class="art__exhibits-item">
                            <picture>
                                <source type="image/webp" srcset="img/art/exhibit-7.webp">
                                <img loading="lazy" class="art__exhibits-img" src="img/art/exhibit-7.png" alt="">
                            </picture>
                            <p class="art__exhibits-text">
                                Drawings <br />and graphics
                            </p>
                        </div>

                        <div class="art__exhibits-item">
                            <picture>
                                <source type="image/webp" srcset="img/art/exhibit-8.webp">
                                <img loading="lazy" class="art__exhibits-img" src="img/art/exhibit-8.png" alt="">
                            </picture>
                            <p class="art__exhibits-text">
                                Bone <br />carvings
                            </p>
                        </div>

                        <div class="art__exhibits-item">
                            <picture>
                                <source type="image/webp" srcset="img/art/exhibit-9.webp">
                                <img loading="lazy" class="art__exhibits-img" src="img/art/exhibit-9.png" alt="">
                            </picture>
                            <p class="art__exhibits-text">
                                Modern <br />materials
                            </p>
                        </div>
                    </div>
                </div>

                <div class="art__quote mb-100">
                    <div class="art__quote-quotation-mark"></div>
                    <div class="art__quote-text">
                        The project is&nbsp;interesting for its complexity, bringing together three different institutions under one multi-disciplinary and cross-cultural theme. It&nbsp;provides an&nbsp;opportunity to&nbsp;take a&nbsp;broad view of&nbsp;the centuries-old history of&nbsp;the region, and to&nbsp;understand the importance of&nbsp;climate change in&nbsp;the Arctic. It&nbsp;illustrates the issues behind the coexistence of&nbsp;nature, man and technology in&nbsp;a&nbsp;new way.
                    </div>
                    <div class="art__quote-name">
                        Kirill Gavrilin
                    </div>
                    <div class="art__quote-status art__quote-status-KirillGavrilin">
                        Candidate of&nbsp;Art History, Professor, Head of&nbsp;the Department of&nbsp;History of&nbsp;Arts and Humanities of&nbsp;the Stroganov Russian State University of&nbsp;Art and Industry
                    </div>
                    <picture>
                        <source type="image/webp" srcset="img/art/KirillGavrilin.webp">
                        <img loading="lazy" class="art__quote-img art__quote-img-KirillGavrilin"
                            src="img/art/KirillGavrilin.png" alt="Kirill Gavrilin">
                    </picture>
                    <img class="art__quote-strip" src="img/strip.png" alt="">
                </div>

                <div class="mb-100">
                    <div class="art__man">
                        <picture>
                            <source type="image/webp" srcset="img/art/art-man.webp">
                            <img loading="lazy" src="img/art/art-man.png">
                        </picture>
                    </div>
                    <BaseTextBlock>
                        <p class="mb-10">
                            Support for environmental and socially important projects in&nbsp;all regions where we&nbsp;operate is&nbsp;an&nbsp;important part of&nbsp;Nornickel&rsquo;s work.
                        </p>
                        <p class="mb-10">
                            The company initiates large-scale research expeditions on&nbsp;an&nbsp;annual basis, with the involvement of&nbsp;Russian scientists, local historians and employees of&nbsp;nature reserves.
                        </p>
                        <p>
                            At&nbsp;the same time, the Russian North is&nbsp;not only a&nbsp;place of&nbsp;work for the company, but also a&nbsp;place of&nbsp;inspiration. Remember that in&nbsp;2023, Norilsk Nickel sponsored the Art Seasons in&nbsp;Taimyr.
                        </p>
                    </BaseTextBlock>
                </div>
                <img class="line line-bottom" src="img/strip.png" alt="">
            </section>

            <section class="section-blue">
                <BaseTextBlock class="mb-40">
                    <div class="art__position-info-open">
                        Lectures <br />and master classes
                    </div>
                    <p>
                        In&nbsp;addition to&nbsp;large-scale exhibitions, the project included a&nbsp;wide scientific and educational program, with lectures, master classes, documentaries and feature films. Recordings of&nbsp;lectures are available to&nbsp;viewers anywhere in&nbsp;the world.
                    </p>
                </BaseTextBlock>

                <div class="art__bottom">
                    <div class="art__list-wrap mb-100">
                        <div class="art__list" :style="{ height: `${blockHeight}rem` }">
                            <div class="art__list-left">
                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=4bs_1wvDUH4&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=1&t=5s&pp=iAQB" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;Life on&nbsp;an&nbsp;expedition: how scientific discoveries happen&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Alexander Osadchiev, Chief Researcher, MIPT
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/live/PeaH6-HeUAE" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;Unusual expeditions, based on&nbsp;the diaries of&nbsp;animal artists&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Antonina Nefedova, Senior Research Fellow at&nbsp;the State Darwin Museum
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=Q4RgYYzhids&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=6" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;Problems of&nbsp;the Development and Environmental Safety of&nbsp;Mining Areas: History and Modernity&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Gazhit Tsybekmitova, Leading Researcher at&nbsp;the Institute of&nbsp;Natural Resources, Ecology and Cryology of&nbsp;the Siberian Branch of&nbsp;the Russian Academy of&nbsp;Sciences
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=CgIW8zWAthQ&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=4" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;The Artist in&nbsp;the Ice: Alexander Borisov and His Expeditions (1896-1903)&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Ivan Katyshev, Head of&nbsp;the Borisov Museum of&nbsp;Artistic Development of&nbsp;the Arctic
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=1JMIdJcd2y8&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=6" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;Arctic bird watching&nbsp;&mdash; combining the beautiful with the useful&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Elena Valova, researcher at&nbsp;the Pasvik Nature Reserve
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=ThW3oBuf0xA&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=10" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;Master-class in&nbsp;macrophotography&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Viktor Glupov, Corresponding Member of&nbsp;the Russian Academy of&nbsp;Sciences, Director of&nbsp;the Institute of&nbsp;Systematics and Ecology of&nbsp;Animals of&nbsp;the Siberian Branch of&nbsp;the Russian Academy of&nbsp;Sciences
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=-fKDqzoydmQ&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=10&pp=iAQB" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;Animal vision&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Viktor Glupov, Corresponding Member of&nbsp;the Russian Academy of&nbsp;Sciences, Director of&nbsp;the Institute of&nbsp;Systematics and Ecology of&nbsp;Animals of&nbsp;the Siberian Branch of&nbsp;the Russian Academy of&nbsp;Sciences
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=NqfGU-E_Gws&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=13" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;Flights in&nbsp;dreams and in&nbsp;reality. Thomas Saraceno&rsquo;s Ecological Utopia&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Anna Reztsova, art critic, curator of&nbsp;exhibition projects, artist
                                    </div>
                                </div>
                            </div>

                            <div class="art__list-right">
                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=NT86W_Z_WKc&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=11&pp=iAQB" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;Environmental Policy of&nbsp;the Future&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Ekaterina Bliznetskaya, Lecturer at&nbsp;the Department of&nbsp;International Complex Problems of&nbsp;Nature Management and Ecology at&nbsp;MGIMO University, expert at&nbsp;the FAO Information Center
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=BekYiUuqLSE&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=15&pp=iAQB" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;Educational tourism. The land of&nbsp;the wild deer and beautiful lakes of&nbsp;Monchetundra&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Tatyana Kochetkova, tour guide, researcher at&nbsp;the Lapland Nature Reserve
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=-oLOO4GqLhY&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=13&pp=iAQB" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;The Modest Charm of&nbsp;the Art of&nbsp;Shadows: The History of&nbsp;the Creation of&nbsp;Photograms from the Beginning of&nbsp;the XIX Century to&nbsp;the Present Day&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Lara Fedotova, artist, participant of&nbsp;the Art in&nbsp;Expedition project
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=Sh5XqyW6dkA&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=14&pp=iAQB" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;The nature reserve system of&nbsp;Russia is&nbsp;107 years old&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Svetlana Yushkova, Acting Deputy Director for Environmental Education and Educational Tourism of&nbsp;the Krasnoyarsk Stolby National Park
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=mXqduaLs_sI&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=18&pp=iAQB" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;At&nbsp;the Origins of&nbsp;Ecology: A.&nbsp;N.&nbsp;Formozov, Scientist and Artist&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Antonina Nefedova, Senior Research Fellow at&nbsp;the State Darwin Museum
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=e6FpaLStZs8&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=16&pp=iAQB" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;Shamanism and Ritual Practices in&nbsp;the Culture of&nbsp;the Indigenous Peoples of&nbsp;Taimyr&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Leila Landina, Director of&nbsp;the Taimyr House of&nbsp;Folk Art
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=Vppc0yg8d6k&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=17&pp=iAQB" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;Machine: Enemy, Helper or&nbsp;Partner? Robo-Art as&nbsp;a&nbsp;Reflection on&nbsp;a&nbsp;New Social Reality&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Anna Reztsova, art critic, curator of&nbsp;exhibition projects, artist
                                    </div>
                                </div>

                                <div class="art__list-item">
                                    <a href="https://www.youtube.com/watch?v=mK2WXrffiMc&list=PLP1q1udSlq9gMKD9YOSZr0_nMTRl7tjsX&index=19&pp=iAQB" target="_blank" rel="noopener noreferrer" class="art__list-item-title">
                                        &laquo;Vladimir Vernadsky&nbsp;&mdash; Deep Media Theory&raquo;
                                    </a>
                                    <div class="art__list-item-text">
                                        Speaker: Anna Reztsova, art critic, curator of&nbsp;exhibition projects, artist
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="art__list-ten"  :class="{ 'art__list-ten-hidden': (this.blockHeight === 715) || (this.blockHeight === 76) }"></div>

                        <button class="art__list-button"
                            :class="{ 'art__list-button-open': (this.blockHeight === 715) || (this.blockHeight === 76) }"
                            @click="increaseHeight">
                            <span>
                                Open full list
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9"
                                fill="none">
                                <path d="M1 0.5L7 7.5L13 0.500001" stroke="#333333" />
                            </svg>
                        </button>
                    </div>

                    <div class="art__swiper-wrap mb-100">
                        <swiper class="art__swiper" :speed="1000" :navigation="true" :modules="modules" :loop="true"
                            :slides-per-view="1" :space-between="50">
                            <SwiperSlide :lazy="true">
                                <img src="../../public/img/art/swiper-13.jpg" loading="lazy" />
                            </SwiperSlide>
                            <SwiperSlide :lazy="true">
                                <img src="../../public/img/art/swiper-14.jpg" loading="lazy" />
                            </SwiperSlide>
                            <SwiperSlide :lazy="true">
                                <img src="../../public/img/art/swiper-15.jpg" loading="lazy" />
                            </SwiperSlide>
                        </swiper>
                    </div>
                </div>
                <BaseSidebar class="art__sidebar">
                    <p>
                        The project &laquo;Explorers and Artist: Art on&nbsp;an&nbsp;Expedition&raquo; is&nbsp;one of&nbsp;the many ways Nornickel supports the environment and culture in&nbsp;the regions where it&nbsp;operates.
                    </p>
                </BaseSidebar>
                <img class="line line-bottom" src="img/strip.png" alt="">
            </section>

        </MainSection>
    </div>
</template>

<script>
import BaseHero from '@/layout/BaseHero.vue'
import MainSection from '@/layout/MainSection'
import BaseTextBlock from '@/components/BaseTextBlock.vue'
import BaseNumberBlock from '@/components/BaseNumberBlock.vue'
import ParticipantsSwiper from '@/components/ParticipantsSwiper.vue'
import BaseSidebar from '@/components/BaseSidebar.vue'

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
    components: {
        BaseHero,
        MainSection,
        BaseTextBlock,
        BaseNumberBlock,
        Swiper,
        SwiperSlide,
        BaseSidebar,
        ParticipantsSwiper,
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 768
        },
    },
    data() {
        return {
            blockHeight: window.innerWidth <= 768 ? 132 : 29,
            heroDescr: 'Russian expeditions to&nbsp;the Arctic over the course of&nbsp;four centuries gave&nbsp;us not only significant scientific discoveries. An&nbsp;important aspect of&nbsp;the story is&nbsp;immersion in&nbsp;the cultural context, and artistic interpretations of&nbsp;the images of&nbsp;the North created by&nbsp;nature.',
        }
    },
    setup() {
        return {
            modules: [Navigation],
        };
    },
    mounted() {
        this.$nextTick(function () {
            this.scrollAnimation()
        })
    },
    methods: {
        scrollAnimation() {
            const collageItems = Array.from(document.querySelectorAll(".text-block__wrap"));
            collageItems.forEach((elem) => {

                this.oneScrollTrigger = gsap.fromTo(elem, {
                    y: 50,
                }, {
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        start: '0% 100%',
                        end: 'bottom 75%',
                        trigger: elem,
                        scrub: true,
                        // markers: true,
                    },
                });
            });

            const sidebarItems = Array.from(document.querySelectorAll(".sidebar"));
            sidebarItems.forEach((elem) => {
                this.twoScrollTrigger = gsap.fromTo(elem, {
                    y: 50,
                }, {
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        start: '0% 100%',
                        end: 'bottom 75%',
                        trigger: elem,
                        // markers: true,
                        scrub: true,
                    },
                });
            });
        },
        increaseHeight() {
            if (window.innerWidth <= 768) {
                if (this.blockHeight === 715) {
                    this.blockHeight -= 583;
                } else {
                    this.blockHeight += 583;
                }
            } else {
                if (this.blockHeight === 76) {
                    this.blockHeight -= 47;
                } else {
                    this.blockHeight += 47;
                }
            }
        }
    }
}
</script>

<style lang="scss">
.art {
    overflow: hidden;
    .hero__descr {
        width: 41rem !important;

        @media (max-width: 768px) {
            width: unset !important;
        }
    }

    &__position-info-open {
        position: absolute;
        top: 0;
        left: -24.4rem;
        color: var(--blue-deep, #004C97);
        font-size: 2.125rem;
        font-weight: 600;
        line-height: 130%;

        @media (max-width: 768px) {
            position: relative;
            left: 0;
            font-size: 7.4667rem;
            margin-bottom: 6.4rem;
        }
    }

    &__position-info-icon {
        position: absolute;
        bottom: 0;
        right: -24rem;
        width: 5.3125rem;
        height: auto;

        @media (max-width: 768px) {
            position: relative;
            right: unset;
            margin-bottom: 6.4rem;
            width: 13.6rem;
        }

    }

    &__position-info-title {
        @media (max-width: 768px) {
            margin-bottom: 6.4rem;
            font-size: 5.8667rem;
            font-weight: 600;
            line-height: 130%;
        }
    }

    &__youtube-video {
        width: 83.4375rem;
        height: 37.5rem;
        margin: 0 auto;
        border-radius: 30px;
        overflow: hidden;

        @media (max-width: 768px) {
            height: 43rem;
            margin-bottom: 10rem !important;
        }
    }

    &__quote {
        position: relative;
        padding: 5.62rem 14rem 4.44rem;
        overflow: hidden;

        @media (max-width: 768px) {
            padding: 12rem 9.5rem 52rem 9.5rem;
            margin-bottom: 7.5rem !important;
        }

        &-VladimirBurmatov {
                @media (max-width: 768px) {
                    padding: 12rem 9.5rem 26rem 9.5rem;
                }
            }

        &-quotation-mark {
            position: absolute;
            top: 0;
            left: 8.69rem;
            width: 6.25rem;
            height: 2.69rem;
            background-image: url('data:image/svg+xml;utf8,<svg width="86" height="45" viewBox="0 0 86 45" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.08 0.91999H45.14L30.84 44.48H0.0400003L18.08 0.91999ZM58.56 0.91999H85.62L71.32 44.48H40.52L58.56 0.91999Z" fill="%23004C97"/></svg>');
            background-repeat: no-repeat;
            background-size: contain;

            @media (max-width: 768px) {
                left: 9.5rem;
                width: 13.25rem;
                height: 6.69rem;
            }
        }

        &-text {
            width: 48.6rem;
            margin-bottom: 4.5rem;
            font-size: 2rem;
            font-weight: 300;
            line-height: 130%;

            @media (max-width: 768px) {
                width: 100%;
                font-size: 5.334rem;
                margin-bottom: 9.87rem;
            }
        }

        &-name {
            margin-bottom: 1.38rem;
            font-size: 1.375rem;
            color: var(--blue-deep, #004C97);
            font-weight: 600;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 5.334rem;
                margin-bottom: 5.9rem;
            }
        }

        &-status {
            position: relative;
            font-size: 1.125rem;
            font-weight: 300;
            line-height: 130%;
            width: 27rem;
            z-index: 1;

            @media (max-width: 768px) {
                font-size: 4.5rem;
                width: 100%;
            }

            &-AlexanderBorisov {
                @media (max-width: 768px) {
                    width: 43rem;
                }
            }

            &-VladimirBurmatov {
                @media (max-width: 768px) {
                    width: 43rem;
                }
            }

            &-KirillGavrilin {
                @media (max-width: 768px) {
                    width: 52rem;
                }
            }
        }

        &-img {
            position: absolute;
            top: 4.5rem;
            right: 3.62rem;
            width: 30rem;
            height: 31.375rem;

            @media (max-width: 768px) {
                top: 96.5rem;
                right: -26.38rem;
                width: 85.867rem;
                height: 89.6rem;
            }

            &-AlexanderBorisov {
                top: -4.62rem;

                @media (max-width: 768px) {
                    top: 45.38rem;
                }
            }

            &-KirillGavrilin {
                top: unset;
                bottom: 1rem;
            }
        }

        &-strip {
            position: absolute;
            bottom: 0rem;
            left: 0;
            height: 9.5rem;

            @media (max-width: 768px) {
                height: 32rem;
                bottom: -4rem;
            }
        }
    }

    &__exhibits {
        position: relative;
        padding: 3.25rem 3.38rem 2.81rem 3.38rem;

        @media (max-width: 768px) {
            padding: 0 4.5rem;
        }

        .number-block {
            position: relative;
            left: unset;
            margin-bottom: 2.63rem;

            @media (max-width: 768px) {
                margin-bottom: 15rem;
                left: 4.5rem;
            }
        }

        &-list {
            display: flex;
            padding: 0 6.56rem 0 1.56rem;
            justify-content: space-between;

            @media (max-width: 768px) {
                flex-direction: column;
                padding: 0;
            }
        }

        &-item {
            display: flex;
            align-items: flex-end;

            @media (max-width: 768px) {
                align-items: center;
                gap: 4rem;

                &:nth-child(2) {
                    flex-direction: row-reverse;
                }
            }
        }

        &-img {
            width: 15.5rem;
            height: 15.5rem;
            border-radius: 100%;

            @media (max-width: 768px) {
                width: 47.5rem;
                height: 47.5rem;
            }
        }

        &-text {
            margin-bottom: 1.94rem;
            color: var(--blue-deep, #004C97);
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 4.53334rem;
            }
        }
    }

    &__swiper {
        width: 100%;
        height: 41.5rem;
        border-radius: 16px;

        @media (max-width: 768px) {
            height: 95.2rem;
        }

        &-wrap {
            padding: 0 3.38rem;
            margin: 3.5rem;
            width: 100%;
            margin: 0 auto;

            @media (max-width: 768px) {
                padding: 0 9.5rem;
            }
        }

        .swiper-slide {
            border-radius: 16px;
            overflow: hidden;
            width: 100%;
            height: 100%;

            @media (max-width: 768px) {
                img {
                    object-fit: cover;
                    height: 100%;
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: 4.6875rem;
            height: 4.6875rem;

            @media (max-width: 768px) {
                display: none;
            }

            &::after {
                width: 100%;
                height: 100%;
            }
        }

        .swiper-button-next {
            &::after {
                content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75" fill="none">  <circle cx="37.5" cy="37.5" r="37.5" fill="white" fill-opacity="0.2"/>  <path d="M34.0881 46.0226L40.9062 38.3521L34.0881 30.6816" stroke="white" stroke-width="2.95455"/></svg>');
            }
        }

        .swiper-button-prev {
            &::after {
                content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75" fill="none">  <circle cx="37.5" cy="37.5" r="37.5" transform="rotate(-180 37.5 37.5)" fill="white" fill-opacity="0.2"/>  <path d="M40.9041 28.9774L34.0859 36.6479L40.9041 44.3184" stroke="white" stroke-width="2.95455"/></svg>');
            }
        }
    }

    .section-white {
        position: relative;
        margin-bottom: 0;

        .line {
            transform: rotate(180deg);
            position: absolute;
            width: 100%;
            height: 10.5rem;
            bottom: -15rem;
            z-index: 1;

            @media (max-width: 768px) {
                height: 20.5rem;
                bottom: -145px;
            }
        }
    }

    .section-blue {
        padding-top: 8rem;
        position: relative;
        background-color: #F3F7FA;
        padding-bottom: 1.72rem;

        @media (max-width: 768px) {
            padding-top: 14rem;
        }

        .line {
            position: absolute;
            width: 100%;
            height: 10.5rem;

            @media (max-width: 768px) {
                height: 40rem;
            }

            &-bottom {
                bottom: -1.5rem;

                @media (max-width: 768px) {
                    bottom: -10.5rem;
                }
            }
        }
    }

    &__man {
        width: 34rem;
        height: auto;
        margin: 0 auto;
        margin-bottom: 0.81rem;

        @media (max-width: 768px) {
            width: 80.56rem;
            margin-bottom: 5rem;
        }
    }

    &__sidebar {
        margin-bottom: 6rem;
        width: 46.125rem;

        @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 25rem;
        }

        .sidebar__text {
            color: var(--blue-deep, #004C97);
        }

        .sidebar__line-top,
        .sidebar__line-bottom {
            filter: grayscale(1) invert(1) sepia(1) saturate(1) hue-rotate(180deg) brightness(1.2);
        }
    }

    &__list {
        display: flex;
        gap: 1.69rem;
        margin-bottom: 2rem;
        overflow: hidden;
        transition: height 0.5s;

        @media (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 7rem;
            gap: 8.54rem;
        }

        &-ten {
            position: absolute;
            bottom: 3rem;
            width: 100%;
            left: 0;
            background: linear-gradient(180deg, rgba(243, 247, 250, 0) 0%, #F3F7FA 100%);
            height: 5rem;

            @media (max-width: 768px) {
                bottom: 8rem;
                height: 25rem;
            }

            &-hidden {
                display: none;
            }
        }

        &-wrap {
            position: relative;
            padding: 2rem 8.31rem 0 33rem;

            @media (max-width: 768px) {
                padding: 0 9.5rem;
            }
        }

        &-right,
        &-left {
            width: 25.5rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            @media (max-width: 768px) {
                width: 100%;
                gap: 8.54rem;
            }
        }

        &-item {
            &-title {
                display: block;
                margin-bottom: 1rem;
                font-size: 1.125rem;
                font-weight: 600;
                line-height: 130%;
                transition: color ease 0.2s;
                color: #0077C8;
                text-decoration: underline;
                @media (hover: hover) {
                    &:hover {
                        color: #00589C;
                    }
                }
                @media (max-width: 768px) {
                    font-size: 4.27rem;
                    margin-bottom: 4.27rem;
                }
            }

            &-text {
                font-size: 1.125rem;
                font-weight: 300;
                line-height: 130%;

                @media (max-width: 768px) {
                    font-size: 4.5rem;
                    
                }
            }
        }

        &-button {
            background-color: transparent;
            outline: none;
            border: none;
            display: flex;
            gap: 0.38rem;
            align-items: center;
            font-size: 0.9375rem;
            font-weight: 400;
            line-height: 130%;
            cursor: pointer;

            @media (max-width: 768px) {
                gap: 1rem;
                font-size: 4rem;
                color: var(--blue-deep, #004C97);
            }

            svg {
                width: 0.75rem;
                height: 0.4375rem;
                transition: all .5s;

                @media (max-width: 768px) {
                    width: 3.2rem;
                    height: 0.47em;

                    path {
                        stroke: var(--blue-deep, #004C97);
                    }
                }
            }

            &-open {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__bottom {
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}
</style>