<template>
    <div class="treasures-1-container__wrap">
        <div class="treasures-1-margin">
            <div class="treasures-1-container">
                <div class="treasures-1-inner">

                        <div class="treasures-1-item treasures-1-item_1">
                            <div class="treasures-1-item__title">Smew</div>
                            <div class="treasures-1-item__descr">
                                <p class="mb-10">
                                    This species of duck is a symbol of Pasvik and has been part of the nature reserve's logo since 2021.
                                </p>
                                <p>
                                    While a relatively rare duck for other Russian regions, the smew is one of the most populous and common species in Pasvik.
                                </p>
                            </div>
                        </div>

                        <div class="smew">
                            <video @loadeddata="videoLoaded" autoplay loop muted playsinline>
                                <source src="video/smew.mov" type="video/mp4">
                                <source src="video/smew.webm" type="video/webm">
                            </video>
                            <picture v-if="!isLoadingOne">
                                <source type="image/webp" srcset="img/smew.webp">
                                <img loading="lazy" src="img/smew.png" alt="">
                            </picture>
                        </div>

                        <div class="spider">
                            <video autoplay loop muted playsinline>
                                <source src="video/spider.mov" type="video/mp4">
                                <source src="video/spider.webm" type="video/webm">
                            </video>
                        </div>

                        <div class="kutora">
                            <video autoplay loop muted playsinline>
                                <source src="video/kutora.mov" type="video/mp4">
                                <source src="video/kutora.webm" type="video/webm">
                            </video>
                        </div>

                        <div class="owl">
                            <video autoplay loop muted playsinline>
                                <source src="video/owl.mov" type="video/mp4">
                                <source src="video/owl.webm" type="video/webm">
                            </video>
                        </div>

                        <div class="treasures-1-item treasures-1-item_2">
                            <div class="treasures-1-item__title">Freshwater pearl mussel</div>
                            <div class="treasures-1-item__descr">
                                This is one of the longest-living invertebrates in existence: it can live more than 100 years and even more than 200, according to some data.
                            </div>
                        </div>

                        <div class="treasures-1-item treasures-1-item_3">
                            <div class="treasures-1-item__title">Hooker's flapwort</div>
                            <div class="treasures-1-item__descr">
                                The Murmansk Region is known to have only ten locations inhabited by this liverwort species, each of them with extre
                            </div>
                        </div>

                        <div class="treasures-1-item treasures-1-item_4">
                            <div class="treasures-1-item__title">Aculepeira lapponica</div>
                            <div class="treasures-1-item__descr">
                                This is the orb-weaving spider found on the Scandinavian Peninsula and in the Murmansk Region. Only two female spiders of this species have been spotted in the Pasvik nature reserve.
                            </div>
                        </div>

                        <div class="treasures-1-item treasures-1-item_5">
                            <div class="treasures-1-item__title">Eurasian water shrew</div>
                            <div class="treasures-1-item__descr">
                                This large shrew is a good swimmer that hunts for fish and waterfowl chicks in addition to insects. The Eurasian water shrew has venomous saliva with strong paralytic properties. This enables the shrew to move its paralysed prey to a store and keep it to eat later.
                            </div>
                        </div>

                        <div class="treasures-1-item treasures-1-item_6">
                            <div class="treasures-1-item__title">Great grey owl</div>
                            <div class="treasures-1-item__descr">
                                It is One of the largest species of wood owls. Unlike many other owls, great grey owls are active not only during the night but also in daytime.
                            </div>
                        </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ScrollSwiper from './CustomScrollSwiper';


export default {
    data() {
        return {
            isLoadingOne: false,
            isLoadingTwo: false,
        }
    },
    mounted() {
        this.$nextTick(function () {
            const wrap = document.querySelector('.treasures-1-container__wrap');
		const container = document.querySelector('.treasures-1-container');
		const inner = document.querySelector('.treasures-1-inner');
		const marg = document.querySelector('.treasures-1-margin');
		new ScrollSwiper(wrap, container, inner, marg);
        })
    },
    methods: {
        videoLoaded() {
            this.isLoadingOne = true;
        },
        videoLoadedTwo() {
            this.isLoadingTwo = true;
        },
       
    }
}
</script>


<style lang="scss">
.treasures-1 {
    position: relative;

	&-margin {
		display: flex;
		width: 100%;
		height: 100%;
	}

	&-inner {
		position: relative;
		display: flex;
		flex-shrink: 0;
        width: 178rem;
        height: 38.12rem;
        background-image: url(../../public/img/treasures-scroll-1.jpg);
        background-repeat: no-repeat;
        background-size: 100% 100%;

		@media (max-width: 768px) {
            width: 554rem;
            height: 100%;
            background-position: bottom;
            background-size: 100%;
            background-image: url(../../public/img/treasures-scroll-1-mobile.png);

		}
	}

    &-container {
        position: sticky;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		height: 100vh;
		width: 100%;

		overflow: hidden;

        @media (max-width: 768px) {
                width: unset;
                height: 139.467rem;
                overflow-x: scroll;
                overflow-y: hidden;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    width: 0;
                }
        }
    }

    video {
        position: absolute;
        width: 100%;
    }

    .smew {
        position: absolute;
        top: 9rem;
        left: 7rem;
        width: 32rem;

        video {
            transform: scaleX(-1);
            width: 145%;
            top: -4.5rem;
            left: -9rem;
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    .spider {
        position: absolute;
        top: 26rem;
        left: 89rem;
        width: 20rem;

        @media (max-width: 768px) {
            display: none;
        }
    }

    .kutora {
        position: absolute;
        top: 18rem;
        left: 110rem;
        width: 28rem;
        transform: scaleX(-1);

        @media (max-width: 768px) {
            display: none;
        }
    }

    .owl {
        position: absolute;
        top: 0rem;
        left: 137rem;
        width: 27rem;
        transform: scaleX(-1);

        @media (max-width: 768px) {
            display: none;
        }
    }

    &-item {
        @media (max-width: 768px) {
            height: 139.467rem;
        }

        &__title {
            padding: 0.4375rem 0.625rem;
            margin-bottom: 1.06rem;
            width: max-content;
            font-size: 0.9375rem;
            font-weight: 300;
            line-height: 130%;
            color: #fff;
            border-radius: 1.6875rem;
            background: var(--grey, #626261);

            @media (max-width: 768px) {
                font-size: 4rem;
                padding: 1.8716rem 2.6737rem;
                border-radius: 7.22rem;
                margin-bottom: 4.5456rem;
            }
        }

        &__descr {
            font-size: 1.0417rem;
            font-weight: 300;
            line-height: 130%;

            @media (max-width: 768px) {
                font-size: 4rem;
            }
        }

        &_1 {
            width: 38.889rem;
            padding: 6.5278rem 15.904rem 0 5.21rem;

            @media (max-width: 768px) {
                width: 118rem;
                padding: 14rem 11.46rem 0 16rem;
            }
        }

        &_2 {
            width: 25.487rem;
            padding: 10rem 9.1rem 0 0;

            @media (max-width: 768px) {
                width: 69rem;
                padding: 34rem 15rem 0 0;
            }
        }

        &_3 {
            width: 25.4866rem;
            padding: 19rem 5.3472rem 0 3rem;

            @media (max-width: 768px) {
                width: 103rem;
                padding: 76rem 17rem 0 0;
            }
        }

        &_4 {
            width: 22.778rem;
            padding: 10.97rem 7.56945rem 14.514rem 0;

            @media (max-width: 768px) {
                width: 95rem;
                padding: 155px 88px 0 0;
            }
        }

        &_5 {
            width: 48.23rem;
            padding: 6.458rem 27.4rem 0 0;

            @media (max-width: 768px) {
                width: 186rem;
                padding: 24rem 81rem 0 0;
            }
        }

        &_6 {
            width: 16.32rem;
            padding: 9rem 1rem 0 0;

            @media (max-width: 768px) {
                width: 81rem;
                padding: 31rem 6.4rem 0 0;
            }
        }
    }
}

.swiper {
    display: none;

    @media (max-width: 768px) {
        display: block;
    }
}
</style>