var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"big-map"},[_vm._t("default"),_c('picture',[_c('source',{attrs:{"media":"(max-width: 768px)","type":"image/webp","srcset":_vm.imgMobile+ '.webp'}}),_c('img',{attrs:{"loading":"lazy","src":_vm.img + '.webp',"alt":""}})]),_vm._l((_vm.circales),function(circale,index){return _c('div',{key:index,staticClass:"map-circale",class:{
        'map-circale__blue': circale.color === 'blue',
        'map-circale__green': circale.color === 'green',
        'map-circale__purple': circale.color === 'purple',
        'map-circale__1': circale.number === '1',
        'map-circale__2': circale.number === '2',
        'map-circale__3': circale.number === '3',
        'map-circale__4': circale.number === '4',
        'map-circale__5': circale.number === '5',
        'map-circale__6': circale.number === '6',
        'map-circale__7': circale.number === '7'
    }})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }