export default function ScrollSwiper(wrap, container, inner, marg) {
    let currentProgress = 0;

    let rem = window.innerWidth / 100;
    const scrollHandler = () => {
        if (wrap.getBoundingClientRect().top < 0 && wrap.getBoundingClientRect().bottom - window.innerHeight >= 0) {
            currentProgress = (-wrap.getBoundingClientRect().top / (wrap.offsetHeight - window.innerHeight)) * 100;
        } else if (wrap.getBoundingClientRect().top >= 0) {
            currentProgress = 0;
        } else {
            currentProgress = 100;
        }
        let innerWidth = inner.offsetWidth;
        let bodyWidth = wrap.offsetWidth;
        let left = ((innerWidth - bodyWidth) * currentProgress) / 100;
        inner.style.left = `${-left}px`;
        // gsap.to(inner, { x: -left,  duration: 0.3 });
        //   gsap.to(container, { x: -left, ease: "none", duration: 0.5 });
    };
    let options = {
        threshold: 0.0,
        rootMargin: '0% 0% 0% 0%',
    };
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                scrollHandler();
                document.addEventListener('scroll', scrollHandler);
            } else {
                document.removeEventListener('scroll', scrollHandler);
                scrollHandler();
            }
        });
    }, options);

    const setMargins = () => {
        let margin = (container.offsetHeight - inner.offsetHeight) / 2;
        marg.style.marginTop = `${-margin/rem}rem`;
        wrap.style.marginBottom = `${-margin/rem}rem`;
    };
    const resizeUpdate = () => {
        rem = window.innerWidth / 100;
        if (window.innerWidth > 768) {
			container.scrollLeft = 0;
			scrollHandler();
            wrap.style.height = `${(inner.offsetWidth * (window.innerHeight / window.innerWidth) * 1.5) / rem}rem`;
            observer.observe(container);
        } else {
            wrap.style = ``;
            inner.style = ``;
            marg.style = ``;
            observer.unobserve(container);
			document.removeEventListener('scroll', scrollHandler);
        }
    };

    setMargins();
    resizeUpdate();

    window.addEventListener('resize', resizeUpdate);
    window.addEventListener('resize', setMargins);

}