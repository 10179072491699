var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-container__wrap"},[_c('div',{staticClass:"treasures-2-margin"},[_c('div',{staticClass:"treasures-2-container"},[_c('div',{staticClass:"treasures-2-inner"},[_vm._m(0),_c('div',{staticClass:"lizard"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true},on:{"loadeddata":_vm.videoLoaded}},[_c('source',{attrs:{"src":"video/lizard.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/lizard.webm","type":"video/webm"}})]),(!_vm.isLoadingOne)?_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":"img/lizard.webp"}}),_c('img',{attrs:{"loading":"lazy","src":"img/lizard.png","alt":""}})]):_vm._e()]),_c('div',{staticClass:"swan"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true},on:{"loadeddata":_vm.videoLoadedTwo}},[_c('source',{attrs:{"src":"video/swan.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/swan.webm","type":"video/webm"}})]),(!_vm.isLoadingTwo)?_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":"img/collage-kola-swan.webp"}}),_c('img',{attrs:{"loading":"lazy","src":"img/collage-kola-swan.png","alt":""}})]):_vm._e()]),_c('div',{staticClass:"shrike"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"video/shrike.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/shrike.webm","type":"video/webm"}})])]),_c('div',{staticClass:"deep"},[_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"video/deep-big.mov","type":"video/mp4"}}),_c('source',{attrs:{"src":"video/deep-big.webm","type":"video/webm"}})])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_1"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Sundews")]),_c('div',{staticClass:"treasures-2-item__descr"},[_c('p',[_vm._v(" The leaves of this plant are topped with sticky secretions, which attract insects and entrap them with the mucilage, preventing their escape. After that, the leaves roll in to envelop and clog the prey, with the digestion process taking several days. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_2"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Viviparous lizard")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" Thanks to its exceptional hardiness to the cold, this species of lizard can survive even near the Arctic Circle. They spend winters underground, hibernating in soil layers up to 40 cm deep. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_3"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Calypso orchid")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" It is one of the ten species of orchid growing in the reserve. The plant takes its name from the nymph Calypso in Greek mythology. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_4"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Whooper swan")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" These birds arrive in the Lapland Nature Reserve in March and early April from their wintering sites. Not afraid of the cold, they need open water to feed. Whooper swans form monogamous pairs and mate for life. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_5"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Wild reindeer")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" This large songbird species is a ruthless hunter. Small rodents, lizards, and large insects are the bulk of its prey. Sometimes great grey shrikes can store their prey by impaling it upon sharp branches and thorns. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_6"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Great grey shrike")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" This is the only species of reindeer in which both males and females grow antlers. The Lapland Nature Reserve is home to around 1,000 of these animals. To survive the harsh winters, reindeer form temporary herds of 100 or more animals. ")])])
}]

export { render, staticRenderFns }