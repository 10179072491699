<template>
    <div class="pyasino">
        <BaseHero title="Revitalising <br/>Lake Pyasino" :descr="heroDescr" :img="'img/hero1.jpg'"
            :imgMobile="'img/hero1-mobile.jpg'" />
        <MainSection>
            <section class="lake">
                <div class="lake__introduction mb-100">
                    <picture>
                        <source type="image/webp" srcset="img/introduction.webp">
                        <img loading="lazy" class="lake__introduction-img" src="img/introduction.png">
                    </picture>
                    <BaseTextBlock>
                        <p class="mb-10">
                            The Arctic has a&nbsp;lot of&nbsp;lakes, especially those filled by&nbsp;underground
                            springs. The lakes are not nutrient-rich, so&nbsp;they don&rsquo;t contain any fish.
                            In&nbsp;other lakes, there are so&nbsp;many fish that people can start fishing
                            on&nbsp;an&nbsp;industrial
                            scale. But sometimes this can lead to&nbsp;the depletion of&nbsp;fish stocks, in&nbsp;which
                            case fishing gets prohibited until the fish&rsquo;s reproduction capacity in&nbsp;the body
                            of&nbsp;water is&nbsp;fully restored.
                        </p>
                        <p>
                            This process can take decades, but scientists have come up&nbsp;with an&nbsp;unusual and
                            eco-friendly way to&nbsp;speed it&nbsp;up. An&nbsp;experiment to&nbsp;increase fish
                            reproduction capacity is&nbsp;currently taking place at&nbsp;Lake Pyasino.
                        </p>
                        <BaseNumberBlock textTop="735 sq km" textBottom="Lake Pyasino surface area" />
                    </BaseTextBlock>
                </div>

                <div class="lake__map mb-100">
                    <BaseTextBlock title="Lake Pyasino">
                        <p>
                            Lake Pyasino is&nbsp;located 20&nbsp;kilometres away from Norilsk.
                            It&nbsp;is&nbsp;said that a&nbsp;hundred years ago there were fish in&nbsp;the lake, and
                            the locals used to&nbsp;fish for a&nbsp;living. However, Pyasino saw fish numbers
                            dropping year after year. Whether this was due to&nbsp;the industrial development
                            of&nbsp;the area or&nbsp;because of&nbsp;unsustainable fishing is&nbsp;still unclear.
                        </p>
                    </BaseTextBlock>
                    <div class="lake__map-img">
                        <div class="circle"></div>
                        <picture>
                            <img loading="lazy" src="img/lake-map.webp">
                        </picture>
                    </div>
                </div>

                <BaseTextBlock title="What makes <br/>a fish-productive lake?">
                    <p>
                        A&nbsp;lake is&nbsp;home to&nbsp;a&nbsp;variety of&nbsp;living things, ranging from
                        minuscule bacteria to&nbsp;large fish and aquatic mammals. In&nbsp;Arctic water bodies, the
                        fauna is&nbsp;unique in&nbsp;terms of&nbsp;species composition, but its population and
                        biomass are relatively small. The flora is&nbsp;also rather sparse, with no&nbsp;large algae
                        present in&nbsp;northern water bodies, only microscopic ones&nbsp;&mdash; phytoplankton.
                    </p>
                </BaseTextBlock>

            </section>

            <section class="scroll mb-100">
                <div class="scroll-bg bg-1">
                </div>
                <div class="scroll-bg bg-2">
                </div>
                <div class="scroll-bg bg-3">
                    <video v-if="!isMobile" autoplay loop muted playsinline class="pyasino__video" preload="auto">
                        <source src="video/fish.mp4" type="video/mp4">
                    </video>
                </div>
                <div class="scroll-bg bg-4">
                </div>
                <div class="scroll-wrap">
                    <div class="scroll-card card-1 text-block__descr">
                        <div>
                            <p class="mb-10">
                                With the help of sunlight, phytoplankton convert carbon dioxide into oxygen, thereby
                                making it possible for other organisms to live and breathe.
                            </p>
                            <p>
                                At the same time, microalgae serve not only as the lungs of a body of water, but as food
                                for zooplankton, such as small crustaceans, molluscs, and aquatic insect larvae.
                            </p>
                        </div>
                    </div>
                    <div class="scroll-card card-2 text-block__descr">
                        Zooplankton are food for juvenile fish and some adult fish species, which in turn, are eaten
                        by predatory fish.
                    </div>
                    <div class="scroll-card card-3 text-block__descr">
                        At&nbsp;the top of&nbsp;this food chain, or, as&nbsp;scientists call&nbsp;it, trophic chain, are
                        waterfowl. They
                        eat fish, and their lime&nbsp;&mdash; a&nbsp;metabolic byproduct&nbsp;&mdash; ends
                        up&nbsp;in&nbsp;the water and becomes a
                        nutrient medium for phytoplankton. The circle is&nbsp;complete.
                    </div>
                    <div class="scroll-card card-4 text-block__descr">
                        However, if&nbsp;even one link is&nbsp;excluded from the trophic chain or&nbsp;if&nbsp;the
                        composition and ratio
                        of&nbsp;the lake inhabitants are altered, the system starts falling apart. Discharging untreated
                        domestic and industrial wastewater, accidents involving fuel spills, or&nbsp;unsustainable
                        fishing can disrupt the balance of&nbsp;the ecosystem.
                    </div>
                </div>
            </section>

            <section class="work-on-mistakes mb-100">
                <BaseTextBlock class="mb-40" title="Lessons learned">
                    <p class="mb-10">
                        According to&nbsp;scientists, the situation can be&nbsp;remedied. For that to&nbsp;happen,
                        it&nbsp;is&nbsp;necessary
                        to&nbsp;improve the efficiency of&nbsp;treating utlity waste water discharged into nearby bodies
                        of
                        water, and to&nbsp;restore the populations of&nbsp;organisms inhabiting them, starting
                        with the very foundation of&nbsp;the food chain&nbsp;&mdash; phytoplankton.
                    </p>
                    <p class="mb-10">
                        One way of&nbsp;solving this problem is&nbsp;to&nbsp;introduce mineral fertilisers into the
                        lake.
                        Nutrient-rich water will support the growth of&nbsp;microalgae, with zooplankton and fish having
                        more to&nbsp;eat as&nbsp;a&nbsp;result. The method is&nbsp;viable, but unfortunately it&nbsp;has
                        its
                        shortcomings.
                    </p>
                    <p>
                        If&nbsp;the &ldquo;fertilisers&rdquo; composition is&nbsp;incorrect or&nbsp;if&nbsp;they are
                        applied in&nbsp;excessive amounts,
                        blue-green algae, also known as&nbsp;cyanobacteria, will begin to&nbsp;proliferate rapidly
                        in&nbsp;the
                        lake. Scientists refer to&nbsp;this phenomenon as&nbsp;&ldquo;an&nbsp;algal bloom&rdquo;.
                    </p>
                </BaseTextBlock>

                <div class="work-on-mistakes__img mb-40">
                    <!-- <video v-if="!isMobile" autoplay loop muted playsinline class="" preload="auto">
                        <source src="video/fish-4.mov" type="video/mp4">
                        <source src="video/fish-4.webm" type="video/webm">
                    </video> -->
                </div>

                <BaseTextBlock class="mb-40">
                    <p>
                        The mass mortality of&nbsp;living organisms because of&nbsp;an&nbsp;algal bloom will result
                        in&nbsp;the decay of&nbsp;their remains and consequent water pollution. Naturally, cyanobacteria
                        can also be&nbsp;found in&nbsp;a&nbsp;healthy ecosystem, but it&nbsp;is&nbsp;a&nbsp;balance
                        that matters most. The domincance the dominance of&nbsp;one particular species disrupts this
                        balance.
                    </p>
                </BaseTextBlock>

                <BaseSidebar>
                    <p>
                        Blooms caused by&nbsp;cyanobacteria can persist for decades after the introduction
                        of&nbsp;fertilisers into the lake. Thus, an&nbsp;attempt to&nbsp;solve one problem can result
                        in&nbsp;another, equally serious issue.
                    </p>
                </BaseSidebar>
            </section>

            <section class="in-search-section">
                <BaseTextBlock title="Looking for alternatives">
                    <div>
                        <p class="mb-10">
                            In&nbsp;their search for alternatives to&nbsp;mineral fertilisers, scientists turned their
                            focus to&nbsp;a&nbsp;natural substitute: waterbird feces (also referred
                            to&nbsp;as&nbsp;guano). In&nbsp;natural environments, these droppings maintain the balance
                            of&nbsp;nutrients in&nbsp;lakes. If&nbsp;birds no&nbsp;longer nest near the lake for various
                            reasons, the feces can be&nbsp;added to&nbsp;the water artificially. This eco-friendly
                            process is&nbsp;termed guanotrophication.
                        </p>
                        <p class="mb-10">
                            Experts hypothesized that in&nbsp;bodies of&nbsp;water fertilised with bird guano, different
                            types of&nbsp;phytoplankton, such as&nbsp;diatoms and green algae, would replace
                            cyanobacteria. These algae form the foundation of&nbsp;the food chain for valuable
                            fish species, and their proliferation does not cause harmful algal blooms.
                        </p>
                        <p>
                            To&nbsp;test their hypothesis, the scientists embarked on&nbsp;an&nbsp;expedition
                            to&nbsp;the Taymyr Peninsula in&nbsp;the summer of&nbsp;2022. They used a&nbsp;special
                            hydroplane and surveyed many of&nbsp;the local lakes over a&nbsp;period of&nbsp;several
                            weeks.
                        </p>
                    </div>
                    <BaseNumberBlock class="hidden-mobile" textTop="21 lakes"
                        textBottom="surveyed during the&nbsp;expedition" />
                </BaseTextBlock>
                <div class="in-search">
                    <div class="in-search__seagulls"></div>
                    <video v-if="!isMobile" autoplay loop muted playsinline class="in-search__video" preload="auto">
                        <source src="video/in-search.mp4" type="video/mp4">
                    </video>
                    <div class="in-search__fish" v-if="!isMobile">
                        <video v-if="!isMobile" @loadeddata="videoLoaded" autoplay loop muted playsinline class=""
                            preload="auto">
                            <source src="video/fish.mov" type="video/mp4">
                            <source src="video/fish.webm" type="video/webm">
                        </video>
                        <picture v-if="!isLoadingOne || isMobile">
                            <source type="image/webp" srcset="img/fish.webp">
                            <img loading="lazy" src="img/fish.png">
                        </picture>
                    </div>

                    <div class="in-search__fish-2" v-if="!isMobile">
                        <video autoplay loop muted playsinline class="" preload="auto">
                            <source src="video/fish-2.mov" type="video/mp4">
                            <source src="video/fish-2.webm" type="video/webm">
                        </video>
                    </div>

                    <div class="in-search__fish-3" v-if="!isMobile">
                        <video autoplay loop muted playsinline class="" preload="auto">
                            <source src="video/fish-3.mov" type="video/mp4">
                            <source src="video/fish-3.webm" type="video/webm">
                        </video>
                    </div>
                    <BaseTextBlock class="hidden-mobile">
                        <p>
                            Some lakes had no&nbsp;aquatic birds around at&nbsp;all, while others hosted large colonies
                            of&nbsp;tens or&nbsp;even hundreds of&nbsp;individuals. The biologists took water samples
                            from each lake and later sent them for analysis.
                            <br />
                            <br /> The laboratory findings confirmed their hypothesis. In&nbsp;the lakes with birds
                            nesting around (where their droppings enter the water), the balance of&nbsp;dissolved
                            nutrients in&nbsp;the water was indeed better. Also, their
                            plankton was predominantly composed of&nbsp;beneficial diatoms and green algae, with
                            a&nbsp;noticeably lower presence of&nbsp;cyanobacteria compared to&nbsp;birdless lakes.
                            <br />
                            <br /> In&nbsp;other words, these lakes harboured a&nbsp;balanced phytoplankton community,
                            providing a&nbsp;valuable food supply for the unique Arctic fish species.
                        </p>
                        <BaseNumberBlock textTop="16 p.p."
                            textBottom="lower share of cyanobacteria in lakes with bird populations" />
                    </BaseTextBlock>
                </div>
                <BaseTextBlock class="visiable-mobile">
                    <div>
                        <p class="mb-10">
                            Some lakes had no&nbsp;aquatic birds around at&nbsp;all, while others hosted large colonies
                            of&nbsp;tens or&nbsp;even hundreds of&nbsp;individuals. The biologists took water samples
                            from each lake and later sent them for analysis.
                        </p>

                        <p class="mb-10">The laboratory findings confirmed their hypothesis. In&nbsp;the lakes with
                            birds nesting around (where their droppings enter the water), the balance of&nbsp;dissolved
                            nutrients in&nbsp;the water was indeed better. Also, their plankton
                            was predominantly composed of&nbsp;beneficial diatoms and green algae, with
                            a&nbsp;noticeably lower presence of&nbsp;cyanobacteria compared to&nbsp;birdless lakes.
                        </p>
                        <p>
                            In&nbsp;other words, these lakes harboured a&nbsp;balanced phytoplankton community,
                            providing a&nbsp;valuable food supply for the unique Arctic fish species.
                        </p>
                    </div>
                    <BaseNumberBlock textTop="16 p.p."
                        textBottom="lower share of cyanobacteria in lakes with bird populations" />
                </BaseTextBlock>
            </section>

            <section class="shares">
                <h3 class="shares__title">
                    Phytoplankton distribution in lakes with and without birds
                </h3>
                <div class="shares__wrap">
                    <div class="shares__name-scales">
                        <div class="scales__text">Cyanobacteria</div>
                        <div class="scales__text">Diatoms</div>
                        <div class="scales__text">Green algae</div>
                    </div>
                    <div class="shares__item">
                        <div class="shares__img-wrap">
                            <img loading="lazy" class="shares__img-img" src="img/nest.png">
                        </div>
                        <div class="scales__text-wrap">
                            <div class="scales__text-mobile">Cyanobacteria</div>
                            <BaseScale :percent="'32,6%'" class="mr-4-31" :hasClass="'scale-1'" />
                        </div>
                        <div class="scales__text-wrap">
                            <div class="scales__text-mobile">Diatoms</div>
                            <BaseScale :percent="'27,9%'" class="mr-4-31" :hasClass="'scale-3'" />
                        </div>
                        <div class="scales__text-wrap">
                            <div class="scales__text-mobile"> Green algae</div>
                            <BaseScale :percent="'10,2%'" class="mr-4-31" :hasClass="'scale-5'" />
                        </div>
                    </div>
                    <div class="shares__item">
                        <div class="shares__img-wrap">
                            <img loading="lazy" class="shares__img-img" src="img/bird.png">
                        </div>
                        <div class="scales__text-wrap">
                            <div class="scales__text-mobile">Cyanobacteria</div>
                            <BaseScale :percent="'16,2%'" :hasClass="'scale-2'" />
                        </div>
                        <div class="scales__text-wrap">
                            <div class="scales__text-mobile">Diatoms</div>
                            <BaseScale :percent="'39,9%'" :hasClass="'scale-4'" />
                        </div>
                        <div class="scales__text-wrap">
                            <div class="scales__text-mobile">Green algae</div>
                            <BaseScale :percent="'20,2%'" :hasClass="'scale-6'" />
                        </div>
                    </div>
                </div>

                <BaseTextBlock class="mb-40">
                    <p>
                        The question of whether guanotrophication can revive life in Arctic lakes is still unresolved.
                        While a quick answer is not possible, the results of the first year are cause for cautious
                        optimism, and the scientists continue their research with the active support of Nornickel.
                    </p>
                </BaseTextBlock>

                <BaseSidebar class="remove-padding">
                    <p>
                        Scientists and Nornickel are teaming up to develop and test an eco-friendly guanotrophication
                        technology to improve water quality and revive life in Arctic lakes.
                    </p>
                </BaseSidebar>
            </section>
        </MainSection>
    </div>
</template>

<script>
import BaseHero from '@/layout/BaseHero.vue';
import MainSection from '@/layout/MainSection.vue'
import BaseNumberBlock from "@/components/BaseNumberBlock.vue";
import BaseTextBlock from '@/components/BaseTextBlock.vue'
import BaseSidebar from '@/components/BaseSidebar.vue'
import BaseScale from '@/components/BaseScale.vue'

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'App',
    data() {
        return {
            isLoadingOne: false,
            oneScrollTrigger: null,
            twoScrollTrigger: null,
            threeScrollTrigger: null,
            ScrollTrigger: null,
            heroDescr: 'The Far North is&nbsp;often referred to&nbsp;as&nbsp;the country&rsquo;s treasure trove, as&nbsp;the region has a&nbsp;wealth of&nbsp;minerals. This mineral wealth benefits the economy, but compromises the environment. With the arrival of&nbsp;humans, not only the land but also the water are subjected to&nbsp;change: rivers, lakes, groundwater, and even permafrost.'
        }
    },
    components: {
        BaseHero,
        BaseTextBlock,
        BaseSidebar,
        BaseScale,
        MainSection,
        BaseNumberBlock
    },
    mounted() {
        this.$nextTick(function () {
            this.scrollAnimation()
            // this.parallax()
        })
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 768
        },
    },
    beforeDestroy() {
        this.oneScrollTrigger.kill()
        this.oneScrollTrigger = null

        this.twoScrollTrigger.kill()
        this.twoScrollTrigger = null
    },
    methods: {
        videoLoaded() {
            this.isLoadingOne = true;
        },
        scrollMobile() {
            const collageItems = Array.from(document.querySelectorAll(".scroll-bg.active"))

            collageItems.forEach((elem) => {
                elem.css('background-position', '0px ' + document.scrollTop() + 'px')
            })
        },
        scrollAnimation() {
            const collageItems = Array.from(document.querySelectorAll(".text-block__wrap"));
            collageItems.forEach((elem) => {

                this.oneScrollTrigger = gsap.fromTo(elem, {
                    y: 50,
                }, {
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        start: '0% 100%',
                        end: 'bottom 75%',
                        trigger: elem,
                        scrub: true,
                        // markers: true,
                    },
                });
            });

            const sidebarItems = Array.from(document.querySelectorAll(".sidebar"));
            sidebarItems.forEach((elem) => {
                this.twoScrollTrigger = gsap.fromTo(elem, {
                    y: 50,
                }, {
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        start: '0% 100%',
                        end: 'bottom 75%',
                        trigger: elem,
                        scrub: true,
                    },
                });
            });

            this.ScrollTrigger = gsap.to(".scroll-bg", {
                scrollTrigger: {
                    trigger: ".scroll",
                    start: '0%',
                    scrub: true,
                    toggleClass: { className: "active", targets: ".scroll-bg" }
                },
            })
            if (window.innerWidth <= 768) {
                this.scrollMobile()
            }

            gsap.fromTo(".bg-1", { opacity: '1' }, {
                opacity: '0',
                scrollTrigger: {
                    trigger: ".card-1",
                    start: 'top 0%',
                    end: 'bottom 50%',
                    endTrigger: '.card-2',
                    // markers: true,
                    scrub: true
                },

            });

            gsap.fromTo(".bg-2", { opacity: '1' }, {
                opacity: '0',
                scrollTrigger: {
                    trigger: ".card-2",
                    start: 'top 0%',
                    end: 'bottom 50%',
                    endTrigger: '.card-3',
                    // markers: true,
                    scrub: true,
                },

            });

            gsap.fromTo(".bg-3", { opacity: '1' }, {
                opacity: '0',
                scrollTrigger: {
                    trigger: ".card-3",
                    start: 'top 0%',
                    end: 'bottom 50%',
                    endTrigger: '.card-4',
                    scrub: true,
                },

            });

            gsap.to(".scroll-bg", {
                scrollTrigger: {
                    trigger: ".card-4",
                    start: () => "+=120%",
                    end: () => "+=" + (document.querySelector(".scroll-wrap").offsetHeight),
                    // markers: true,
                    scrub: true,
                    toggleClass: { className: "remove-active", targets: ".scroll-bg" }
                },
            });

            if (window.innerWidth > 768) {
                gsap.fromTo(".scale-1", { width: '0' }, {
                    width: '5.75rem',
                    duration: 1,
                    scrollTrigger: {
                        trigger: ".shares__wrap",
                        start: 'top 50%',
                        end: 'bottom 40%',
                        // markers: true,
                        scrub: true,
                    },
                });
            } else {
                gsap.fromTo(".scale-1", { width: '0' }, {
                    width: '24.534rem',
                    duration: 1,
                    scrollTrigger: {
                        trigger: ".shares__wrap",
                        start: 'top 75%',
                        end: 'bottom 60%',
                        // markers: true,
                        scrub: true,
                    },
                });
            }

            if (window.innerWidth > 768) {
                gsap.fromTo(".scale-2", { width: '0' }, {
                    width: '2.875rem',
                    duration: 4,
                    scrollTrigger: {
                        trigger: ".shares__wrap",
                        start: 'top 50%',
                        end: 'bottom 40%',
                        scrub: true,
                    },
                });
            } else {
                gsap.fromTo(".scale-2", { width: '0' }, {
                    width: '12.267rem',
                    duration: 4,
                    scrollTrigger: {
                        trigger: ".shares__wrap",
                        start: 'top 75%',
                        end: 'bottom 60%',
                        scrub: true,
                    },
                });
            }

            if (window.innerWidth > 768) {
                gsap.fromTo(".scale-3", { width: '0' }, {
                    width: '4.94rem',
                    duration: 4,
                    scrollTrigger: {
                        trigger: ".shares__wrap",
                        start: 'top 50%',
                        end: 'bottom 40%',
                        // markers: true,
                        scrub: true,
                    },
                });
            } else {
                gsap.fromTo(".scale-3", { width: '0' }, {
                    width: '21.067rem',
                    duration: 4,
                    scrollTrigger: {
                        trigger: ".shares__wrap",
                        start: 'top 75%',
                        end: 'bottom 60%',
                        scrub: true,
                    },
                });
            }

            if (window.innerWidth > 768) {
                gsap.fromTo(".scale-4", { width: '0' }, {
                    width: '7rem',
                    duration: 4,
                    scrollTrigger: {
                        trigger: ".shares__wrap",
                        start: 'top 50%',
                        end: 'bottom 40%',
                        // markers: true,
                        scrub: true,
                    },
                });
            } else {
                gsap.fromTo(".scale-4", { width: '0' }, {
                    width: '29.867rem',
                    duration: 4,
                    scrollTrigger: {
                        trigger: ".shares__wrap",
                        start: 'top 75%',
                        end: 'bottom 60%',
                        // markers: true,
                        scrub: true,
                    },
                });
            }

            if (window.innerWidth > 768) {
                gsap.fromTo(".scale-5", { width: '0' }, {
                    width: '1.94rem',
                    duration: 4,
                    scrollTrigger: {
                        trigger: ".shares__wrap",
                        start: 'top 50%',
                        end: 'bottom 40%',
                        // markers: true,
                        scrub: true,
                    },
                });
            } else {
                gsap.fromTo(".scale-5", { width: '0' }, {
                    width: '15.2rem',
                    duration: 4,
                    scrollTrigger: {
                        trigger: ".shares__wrap",
                        start: 'top 75%',
                        end: 'bottom 60%',
                        // markers: true,
                        scrub: true,
                    },
                });
            }

            if (window.innerWidth > 768) {
                gsap.fromTo(".scale-6", { width: '0' }, {
                    width: '3.6rem',
                    duration: 4,
                    scrollTrigger: {
                        trigger: ".shares__wrap",
                        start: 'top 50%',
                        end: 'bottom 40%',
                        // markers: true,
                        scrub: true,
                    },
                });
            } else {
                gsap.fromTo(".scale-6", { width: '0' }, {
                    width: '8.267rem',
                    duration: 4,
                    scrollTrigger: {
                        trigger: ".shares__wrap",
                        start: 'top 75%',
                        end: 'bottom 60%',
                        // markers: true,
                        scrub: true,
                    },
                });
            }
        },
        parallax() {
            if (window.innerWidth > 768) {
                // gsap.fromTo(".in-search__fish", { x: 60 }, {
                //     x: -60,
                //     scrollTrigger: {
                //         trigger: ".in-search-section",
                //         start: '55% 100%',
                //         end: '90%',
                //         ease: "ease-in-out",
                //         // markers: true,
                //         scrub: true
                //     },

                // });
            }
        }
    }
}
</script>

<style lang="scss">
.lake {

    overflow: hidden;

    &__introduction {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-img {
            margin-bottom: 1.75rem;
            width: 31.625rem;
            height: 7.625rem;

            @media (max-width: 768px) {
                margin-bottom: 5.34rem;
                width: 100%;
                height: 26.667rem;
            }
        }

        .number-block {
            position: absolute;

            @media (max-width: 768px) {
                position: static;
            }
        }
    }

    &__map {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-img {
            position: relative;
            margin-top: 1.88rem;
            width: 21.1875rem;
            height: 32.08944rem;

            @media (max-width: 768px) {
                margin: 0 auto;
                margin-top: 5.34rem;
                width: 80.3rem;
                height: 121.5rem;
            }

            .circle {
                position: absolute;
                top: 26.8rem;
                left: 13.2rem;
                width: 0.4rem;
                height: 0.4rem;
                z-index: 2;
                background-color: #333;
                border-radius: 100%;
                animation: ripple-black 1s infinite;

                @media (max-width: 768px) {
                    top: 101rem;
                    left: 49.7rem;
                    width: 1.375rem;
                    height: 1.375rem;
                }
            }
        }
    }
}

.pyasino__video {
    position: fixed;
    z-index: 8;
    bottom: 0;
    width: 118.5%;
    left: -9.3%;
    opacity: 0;
    display: none;
}

.pyasino .scroll {
    position: relative;
    width: 100%;

    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        height: 100vh;
        width: 100%;
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: 768px) {
            background-position: top;
            background-size: 100%;
        }

        &.active {
            background-attachment: fixed;
            height: 100%;

            @media (max-width: 768px) {
                height: 100%;
                background-size: 100%;
                position: fixed;
                background-attachment: unset;
            }

            &.remove-active {
                background-attachment: unset;
                background-position: bottom;
                background-size: contain;

                @media (max-width: 768px) {
                    background-position: bottom;
                    position: absolute;
                }
            }

            .pyasino__video {
                opacity: 1;
                display: block;
            }
        }

        &.bg-1 {
            z-index: 4;
            background-image: url(../../public/img/fito-1.jpg);

            @supports (background-image: url(../../public/img/fito-1.webp)) {
                background-image: url(../../public/img/fito-1.webp);
            }
        }

        &.bg-2 {
            z-index: 3;
            background-image: url(../../public/img/fito-2.jpg);

            @supports (background-image: url(../../public/img/fito-2.webp)) {
                background-image: url(../../public/img/fito-2.webp);
            }
        }

        &.bg-3 {
            z-index: 2;
            background-image: url(../../public/img/fito-3.jpg);

            @supports (background-image: url(../../public/img/fito-3.webp)) {
                background-image: url(../../public/img/fito-3.webp);
            }
        }

        &.bg-4 {
            z-index: 1;
            background-image: url(../../public/img/fito-4.jpg);

            @supports (background-image: url(../../public/img/fito-4.webp)) {
                background-image: url(../../public/img/fito-4.webp);
            }
        }

        @media (max-width: 768px) {
            &.bg-1 {
                background-image: url(../../public/img/fito-1-mobile.jpg);
            }

            &.bg-2 {
                background-image: url(../../public/img/fito-2-mobile.jpg);
            }

            &.bg-3 {
                background-image: url(../../public/img/fito-3-mobile.jpg);
            }

            &.bg-4 {
                background-image: url(../../public/img/fito-4-mobile.jpg);
            }
        }
    }

    &-wrap {
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 1.25rem;
    }

    &-card {
        padding: 1.5rem;
        margin-bottom: 100vh;
        width: 34.2rem;
        border-radius: 0.25rem;
        background: #FFF;

        &:first-child {
            margin-top: 50vh;
        }

        @media (max-width: 768px) {
            padding: 4.2666rem;
            margin: 0 auto 100vh auto;
            width: 93%;

            &:first-child {
                margin-top: 100vh;
            }
        }
    }
}

.work-on-mistakes {
    &__img {
        position: relative;
        background-image: url(../../public/img/scheme.png);
        width: 100%;
        height: 33.4723rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @supports (background-image: url(../../public/img/scheme.webp)) {
            background-image: url(../../public/img/scheme.webp);
        }

        @media (max-width: 768px) {
            background-image: url(../../public/img/scheme-mobile.png);
            height: 178.135rem;

            @supports (background-image: url(../../public/img/scheme-mobile.webp)) {
                background-image: url(../../public/img/scheme-mobile.webp);
            }
        }
    }

    video {
        position: absolute;
        top: 16.85rem;
        left: 48.7rem;
        width: 22.3rem;
    }
}

.in-search {
    position: relative;
    margin-bottom: 5.75rem;
    height: 106rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    background-image: url(../../public/img/in-search-line.webp);
    background-position: 0 3rem;
    background-size: cover;
    background-repeat: no-repeat;

    @supports (background-image: url(../../public/img/in-search-line.webp)) {
        background-image: url(../../public/img/in-search-line.webp);
    }

    @media (max-width: 768px) {
        background-image: url(../../public/img/in-search-mobile.webp);
        height: 176.535rem;
        margin-bottom: 21.3334rem;

        @supports (background-image: url(../../public/img/in-search-mobile.webp)) {
            background-image: url(../../public/img/in-search-mobile.webp);
        }
    }

    &__video {
        position: absolute;
        width: 100%;
        top: -34rem;
        object-fit: cover;
        z-index: -1;
    }


    &__seagulls {
        position: absolute;
        top: 10rem;
        left: 62.76rem;
        width: 13rem;
        height: 5rem;
        background-image: url(../../public/img/seagulls.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @supports (background-image: url(../../public/img/seagulls.webp)) {
            background-image: url(../../public/img/seagulls.webp);
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    &__fish {
        position: absolute;
        top: 56.8rem;
        left: 56rem;
        width: 28.4rem;
        height: 11rem;

        @media (max-width: 768px) {
            display: none;
        }

        video {
            width: 109%;
        }
    }

    &__fish-2 {
        position: absolute;
        top: 41.8rem;
        left: 34rem;
        width: 27rem;

        @media (max-width: 768px) {
            display: none;
        }

        video {
            width: 100%;
        }
    }

    &__fish-3 {
        position: absolute;
        top: 51.6rem;
        left: 18rem;
        width: 17rem;

        @media (max-width: 768px) {
            display: none;
        }

        video {
            width: 100%;
        }
    }
}

.shares {
    padding: 0 13.25rem;

    @media (max-width: 768px) {
        padding: 0;
    }

    &__title {
        margin-left: 4.2rem;
        width: 24rem;
        color: #000;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 130%;

        @media (max-width: 768px) {
            margin-left: 0;
            margin-top: 21.3334rem;
            margin-bottom: 4rem;
            padding: 0 4rem;
            flex-wrap: wrap;
            width: 100%;
            font-size: 4.8rem;
        }
    }

    &__wrap {
        margin-left: 4.2rem;
        margin-bottom: 3.75rem;
        display: flex;

        @media (max-width: 768px) {
            margin-left: 0;
            flex-wrap: wrap;
        }
    }

    &__img-wrap {
        position: relative;
        display: flex;
        width: 10.8125rem;
        height: 7.25rem;

        @media (max-width: 768px) {
            margin-bottom: 1.8666rem;
            width: 46.134rem;
            height: 24.8rem;
        }
    }

    &__name-scales {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @media (max-width: 768px) {
            display: none;
        }
    }

    &__item {
        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.scales {
    &__text {
        width: 11rem;
        margin-right: 0.69rem;
        color: #000;
        font-size: 1.11111rem;
        font-weight: 300;

        &:not(:last-child) {
            margin-bottom: 1.88rem;
        }

        &-wrap:not(:last-child) {
            margin-bottom: 1.88rem;

            @media (max-width: 768px) {
                margin-bottom: 0;
            }
        }

        &-mobile {
            display: none;

            @media (max-width: 768px) {
                display: block;
                padding: 0 4rem;
                margin-bottom: 1.867rem;
                font-size: 4.2667rem;
                line-height: 130%;
            }
        }
    }
}

.scale__wrap.mr-4-31 {
    @media (max-width: 768px) {
        margin-right: 0;
    }
}

.remove-padding .sidebar__text {
    padding: 0;
}
</style>