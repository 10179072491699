<template>
    <div class="faq">
        <div class="header" @click="handleClick(faq)">
            <div class="question" v-html="faq.question"></div>
            <div class="faq_svg">
                <svg class="faq_svg__minus" xmlns="http://www.w3.org/2000/svg" width="29" height="2" viewBox="0 0 29 2"
                    fill="none">
                    <path d="M0 1.0802H29" stroke="black" />
                </svg>
                <svg class="faq_svg__minus" :class="{ 'faq_svg__minus_close': faq.isOpen }"
                    xmlns="http://www.w3.org/2000/svg" width="29" height="2" viewBox="0 0 29 2" fill="none">
                    <path d="M0 1.0802H29" stroke="black" />
                </svg>
            </div>
        </div>
        <div v-if="faq.mainPageItem" class="answer" :class="{ 'open': faq.isOpen }">
            <div class=fqa-flex>
                <div class=fqa-num>01</div>
                <div class=fqa-text>
                    <span>
                        We organise annual research expeditions together with the Russian Academy of Sciences to study ecosystems across our divisions and monitor biodiversity.
                    </span>
                </div>
            </div>

            <div class=fqa-flex>
                <div class=fqa-num>02</div>
                <div class=fqa-text>
                    <span>
                        We perform tests and experiments to contribute to natural restoration of habitats.
                    </span>
                </div>
            </div>

            <div class=fqa-flex>
                <div class=fqa-num>03</div>
                <div class=fqa-text>
                    <span>We support </span>
                    <BaseTooltip :word="'specially protected natural areas'">
                        <span>
                            Specially protected natural areas (SPNA) are portions of land and water where the nature and biodiversity are protected by the government. They are created to preserve rare and vulnerable animals, plants and ecosystems, and support natural processes and cultural values. SPNAs are nature reserves, national parks, natural protected areas and other sites with special rules and restrictions established to protect nature in the long run. In Russia, there are 13,000 SPNAs spreading over 12% of its territory.
                        </span>
                    </BaseTooltip>
                    <span> and select research programmes.</span>
                </div>
            </div>

            <div class=fqa-flex>
                <div class=fqa-num>04</div>
                <div class=fqa-text>
                    <span>
                        We sponsor programmes to study and protect rare and extinct species of plants and animals.
                    </span>
                </div>
            </div>

            <div class=fqa-flex>
                <div class=fqa-num>05</div>
                <div class=fqa-text>
                    <span>
                        We rehabilitate disturbed lands to bring them to their initial or a better state.
                    </span>
                </div>
            </div>

            <div class=fqa-flex>
                <div class=fqa-num>06</div>
                <div class=fqa-text>
                    <span>
                        We utilise SO₂ capture solutions across operating and designed facilities to reduce air emissions (Sulphur Programme).
                    </span>
                </div>
            </div>

            <div class=fqa-flex>
                <div class=fqa-num>07</div>
                <div class=fqa-text>
                    <span>
                        We build and upgrade local treatment facilities to reduce the polluting impact of wastewater discharges.
                    </span>
                </div>
            </div>

            <div class=fqa-flex>
                <div class=fqa-num>08</div>
                <div class=fqa-text>
                    <span>
                        We arrange for juveniles of valuable fish species to be released into Russian rivers.
                    </span>
                </div>
            </div>

            <div class=fqa-flex>
                <div class=fqa-num>09</div>
                <div class=fqa-text>
                    <span>
                        We monitor landfill sites and the health of surrounding ecosystems.
                    </span>
                </div>
            </div>

            <div class=fqa-flex>
                <div class=fqa-num>10</div>
                <div class=fqa-text>
                    <span>
                        We observe noise requirements during blasting and other activities.
                    </span>
                </div>
            </div>

            <div class=fqa-flex>
                <div class=fqa-num>11</div>
                <div class=fqa-text>
                    <span>
                        We use environmental monitoring techniques to keep track of pollution.
                    </span>
                </div>
            </div>

            <div class=fqa-flex>
                <div class=fqa-num>12</div>
                <div class=fqa-text>
                    <span>
                        We make our facilities better prepared for emergencies that can affect the environment.
                    </span>
                </div>
            </div>

            <div class=fqa-flex>
                <div class=fqa-num>13</div>
                <div class=fqa-text>
                    <span>
                        We limit certain activities in areas with the greatest biodiversity value, which feature protected species and unique natural ecosystems.
                    </span>
                </div>
            </div>
        </div>
        <div v-else class="answer" :class="{ 'open': faq.isOpen }">
            <div v-if="faq.answer" v-html="faq.answer" />
            <div v-else>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import BaseTooltip from '@/components/BaseTooltip.vue'
export default {
    components: {
        BaseTooltip
    },
    props: {
        faq: {
            type: Object,
            default: null
        }
    },

    methods: {
        handleClick(faq) {
            faq.isOpen = !faq.isOpen
        }
    },
}
</script>

<style lang="scss">
.faq {
    flex-grow: 1;

    @media (max-width: 768px) {
        margin: 0 5.3334rem;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.09rem 0.7rem;
        cursor: pointer;

        @media (max-width: 768px) {
            padding: 6.4rem 0;
        }

        .question {
            font-size: 1.5278rem;
            font-weight: 600;
            line-height: 130%;

            @media (max-width: 768px) {
                white-space: pre-wrap;
                font-size: 4.8rem;
            }
        }

        .faq_svg {
            position: relative;
            display: flex;
            margin-right: 0.695rem;
            width: 2.014rem;
            height: 2.014rem;
            flex-shrink: 0;

            @media (max-width: 768px) {
                width: 7.734rem;
                height: 7.734rem;
            }
        }

        .faq_svg__minus {
            position: absolute;
            width: 2.014rem;
            height: 2.014rem;
            transition: all .5s ease-out;

            @media (max-width: 768px) {
                width: 7.734rem;
                height: 7.734rem;
            }

            &:last-child {
                transform: rotateZ(90deg);
            }

            &_close {
                transform: rotateZ(0deg) !important;
            }

        }
    }

    .answer {
        height: 0;
        overflow: hidden;
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 130%;
        transition: all .5s ease-out;

        @media (max-width: 768px) {
            font-size: 4.8rem;
        }

        &.open {
            padding-bottom: 2.09rem;
            height: auto;

            @media (max-width: 768px) {
                padding-bottom: 8rem;
            }
        }
    }
}
</style>